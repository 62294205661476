import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
export default class extends Controller {
  static targets = ['nextToVariants']
  static values = {
    step: String,
  }
  connect() {
    StimulusReflex.register(this)
    console.log(this.stepValue);
    if(this.stepValue) {
      var step = `#${this.stepValue}`
      $(step).click();
    }
  }

  navClicked (e) {
    var paritalForm = $('.tab-pane.show')[0];
    var allowed = this.validateInformation(paritalForm, e);
    if (!allowed) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  validateInformation (paritalForm, e) {
    var inputs = paritalForm.querySelectorAll("form input, form select");

    // Set a flag to indicate whether the form is valid
    var isValid = true;

    // Loop through all the input elements
    for (var i = 0; i < inputs.length; i++) {
      // If the input is required and it's empty, set the flag to false and show an error message
      if (inputs[i].required && inputs[i].value === "") {
        isValid = false;
        inputs[i].parentNode.classList.add("has-error");
        // inputs[i].nextElementSibling.innerHTML = "This field is required";
      } else {
        // Otherwise, remove the error message and error class
        inputs[i].parentNode.classList.remove("has-error");
        // inputs[i].nextElementSibling.innerHTML = "";
      }
    }

    // If the form is valid, submit it. Otherwise, prevent the default submission behavior
    if (isValid) {
      return true;
    } else {
      toastr.error('Please fill all fields.');
      e.preventDefault();
      return false;
    }
  }

  typeChanged (e) {
    var val = e.target.value;
    var inputElements = this.standardSectionTarget.querySelectorAll("input");
    inputElements.forEach(element => {
      if (val == 'recurring') {
        $(element).attr('required', false);
      } else {
        $(element).attr("required", true);
      }
    });

    if (val == 'recurring') {
      this.standardSectionTarget.classList.add('d-none');
    } else {
      this.standardSectionTarget.classList.remove('d-none');
    }
  }

  unitChanged (e) {
    var val = e.target.value;
    var inputElements = this.perPackDivTarget.querySelectorAll("input");


    inputElements.forEach(element => {
      if (val == 'single') {
        $(element).attr('disabled', true);
        $(element).attr('required', false);
      } else {
        $(element).attr('disabled', false);
        $(element).attr("required", true);
      }
    });
  }

  toggleStateSuccess (element, reflex, noop, reflexId) {
    toastr.options.timeOut = 90;
    toastr.success('Product Updated..');
    $('.refresh-page-table')[0].click();
  }

  toggleStateClicked(e) {
    var el = e.target;
    if (confirm('Are you sure you want to perform this action?')) {
      this.stimulate('ProductTemplates#toggle_state', el)
    };
  }
;}
