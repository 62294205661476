import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

import {
  requireAForm,
  serializeFormData
} from "helpers";
export default class extends ApplicationController {
  static targets = ['patientCount'];
  static values = {
    listId: String
  };

  connect() {
    var vm = this;
    StimulusReflex.register(this);
    if (this.listIdValue){
      setTimeout(() => {
        this.countPatientsInList();
      }, 1000);
    }
  }

  countPatientsInList () {
    var params = {};
    params['smart_list_id'] = this.listIdValue;

    this.stimulate("SmartFilter#calculate_patients_count", params);
  }

  calculatePatientsCountSuccess(element, reflex, noop, reflexId) {
  }
}
