// Import Stimulus and GoJS
import { Controller } from "stimulus";
import * as go from "gojs";
import {
  isAdmin,
} from "helpers";
import { success } from "toastr";

export default class extends Controller {
  static targets = ["diagram", "modelJson"];
  static values = {
    information: Object,
  }

  connect() {
    this.initDiagram();
    var canvas = document.querySelector("canvas");
    // Remove the border style
    canvas.style.border = "none";
  }

  initDiagram() {
    var vm = this;
    var _self = this;
    var myDiagram = null;
    var $$ = go.GraphObject.make; // For conciseness in defining templates

    // Custom PoolLayout class definition (as provided earlier)
    class PoolLayout extends go.GridLayout {
      constructor() {
        super();
        this.MINLENGTH = 200;  // this controls the minimum length of any swimlane
        this.MINBREADTH = 100;  // this controls the minimum breadth of any non-collapsed swimlane
        this.cellSize = new go.Size(1, 1);
        this.wrappingColumn = Infinity;
        this.wrappingWidth = Infinity;
        this.spacing = new go.Size(0, 0);
        this.alignment = go.GridLayout.Position;
      }

      doLayout(coll) {
        const diagram = this.diagram;
        if (diagram === null) return;
        diagram.startTransaction("PoolLayout");
        // make sure all of the Group Shapes are big enough
        const minlen = this.computeMinPoolLength();
        diagram.findTopLevelGroups().each(lane => {
          if (!(lane instanceof go.Group)) return;
          const shape = lane.selectionObject;
          if (shape !== null) {  // change the desiredSize to be big enough in both directions
            const sz = this.computeLaneSize(lane);
            shape.width = (!isNaN(shape.width)) ? Math.max(shape.width, sz.width) : sz.width;
            // if you want the height of all of the lanes to shrink as the maximum needed height decreases:
            shape.height = minlen;
            // if you want the height of all of the lanes to remain at the maximum height ever needed:
            //shape.height = (isNaN(shape.height) ? minlen : Math.max(shape.height, minlen));
            const cell = lane.resizeCellSize;
            if (!isNaN(shape.width) && !isNaN(cell.width) && cell.width > 0) shape.width = Math.ceil(shape.width / cell.width) * cell.width;
            if (!isNaN(shape.height) && !isNaN(cell.height) && cell.height > 0) shape.height = Math.ceil(shape.height / cell.height) * cell.height;
          }
        });
        // now do all of the usual stuff, according to whatever properties have been set on this GridLayout
        super.doLayout(coll);
        diagram.commitTransaction("PoolLayout");
      };

      // compute the minimum length of the whole diagram needed to hold all of the Lane Groups
      computeMinPoolLength() {
        let len = this.MINLENGTH;
        myDiagram.findTopLevelGroups().each(lane => {
          const holder = lane.placeholder;
          if (holder !== null) {
            const sz = holder.actualBounds;
            len = Math.max(len, sz.height);
          }
        });
        return len;
      }

      // compute the minimum size for a particular Lane Group
      computeLaneSize(lane) {
        // assert(lane instanceof go.Group);
        const sz = new go.Size(lane.isSubGraphExpanded ? this.MINBREADTH : 1, this.MINLENGTH);
        if (lane.isSubGraphExpanded) {
          const holder = lane.placeholder;
          if (holder !== null) {
            const hsz = holder.actualBounds;
            sz.width = Math.max(sz.width, hsz.width);
          }
        }
        // minimum breadth needs to be big enough to hold the header
        const hdr = lane.findObject("HEADER");
        if (hdr !== null) sz.width = Math.max(sz.width, hdr.actualBounds.width);
        return sz;
      }
    }


    vm.myDiagram = myDiagram =
      $$(go.Diagram, "myDiagramDiv",
        {
          // make sure the top-left corner of the viewport is occupied
          contentAlignment: go.Spot.TopLeft,
          allowZoom: false,
          // use a simple layout to stack the top-level Groups next to each other
          layout: $$(PoolLayout),
          // disallow nodes to be dragged to the diagram's background
          mouseDrop: e => {
            e.diagram.currentTool.doCancel();
          },
          // a clipboard copied node is pasted into the original node's group (i.e. lane).
          "commandHandler.copiesGroupKey": true,
          // automatically re-layout the swim lanes after dragging the selection
          "SelectionMoved": relayoutDiagram,  // this DiagramEvent listener is
          "SelectionCopied": relayoutDiagram, // defined above
          "undoManager.isEnabled": true,
          // allow TextEditingTool to start without selecting first
          "textEditingTool.starting": go.TextEditingTool.SingleClick
        });


    this.draggingConfiguration(myDiagram);
    // this is called after nodes have been moved
    function relayoutDiagram() {
      myDiagram.selection.each(n => n.invalidateLayout());
      myDiagram.layoutDiagram();
    }

    this.nodeConfigurations(myDiagram);
    this.groupConfigurations(myDiagram);
    myDiagram.model = go.Model.fromJson(this.informationValue);
    console.log('info:', this.informationValue);

  }


  draggingConfiguration(myDiagram) {
    // Customize the dragging tool:
    // When dragging a node set its opacity to 0.6 and move it to be in front of other nodes
    myDiagram.toolManager.draggingTool.doActivate = function() {  // method override must be function, not =>
      go.DraggingTool.prototype.doActivate.call(this);
      this.currentPart.opacity = 0.6;
      this.currentPart.layerName = "Foreground";
    }
    myDiagram.toolManager.draggingTool.doDeactivate = function() {  // method override must be function, not =>
      this.currentPart.opacity = 1;
      this.currentPart.layerName = "";
      go.DraggingTool.prototype.doDeactivate.call(this);
    }
  }

  groupConfigurations(myDiagram) {
    var vm = this;
    var $$ = go.GraphObject.make; // For conciseness in defining templates;
    // While dragging, highlight the dragged-over group
    function highlightGroup(grp, show) {
      if (show) {
        const part = myDiagram.toolManager.draggingTool.currentPart;
        if (part.containingGroup !== grp) {
          grp.isHighlighted = true;
          return;
        }
      }
      grp.isHighlighted = false;
    }

    myDiagram.groupTemplate =
      $$(go.Group, "Vertical",
        {
          selectable: false,
          selectionObjectName: "SHAPE", // even though its not selectable, this is used in the layout
          layerName: "Background",  // all lanes are always behind all nodes and links
          layout: $$(go.GridLayout,  // automatically lay out the lane's subgraph
            {
              wrappingColumn: 1,
              cellSize: new go.Size(10, 10),
              spacing: new go.Size(5, 5),
              alignment: go.GridLayout.Position,
              comparer: (a, b) => {  // can re-order tasks within a lane
                const ay = a.location.y;
                const by = b.location.y;
                if (isNaN(ay) || isNaN(by)) return 0;
                if (ay < by) return -1;
                if (ay > by) return 1;
                return 0;
              }
            }),
          click: (e, grp) => {  // allow simple click on group to clear selection
            if (!e.shift && !e.control && !e.meta) e.diagram.clearSelection();
          },
          computesBoundsIncludingLocation: true,
          computesBoundsAfterDrag: true,  // needed to prevent recomputing Group.placeholder bounds too soon
          handlesDragDropForMembers: true,  // don't need to define handlers on member Nodes and Links
          mouseDragEnter: (e, grp, prev) => highlightGroup(grp, true),
          mouseDragLeave: (e, grp, next) => highlightGroup(grp, false),
          mouseDrop: (e, grp) => {  // dropping a copy of some Nodes and Links onto this Group adds them to this Group
            // don't allow drag-and-dropping a mix of regular Nodes and Groups
            if (e.diagram.selection.all(n => !(n instanceof go.Group))) {
              const ok = grp.addMembers(grp.diagram.selection, true);
              if (!ok) {
                e.diagram.currentTool.doCancel();
              } else {
                console.log('Drop done. Dropped to group with key:', grp.key);
                var destinationStageId = grp.key;
                var columnTag = grp.data.tag;
                var patientNode = null;
                var mainEvent = e;
                e.diagram.selection.each(function(node) {
                  if (!(node instanceof go.Group)) {
                    patientNode = node;
                    console.log('Dropped node key:', node.data.key); // Assuming node.data.key holds the key or ID
                  }
                });
                var sourceStageId = patientNode.data.group;
                if (1 == 1) {
                  var patientId = patientNode.data.key;

                  var url = `/patients/${patientId}/update_stage?patient_id=${patientId}`;

                  url = isAdmin() ? `/admin/${url}` : url;
                  console.log(url)
                  $.ajax({
                    url: url,
                    method: 'post',
                    dataType: "json",
                    data: {
                      id: patientId,
                      patient_id: patientId,
                      pipeline_stage_id: destinationStageId
                    },
                    success: (result) => {
                      if (result.status == 'ok') {
                        toastr.success('Patient Updated')
                        if (columnTag == 'Won') {
                          url = `/patients/${patientId}/assign_form_to_patient`;
                          url = isAdmin() ? `/admin/${url}` : url;
                          $.ajax({
                            url: url,
                            method: 'GET',
                            dataType: "script",
                            data: {
                              id: patientId,
                            }
                          },
                          success => { toastr.success('Assign Workflow To The Patient') },
                          error => { console.log('error') });
                        }
                      } else {
                        mainEvent.diagram.currentTool.doCancel();
                        toastr.error('Something went wrong')
                      }
                    },
                    error: (result) => {
                      mainEvent.diagram.currentTool.doCancel();
                      toastr.error('Something went wrong')
                    }
                  });
                }

              }
            }
          },
          subGraphExpandedChanged: grp => {
            const shp = grp.selectionObject;
            if (grp.diagram.undoManager.isUndoingRedoing) return;
            if (grp.isSubGraphExpanded) {
              shp.width = grp.data.savedBreadth;
            } else {  // remember the original width
              if (!isNaN(shp.width)) grp.diagram.model.set(grp.data, "savedBreadth", shp.width);
              shp.width = NaN;
            }
          }
        },
        new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
        new go.Binding("isSubGraphExpanded", "expanded").makeTwoWay(),
        // the lane header consisting of a TextBlock and an expander button
        $$(go.Panel, "Horizontal",
          { name: "HEADER", alignment: go.Spot.Left },
          // $("SubGraphExpanderButton", { margin: 5 }),  // this remains always visible
          $$(go.TextBlock,  // the lane label
            { font: "20px Lato, sans-serif", editable: false, margin: new go.Margin(2, 0, 0, 0) },
            // this is hidden when the swimlane is collapsed
            new go.Binding("visible", "isSubGraphExpanded").ofObject(),
            new go.Binding("text").makeTwoWay()),
            // new go.Binding("stroke", "color")),
            $$(go.Panel, "Auto", {  // Panel for the status bubble
              margin: new go.Margin(0, 4),  // Adjust margins as needed
            },
            $$(go.Shape, "RoundedRectangle", {
              strokeWidth: 0,
              // Bind the fill property to the "color" property of the node data
            }, new go.Binding("fill", "color")),
            $$(go.TextBlock, {  // Text for the status bubble
              margin: 4,
              font: '11px Lato, sans-serif',
              stroke: "white",
              editable: false,  // Assuming this isn't editable
            }, new go.Binding("text", "tag"))
          )
        ),  // end Horizontal Panel

        $$(go.Panel, "Auto",  // the lane consisting of a background Shape and a Placeholder representing the subgraph
          $$(go.Shape, "RoundedRectangle",  // this is the resized object
            { name: "SHAPE", fill: "#F1F1F1", stroke: null, strokeWidth: 4 },  // strokeWidth controls space between lanes
            new go.Binding("fill", "isHighlighted", h => h ? "#D6D6D6" : "#47b0fd").ofObject(),
            new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(go.Size.stringify)),
          $$(go.Placeholder,
            { padding: 12, alignment: go.Spot.TopLeft }),
          $$(go.TextBlock,  // this TextBlock is only seen when the swimlane is collapsed
            {
              name: "LABEL", font: "15px Lato, sans-serif", editable: false,
              angle: 90, alignment: go.Spot.TopLeft, margin: new go.Margin(4, 0, 0, 2)
            },
            new go.Binding("visible", "isSubGraphExpanded", e => !e).ofObject(),
            new go.Binding("text").makeTwoWay())
        )  // end Auto Panel
      );  // end Group
  }

  nodeConfigurations(myDiagram) {
    var vm = this;
    var $$ = go.GraphObject.make; // For conciseness in defining templates

    // There are only three note colors by default, blue, red, and yellow but you could add more here:
    const noteColors = ['#009CCC', '#CC293D', '#FFD700'];

    function getNoteColor(num) {
      return noteColors[Math.min(num, noteColors.length - 1)];
    }
    var maxWidth = 350;

    myDiagram.nodeTemplate =
      $$(go.Node, "Auto",  // "Auto" for automatic sizing based on contents
        new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
        $$(go.Shape, "RoundedRectangle", {  // Shape around the text blocks
          fill: 'white', strokeWidth: 1, stroke: 'white',
          stretch: go.GraphObject.Fill,  // Fill the shape to the panel size
          },
          {
            click: function(e, obj) { // Click event handler
              var clickedNodeData = obj.part.data;
              vm.fetchFollowUpCard(clickedNodeData.key);
            }
          },
          new go.Binding("fill", "color", getNoteColor),
          new go.Binding("stroke", "color", getNoteColor)),
        $$(go.Panel, "Vertical", {  // Vertical panel for the text blocks
            padding: 10,
            width: maxWidth,  // Adjust width if necessary
          },

          // Horizontal Panel for Name and Status Bubble
          $$(go.Panel, "Horizontal",
            {
              width: maxWidth,  // Ensure this fits within the node's width
              alignment: go.Spot.Left
            },
            $$(go.TextBlock, {  // First text block for name
              margin: 4,
              font: '16px Lato, sans-serif',
              stroke: "#000",
              editable: false,
            }, new go.Binding("text", "text").makeTwoWay()),
            $$(go.Panel, "Auto", {  // Panel for the status bubble
                margin: new go.Margin(0, 4),  // Adjust margins as needed
              },
              $$(go.Shape, "RoundedRectangle", {
                strokeWidth: 0,
                // Bind the fill property to the "color" property of the node data
              }, new go.Binding("fill", "color")),
              $$(go.TextBlock, {  // Text for the status bubble
                margin: 4,
                font: '11px Lato, sans-serif',
                stroke: "white",
                editable: false,  // Assuming this isn't editable
              }, new go.Binding("text", "leadStatus"))
            ),
            $$(go.Panel, "Auto", {  // Panel for the status bubble
                margin: new go.Margin(0, 4),  // Adjust margins as needed
              },
              $$(go.Shape, "RoundedRectangle", {
                strokeWidth: 0,
                fill: 'red'
                // Bind the fill property to the "color" property of the node data
              }),
              $$(go.TextBlock, {  // Text for the status bubble
                margin: 4,
                font: '11px Lato, sans-serif',
                stroke: "white",
                editable: false,  // Assuming this isn't editable
              }, new go.Binding("text", "inboxTasksCount"))
            )
          ),
          $$(go.TextBlock, {  // Second text block for reason, truncated to 200 characters
            wrap: go.TextBlock.WrapFit,
            margin: 4,
            font: '13px Lato, sans-serif',
            stroke: "#000",
            editable: false,
            width: (maxWidth - 15),  // Ensure it wraps correctly within the node's width
            alignment: go.Spot.Left,  // Align text to the left
          }, new go.Binding("text", "reason", function(t) {
            if (t.length > 200 ){
              return `${t.substring(0, 300)}...`;
            } else {
              return t;
            }
          })),
          $$(go.TextBlock, {  // Second text block for reason, truncated to 200 characters
            wrap: go.TextBlock.WrapFit,
            margin: 4,
            font: '12px Lato, sans-serif',
            stroke: "#000",
            editable: false,
            width: maxWidth,  // Ensure it wraps correctly within the node's width
            alignment: go.Spot.Left,  // Align text to the left
          }, new go.Binding("text", "txValue", function(t) { return t.substring(0, 200); })),
          // Horizontal Panel for addedOn and followUpDate
          $$(go.Panel, "Vertical",
            {
              width: maxWidth,  // Match width with the above components
              alignment: go.Spot.Left,
              margin: new go.Margin(4, 0),  // Adjust top margin as needed
            },
            $$(go.TextBlock, {  // Text block for addedOn
              margin: new go.Margin(0, 4),  // Right margin for spacing
              font: '9.8px Lato, sans-serif',
              stroke: "#000",
              alignment: go.Spot.Left,  // Align text to the left
            }, new go.Binding("text", "addedOn")),
            $$(go.TextBlock, {  // Text block for followUpDate
              font: '9.8px Lato, sans-serif',
              margin: new go.Margin(0, 4),  // Right margin for spacing
              stroke: "#000",
              alignment: go.Spot.Left,  // Align text to the left
            }, new go.Binding("text", "followUpDate")),
            $$(go.TextBlock, {  // Text block for followUpDate
              font: '9.8px Lato, sans-serif',
              margin: new go.Margin(0, 4),  // Right margin for spacing
              stroke: "#000",
              alignment: go.Spot.Left,  // Align text to the left
            }, new go.Binding("text", "lastActivity"))
          )
        )
      );
  }

  fetchFollowUpCard(id) {
    var url = `/patients/${id}/follow_up_card?patient_id=${id}&id=${id}`;

    url = isAdmin() ? `/admin${url}` : url; // Ensure the URL is constructed correctly

    // Open the URL in a new tab
    window.open(url, '_blank');
  }

  pipelineChanged(event) {
    console.log('im here..')
    var id = event.target.value;

    var url = `/clinic_follow_ups?pipeline_id=${id}`;

    url = isAdmin() ? `/admin${url}` : url; // Ensure the URL is constructed correctly

    // Open the URL in a new tab
    window.location = url;
    // window.open(url);
  }
}
