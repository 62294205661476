import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

import {
  isAdmin,
} from "helpers";
export default class extends ApplicationController {
  static targets = ['email', 'phone', 'treatments', 'patient', 'startTime', 'calendar', 'appointmentType']
  static values = {
    patients: Array,
  }
  connect() {
    StimulusReflex.register(this)

    this.setTreatments(this.patientTarget.value);
    this.checkPreRequisites();
  }

  checkPreRequisites () {
    var params = {
      patient_id: this.patientTarget.value,
      calendar_id: this.calendarTarget.value,
      appointment_type_id: this.appointmentTypeTarget.value,
    }
    var url = '/appointments/check_pre_requisite';
    if (isAdmin()) {
      url = '/admin' + url;
    }
    $.ajax({
      url: url,
      method: 'GET',
      data: params,
      dataType: "script",
      success: (result) => {
      },
      error: (errors) => {
        console.log(errors)
      }
    });
  }

  startTimeChanged () {
    var vm = this;
    var params = {
      'appointment': {
        'start_date': (vm.startTimeTarget.value),
        'calendar_id': (vm.calendarTarget.value)
      }
    }
    this.stimulate(`Appointment#validate`, params);
  }

  patientChanged (e) {
    var patient = this.patientsValue.find(function(patient) {
      if (patient[0] == e.target.value)
        return patient
    });
    this.emailTarget.value = patient[1];
    this.phoneTarget.value = patient[2];
    this.setTreatments(patient[0]);
  }

  setTreatments (patientId) {
    if (!patientId) return;
    var vm = this;
    var url = `/patients/${patientId}/patient_treatments/set_treatments_appointment`;
    if (isAdmin()) {
      url = '/admin/' + url;
    }

    $.ajax({
      url: url,
      method: 'GET',
      data: {
        'treatment_statuses': ['unscheduled', 'scheduled'],
        'outcomes': ['accepted']
      },
      dataType: "script",
      success: (result) => {
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }
}
