import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = ['priorityDateDiv']

  connect() {
    StimulusReflex.register(this)
    console.log('connected')
  }

  priorityToggle (e) {
    var value = e.target.checked;
    console.log(value);
    if (value) {
      $(this.priorityDateDivTarget).removeClass('d-none')
    } else {
      $(this.priorityDateDivTarget).addClass('d-none')
    }
  }
}