import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

import {
  serializeFormData,
  currentUserInformation
} from "helpers";
export default class extends ApplicationController {
  static targets = ['filterForm', 'filterBtn', 'modal', 'search', 'availableFilters', 'dateFilter', 'tableView', 'kanbanView', 'tableViewBtn', 'kanbanViewBtn', 'smartFilterBtn', 'smartModal', 'listedConditions', 'saveListBtn', 'updateListBtn']
  static values = {
    filts: Array,
    smartConditions: Array,
  }

  reconnect(){
    var vm =this;
    $('.arrow').on('click', function(e){
      vm.applySort(e);
    })
  }

  connect() {
    StimulusReflex.register(this)
    this.builtSmartConditions = [];
    this.builtSmartColumns = [];
    this.appliedList = null;
    this.name = this.element.dataset.controller;
    this.timer;
    this.initFlatPickr();
    this.checkFilterActive();
    this.setSaveListBtn();
    var mainElement = this.element.querySelector('.field-filter-div');
    if ((mainElement).querySelector('.clearfilter')) {
      (mainElement).querySelector('.clearfilter').remove()
    }
    $(mainElement).prepend( "<div class='clearfilter'><a href='' data-action='click->filter#clearAll'>Clear All Filters</a></div>" );
  }

  clearAll (e) {
    e.preventDefault();
    var filtersArr = this.availableFiltersTarget.value.split(',')
    filtersArr.forEach(function(filter) {
      $(filter).val(null).trigger('change');
    })
  }

  checkFilterActive () {
    var filtersArr = this.availableFiltersTarget.value.split(',')
    var active = false;
    filtersArr.forEach(function(filter) {
      if (!$(filter)[0]) {
        return;
      }

      if ($(filter).length > 1) {
        var hiddenFilters = $(filter);
        for (var i=0, item; item = hiddenFilters[i]; i++) {
          if ($(item).val().length != 0) {
            active = true;
          }
        }
      }
      else if ($(filter).val().length != 0) {
        active = true;
      }
    });

    if (this.hasSmartFilterBtnTarget) {
      if (this.builtSmartConditions.length > 0) {
        $(this.smartFilterBtnTarget).addClass('active')
      } else {
        $(this.smartFilterBtnTarget).removeClass('active')
      }
    }

    if (active) {
      $(this.filterBtnTarget).addClass('active')
    } else {
      $(this.filterBtnTarget).removeClass('active')
    }
  }

  initFlatPickr () {
    var vm = this;
    if (!this.hasDateFilterTarget) { return; }

    vm.flatpickr = flatpickr(vm.dateFilterTarget, {
      mode: 'range',
      showMonths: 2,
      onChange: function(selectedDates, dateStr, instance) {
        var dateArr = selectedDates.map(date => this.formatDate(date, "Y-m-d"));
        if (dateArr.length == 2 || dateArr.length == 0) {
          var params = vm.serializeForm(vm.filterFormTarget);
          vm.query(params);
        }
      },
      onReady: function(dateObj, dateStr, instance) {
        var $cal = $(instance.calendarContainer);
        if ($cal.find('.flatpickr-clear').length < 1) {
          $cal.append('<a class="flatpickr-clear">Clear</a>');
          $cal.find('.flatpickr-clear').on('click', function() {
            instance.clear();
            instance.close();
          });
        }
      }
    })
  }

  toggleFilter (e) {
    $(this.modalTarget).modal('toggle');
    e.preventDefault();
  }


  searchInput() {
    var params = this.serializeForm(this.filterFormTarget);
    params['search'] = this.searchTarget.value;
    this.query(params);
  }

  filter(e) {}

  applySort (e) {
    var params = this.serializeForm(this.filterFormTarget);
    params['sort_attribute'] = e.target.dataset['attribute'];
    params['direction'] = e.target.dataset['direction'];
    this.query(params);
    e.preventDefault();
  }

  searchInputDelay() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.searchInput(), 300);
  }

  applyFilters (e) {
    if (e)
      e.preventDefault();
    var params = this.serializeForm(this.filterFormTarget);
    this.query(params);
    $('.default-value').val('');
    this.checkFilterActive();
  }

  extractSmartConditions(params) {
    if (this.hasListedConditionsTarget) {
      var conditions = [];
      var listedConditions = this.listedConditionsTarget;
      $(listedConditions)[0].querySelectorAll('a').forEach(function(link) {
        conditions.push((link.dataset['params']));
      })
      if (conditions.length > 0) {
        params['conditions'] = conditions;
      } else {
        params['conditions'] = null;
      }
    }

    return params;
  }

  query (params) {
    var vm = this;
    params['filters'] = 'present';
    var address = this.filterFormTarget.action;
    if (this.flatpickr) {
      var dateArr = this.flatpickr.selectedDates;
    } else {
      dateArr = null;
    }
    params = this.extractSmartConditions(params);
    params['smart_column_ids'] = this.builtSmartColumns;

    window.mainParams = params;
    if (dateArr) {
      dateArr = dateArr.map(date => this.flatpickr.formatDate(date, "Y-m-d"));
      if (dateArr.length == 2) {
        params['start_date'] = dateArr[0];
        params['end_date'] = dateArr[1];
      }
    }
    $('body').addClass('loading');
    $.ajax({
      url: address,
      method: 'GET',
      data: params,
      dataType: "script",
      success: (result) => {
        $(this.modalTarget).modal('hide');
        $('body').removeClass('loading');
        vm.reconnect();
      },
      error: (error) => {
        $('body').removeClass('loading');
        console.log(error)
        return;
      },
    });
  }

  serializeForm(form) {
    return serializeFormData(form)
  }

  toggleSmartFilter (e) {
    if (this.hasSmartModalTarget) {
      $(this.smartModalTarget).modal('toggle');
      if (this.builtSmartConditions.length <= 0) {
        $('.main-filters')[0].click();
      }
      if (e) {
        e.preventDefault();
      }
    }
  }

  smartOptionClicked (e){
    var el = e.target;

    if (!$(el).hasClass('card-header')) {
      el = el.closest('.card-header');
    }

    this.stimulate("SmartFilter#smart_option_clicked", el);
  }

  smartOptionEditClicked (e){
    var params = e.target.dataset['params']
    params = JSON.parse(params)
    params.index = e.target.dataset['index'];

    this.stimulate("SmartFilter#smart_option_edit", params);
  }

  smartOptionDelete (e) {
    var target = e.target;
    var token = JSON.parse(e.target.dataset['params']).authenticity_token;
    var index = this.builtSmartConditions.findIndex(function(condition) { return token == condition.authenticity_token});
    (this.builtSmartConditions).splice(index, 1);
    $(`#listed-condition-${index}`)
    $(`#listed-condition-${index}`)[0].remove()
    this.setSaveListBtn();
    this.stimulate("SmartFilter#add_option", this.builtSmartConditions);
    this.broadcastToColumnDropdown();
  }

  addToList(formData) {
    var index = formData.index;
    if (index && index != '') {
      index = parseInt(index);
      this.builtSmartConditions[index] = formData;
    } else {
      this.builtSmartConditions.push(formData);
    }

    this.stimulate("SmartFilter#add_option", this.builtSmartConditions);
    this.broadcastToColumnDropdown();
  }

  broadcastToColumnDropdown() {
    var node = $('.checkbox-dropdown')[0];
    var destCtrl = this.application.getControllerForElementAndIdentifier(node, 'checkbox-dropdown');
    destCtrl.selectBasedOnList(this.builtSmartConditions);
  }

  setList (list) {
    var vm = this;
    this.builtSmartConditions = [];
    var conditions = list.with_conditions.smart_conditions;
    conditions.forEach(function (c) {
      vm.builtSmartConditions.push(c);
    });

    var userInfo = currentUserInformation();
    var listOwnerInfo = list.owner_information;

    if (listOwnerInfo.user_id.toString() == userInfo.userId.toString() && listOwnerInfo.user_type.replace(/[_-]/g, "") == userInfo.userType.replace(/[_-]/g, "")) {
      $(this.updateListBtnTarget)[0].disabled = false;
      $(this.updateListBtnTarget).removeClass('d-none');
      $(this.updateListBtnTarget)[0].dataset['listId'] = list.id;
      this.appliedList = list;
    }
    this.applyFilters(null);
    this.stimulate("SmartFilter#add_option", this.builtSmartConditions);
  }

  setSmartColumns (list) {
    var vm = this;
    this.builtSmartColumns = list;
    this.applyFilters(null);
  }

  addOptionSuccess (element, reflex, noop, reflexId) {
    this.setSaveListBtn();
    this.goBack();
    this.checkFilterActive();
  }

  goBack () {
    $("#addFilterModal").modal('hide');
    $('#smartFilterModal').modal('show');
    $('.applied-conditions').click();
  }

  setSaveListBtn () {
    if (this.hasSaveListBtnTarget) {
      $(this.saveListBtnTarget)[0].disabled = !(this.builtSmartConditions.length > 0)
      if (this.builtSmartConditions.length > 0)
        $(this.saveListBtnTarget).removeClass('d-none');
    }
  }

  viewChanged (e) {
    e.preventDefault();

    var target = e.target;
    var selection = target.id;
    if (selection == 'table') {
      $(this.tableViewTarget).removeClass('d-none');
      $(this.tableViewBtnTarget).addClass('active');

      $(this.kanbanViewTarget).addClass('d-none');
      $(this.kanbanViewBtnTarget).removeClass('active');
    } else {
      $(this.kanbanViewTarget).removeClass('d-none');
      $(this.kanbanViewBtnTarget).addClass('active');

      $(this.tableViewTarget).addClass('d-none');
      $(this.tableViewBtnTarget).removeClass('active');
    }
  }
}
