import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'intent', 'diagnosisDiv', 'medicalDiv']

  connect() {
    if (this.hasIntentTarget) {
      this.setDivs(this.intentTarget.value);
    }
  }


  intentChanged (e) {
    this.setDivs(e.target.value);
  }

  setDivs (intentVal) {
    if (intentVal == 'patient') {
      $(this.medicalDivTarget).addClass('d-none');
      $((this.medicalDivTarget).querySelectorAll('select')).prop('required', false)
      $((this.medicalDivTarget).querySelectorAll('input')).prop('required', false)

    }
    else {
      $(this.medicalDivTarget).removeClass('d-none');
      $((this.medicalDivTarget).querySelectorAll('select')).prop('required', true)
      $((this.medicalDivTarget).querySelectorAll('input')).prop('required', true)
    }
  }
}
