import "core-js/stable"
import "regenerator-runtime/runtime"
import "../stylesheets/application"

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require('jquery')
require("channels")
require('@nathanvda/cocoon')
require("steps/jquery.steps.min")
require("validate/jquery.validate.min")

import "js/plugins";
import "js/inspinia";
import "js/script";
import 'js/moment-js-data';
require("js/flowy")
import 'controllers'
import imgadd from "images/workflow-add.svg";
window.imgadd = imgadd;

import imgremove from "images/workflow-remove.svg";
window.imgremove = imgremove;

import imgsetting from "images/setting-workflow.svg";
window.imgsetting = imgsetting;

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
import "controllers"
import ClipboardController from "../controllers/sub_treatment_creator_controller"
window.clipCtrl = ClipboardController;
