import { param } from "jquery";
import { Controller } from "stimulus"
import {
  serializeFormData
} from "helpers";
export default class extends Controller {
	static targets = ['overview', 'form', 'id', 'flowyInput']

  connect() {
    if(this.hasFormTarget) {
			const vm = this;
			vm.myDiagram = null;
      vm.copiedNode = [];
      this.init(vm);
      $('body')[0].classList.add('mini-navbar');
			var out = JSON.parse(vm.flowyInputTarget.value);
			if (out.class)
				vm.myDiagram.model = go.Model.fromJson(out);
    }
  }

  serializeForm(form) {
    return serializeFormData(form)
  }

  form_data(form) {
    return this.serializeForm(form);
  }

  init(vm) {
    let _self = this;
    var $$ = go.GraphObject.make;  // for conciseness in defining templates
     vm.myDiagram =
      $$(go.Diagram, "myDiagramDiv",
        {
          allowCopy: false,
          "draggingTool.dragsTree": true,
          "commandHandler.deletesTree": true,
          "commandHandler.copiesTree": true,
          layout:
            $$(go.TreeLayout,
              { angle: 90, arrangement: go.TreeLayout.ArrangementFixedRoots, nodeSpacing: 10, layerSpacing: 150, }),
          "undoManager.isEnabled": true
        });
    vm.myDiagram.addDiagramListener("Modified", function(e) {
      var button = document.getElementById("SaveButton");
      if (button) button.disabled = !vm.myDiagram.isModified;
      var idx = document.title.indexOf("*");
      if (vm.myDiagram.isModified) {
        if (idx < 0) document.title += "*";
      } else {
        if (idx >= 0) document.title = document.title.substr(0, idx);
      }
    });

    var bluegrad = $$(go.Brush, "Linear", { 0: "#fff", 1: "#fff" });
    var greengrad = $$(go.Brush, "Linear", { 0: "#2667ff", 1: "#2667ff" });
    var actionTemplate =
      $$(go.Panel, "Horizontal",
        $$(go.Shape,
          { width: 12, height: 12, margin: new go.Margin(0,10,0,0) },
          new go.Binding("figure"),
          new go.Binding("fill")
        ),
        $$(go.TextBlock,
          {
            font: " 10pt Verdana, sans-serif", stroke: "#1934B8",
          },
          new go.Binding("text")
        )
      );
    vm.configureRequest = 0;
    vm.setStartNode(vm, $$, bluegrad, greengrad, newNodeModal);
    vm.setDefaultNode(vm, $$, bluegrad, newNodeModal, configureBlock, actionTemplate, copyTree, pasteTree, checkDeleteButtonsVisibility, deleteForm, deleteTree);
    vm.setExternalFormNode(vm, $$, bluegrad, newNodeModal, configureBlock, actionTemplate, copyTree, pasteTree, checkDeleteButtonsVisibility, deleteForm, deleteTree, 'ExternalForm');
    vm.setDefaultActionNode(vm, $$, bluegrad, newNodeModal, configureBlock, actionTemplate, copyTree, pasteTree, checkDeleteButtonsVisibility, deleteForm, deleteTree, 'SendFax');
    vm.setLinkTemplate(vm, $$);

    var nodeDataArray = [
      { key: 0, category: "Start", text: "Start" },
    ];
    var linkDataArray = [

    ];

    vm.myDiagram.model = $$(go.GraphLinksModel,
      {
        copiesArrays: true,
        copiesArrayObjects: true,
        nodeDataArray: nodeDataArray,
        linkDataArray: linkDataArray
      });

		function configureBlock (e, obj) {
      $('.nav-link').removeClass('active')

			var fromNode = obj.part;
      var blockId = fromNode.data.key;

      $.ajax({
        url: `/admin/master_workflows/${vm.idTarget.value}/blocks/${blockId}/configure`,
        method: 'GET',
        success: (result) => {
          if (vm.configureRequest == 0) {
            console.log('hacky way to load CKeditor :/')
            vm.configureRequest = 1;
            configureBlock(e, obj);
          }
        },
        error: (error) => {
          console.log('err', error);
          toastr.error('Something went wrong', error);
          return;
        },
      });
		}

    function newNodeModal (e, obj) {
      $('#new-block-body').html("");
      $('#newNodeModal').modal('show');
      $('.workflow-modals-options').removeClass('d-none');
      $('#closeNewNodeModal').on('click', function(){
        $('#new-block-body').html("")
        return;
      });

      setUpNewFormModal(vm, e, obj);
      setUpNewExternalFormModal(vm, e, obj);
      setUpTriggerWorkflowModal(vm, e, obj);
      setUpSendFaxModal(vm, e, obj);
    }

    function setUpSendFaxModal (vm, e, obj) {
			var fromNode = obj.part;
      $("#new-fax").off("click");

      $('#new-fax').on('click', function(){
        $('.workflow-modals-options').addClass('d-none');
        $.ajax({
          url: `/admin/master_workflows/${vm.idTarget.value}/blocks/new`,
          method: 'GET',
          data: { blocks: { id: obj.part.data.key, block_type: 'SendFax' }},
          dataType: "script",
          success: (result) => {
            if($('#split-rules') && $('#split-rules')[0].children.length > 0) {
              $('#where_to').val('branch');
            }
            addChangeListenerOnBlockType();
            return;
          },
          error: (error) => {
            toastr.error('Something went wrong');
            location.reload()
            $('#newNodeModal').modal('hide');
            return;
          },
        });
        $("#saveNewNodeModal").off("click");
        $('#saveNewNodeModal').on('click', function() {

        	var form = this.parentElement.parentElement.querySelector('form');
          var formData = new FormData(form);
          var isValid = form.reportValidity();

          if (!isValid) return;
        	formData.append('previous_block_id', fromNode.data.key);
          formData.append('block_type', 'SendFax');
          var emailEditor = CKEDITOR.instances['block_email_body'];

          if (emailEditor) {
            formData.append("block[cover_body]", emailEditor.getData())
            CKEDITOR.remove(emailEditor);
          }

          createBlockRequestWithFile(vm, formData, obj, form, fromNode);
        });
      });
    }

    function setUpTriggerWorkflowModal (vm, e, obj) {
			var fromNode = obj.part;
      $("#trigger-workflow").off("click");

      $('#trigger-workflow').on('click', function(){
        $('.workflow-modals-options').addClass('d-none');
        $.ajax({
          url: `/admin/master_workflows/${vm.idTarget.value}/blocks/new`,
          method: 'GET',
          data: { blocks: { id: obj.part.data.key, block_type: 'TriggerWorkflow' }},
          dataType: "script",
          success: (result) => {
            if($('#split-rules') && $('#split-rules')[0].children.length > 0) {
              $('#where_to').val('branch');
            }
            addChangeListenerOnBlockType();
            return;
          },
          error: (error) => {
            toastr.error('Something went wrong');
            location.reload()
            $('#newNodeModal').modal('hide');
            return;
          },
        });
        $("#saveNewNodeModal").off("click");
        $('#saveNewNodeModal').on('click', function() {
        	var form = this.parentElement.parentElement.querySelector('form');
          var isValid = form.reportValidity();
          if (!isValid) return;
        	var params = vm.form_data(form);
        	params.previous_block_id = fromNode.data.key;
          params.block_type = 'TriggerWorkflow'
          createBlockRequest(vm, params, obj, form, fromNode);
        });
      });
    }

    function setUpNewFormModal (vm, e, obj) {
			var fromNode = obj.part;
      $("#new-block-modal").html("");

      $("#new-form").off("click");
      $('#new-form').on('click', function(){
        $('.workflow-modals-options').addClass('d-none');
        $.ajax({
          url: `/admin/master_workflows/${vm.idTarget.value}/blocks/new`,
          method: 'GET',
          data: { blocks: { id: obj.part.data.key, block_type: 'ChildrenWorkflow', form_type: 'internal' }},
          dataType: "script",
          success: (result) => {
            if($('#split-rules') && $('#split-rules')[0].children.length > 0) {
              $('#where_to').val('branch');
            }
            addChangeListenerOnBlockType();
            return;
          },
          error: (error) => {
            toastr.error('Something went wrong');
            location.reload()
            $('#newNodeModal').modal('hide');
            return;
          },
        });
        $("#saveNewNodeModal").off("click");
        $('#saveNewNodeModal').on('click', function() {
        	var form = this.parentElement.parentElement.querySelector('form');
          var isValid = form.reportValidity();
          if (!isValid) return;
          isValid = vm.validSplitRules();
          if (!isValid) return;

        	var params = vm.form_data(form);
        	params.previous_block_id = fromNode.data.key;
          params.block_type = 'ChildrenWorkflow';
          params.form_type = 'internal';
          createBlockRequest(vm, params, obj, form, fromNode);
        });
      });
    }

    function setUpNewExternalFormModal (vm, e, obj) {
			var fromNode = obj.part;
      $("#new-external-form").off("click");

      $('#new-external-form').on('click', function(){
        $('.workflow-modals-options').addClass('d-none');
        $.ajax({
          url: `/admin/master_workflows/${vm.idTarget.value}/blocks/new`,
          method: 'GET',
          data: { blocks: { id: obj.part.data.key, block_type: 'ChildrenWorkflow', form_type: 'external' }},
          dataType: "script",
          success: (result) => {
            if($('#split-rules') && $('#split-rules')[0].children.length > 0) {
              $('#where_to').val('branch');
            }
            addChangeListenerOnBlockType();
            return;
          },
          error: (error) => {
            toastr.error('Something went wrong');
            location.reload()
            $('#newNodeModal').modal('hide');
            return;
          },
        });
        $("#saveNewNodeModal").off("click");
        $('#saveNewNodeModal').on('click', function() {
        	var form = this.parentElement.parentElement.querySelector('form');
          var isValid = form.reportValidity();
          if (!isValid) return;
          isValid = vm.validSplitRules();
          if (!isValid) return;

        	var params = vm.form_data(form);
        	params.previous_block_id = fromNode.data.key;
          params.block_type = 'ChildrenWorkflow';
          params.form_type = 'external';
          var emailEditor = CKEDITOR.instances['block_email_body'];

          if (emailEditor) {
            params["block[email_body]"] = emailEditor.getData();
            CKEDITOR.remove(emailEditor);
          }
          createBlockRequest(vm, params, obj, form, fromNode);
        });
      });
    }

    function createBlockRequest (vm, params, obj, form, fromNode) {
      $.ajax({
        url: `/admin/master_workflows/${vm.idTarget.value}/blocks`,
        method: 'POST',
        data: params,
        dataType: "json",
        accept: 'json',
        success: (result) => {
          var revision = result.revision;

          if (revision.present) {
            window.location.replace(window.location.origin + `/admin/master_workflows/${revision.new_id}/edit`);
          } else {
            if($('#block_where_to :selected').text() == "Linear") {
              insertLinearNode(obj, result);
            } else {
              insertNode(event, obj, result.record, JSON.parse(result.block));
            }
            $('#newNodeModal').modal('hide');
          }
          return;
        },
        error: (error) => {
          toastr.error('Something went wrong');
          location.reload()
          $('#newNodeModal').modal('hide');
          return;
        },
      });
			$("#saveNewNodeModal").off("click");
    }

    function createBlockRequestWithFile (vm, params, obj, form, fromNode) {
      $.ajax({
        url: `/admin/master_workflows/${vm.idTarget.value}/blocks`,
        method: 'POST',
        data: params,
        dataType: "json",
        accept: 'json',
        processData: false,
        contentType: false,
        mimeType: 'multipart/form-data',    //Property added in 1.5.1
        success: (result) => {
          var revision = result.revision;

          if (revision.present) {
            window.location.replace(window.location.origin + `/admin/master_workflows/${revision.new_id}/edit`);
          } else {
            if($('#block_where_to :selected').text() == "Linear") {
              insertLinearNode(obj, result);
            } else {
              insertNode(event, obj, result.record, JSON.parse(result.block));
            }
            $('#newNodeModal').modal('hide');
          }
          return;
        },
        error: (error) => {
          toastr.error('Something went wrong');
          location.reload()
          $('#newNodeModal').modal('hide');
          return;
        },
      });
			$("#saveNewNodeModal").off("click");
    }

    function addChangeListenerOnBlockType(){
      $('#block_where_to').on('change', function(e) {
        if($('#block_where_to :selected').text() == "Linear") {
          $('#split-rules').addClass('d-none');
          $('#split-rules')[0].querySelectorAll('select').forEach(function(n) {
            n.removeAttribute('required')
          })
        } else {
          $('#split-rules').removeClass('d-none');
          $('#split-rules')[0].querySelectorAll('select').forEach(function(n) {
            n.setAttribute('required', 'true')
          })
        }
      });
    }

    function insertLinearNode(obj, result) {
      var newNode = JSON.parse(result.block)
      var newNodeData = {
                          key: newNode.id,
                          question: newNode.custom_attributes.title,
                          category: newNode.custom_attributes.category,
                          actions: [{ text: "No Actions", figure: "ElectricalHazard", fill: "blue" },] };

      var fromNode = obj.part;
      var diagram = fromNode.diagram;
      var model = fromNode.diagram.model;

      // Create New Node
      diagram.startTransaction("make new node");
      model.addNodeData(newNodeData);
      diagram.commitTransaction("make new node");

      // Attach from nodes children to new node
      var children = fromNode.findNodesOutOf();
      children.each(function(n){
        var linkdata = {
          from: model.getKeyForNodeData(newNodeData),
          to: model.getKeyForNodeData(n.data),
          text: ''
        };
        model.addLinkData(linkdata);
      })

      // Delete all from node outOf links
      diagram.startTransaction("Deleted Links");
      diagram.removeParts(obj.part.findLinksOutOf());
      diagram.commitTransaction("Deleted Links");

      // Create link between from node and new node
      var linkdata = {
        from: model.getKeyForNodeData(fromNode),
        to: model.getKeyForNodeData(newNodeData),
        text: ''
      };
      model.addLinkData(linkdata);
    }

    // delete nodes from gojs frontend side
    function deleteNodes(obj, option) {
      var parentNode = obj.part.findNodesInto().first();

      // for deleting selected sub tree
      if (option == 'tree') {
        // remove the remaining link text if parent has only two childs
        deleteSplitRule(obj, parentNode);
        obj.part.diagram.removeParts(obj.part.findTreeParts());
        return;
      }

      // for deleting only the selected block
      if (obj.part.findNodesOutOf().count > 0) {
        let childNode = obj.part.findNodesOutOf().first();

        let model = obj.part.diagram.model;
        let parentLinkText = '';
        // get the link text if parent has split rules to use it for the new attach child
        parentNode.findLinksOutOf().each(function(l) {
          if (l == obj.part.findLinksInto().first()) {
            parentLinkText = l.data.text;
          }
        });

        var linkdata = {
          from: model.getKeyForNodeData(parentNode.data),
          to: model.getKeyForNodeData(childNode.data),
          text: parentLinkText
        };
        model.addLinkData(linkdata);
        deleteNode(obj);
      }else {
        deleteSplitRule (obj, parentNode);
        deleteNode(obj);
      }
    }

    function deleteSplitRule (obj, parentNode) {
      var siblingNode;
      if(parentNode.findLinksOutOf().count == 2) {
        parentNode.findLinksOutOf().each(function(n) {
          if(n.data.text != obj.part.findLinksInto().first().data.text) {
            siblingNode = n;
          }
        });
        var data = siblingNode.data;
        var model = obj.part.diagram.model;
        model.startTransaction("modified property");
        model.set(data, "text", '');
        model.commitTransaction("modified property");
      }
    }

    // delete the node from gojs
    function deleteNode (obj) {
      let diagram = obj.part.diagram;
      diagram.startTransaction();
      diagram.remove(obj.part);
      diagram.commitTransaction("deleted node");
    }

		function insertNode (e, obj, record, block) {
      var selectedIds, selectedVals = [];
			var fromNode = obj.part;
			var diagram = fromNode.diagram;
			diagram.startTransaction("Add State");
			var fromData = fromNode.data;
			var p = fromNode.location.copy();
			p.x += diagram.toolManager.draggingTool.gridSnapCellSize.width;
			var toData = {
				key: block.id, question: block.custom_attributes.title,
        category: block.custom_attributes.category,
				actions: [
					{ text: "No Actions", fill: "blue" },
				]
			};

			var model = diagram.model;
			model.addNodeData(toData);

      selectedIds = $('.field-options-tags').val();
      selectedVals = [];

      $( ".field-options-tags")[0].options.forEach(function (option) {
        if (selectedIds.includes(option.value)) {
          selectedVals.push(option.text)
        }
      })
			var linkdata = {
				from: model.getKeyForNodeData(fromData),
				to: model.getKeyForNodeData(toData),
        text: selectedVals ? selectedVals.join() : ''
			};
			model.addLinkData(linkdata);

      var data = fromNode.findLinksOutOf().first().data;
      if($('#split-rules') && $('#split-rules')[0].children.length > 0 && !data.text) {
        model.startTransaction("modified property");
        selectedIds = $('.block-question-value-nested').val();
        selectedVals = [];
        $( ".field-options-tags-nested")[0].options.forEach(function (option) {
          if (selectedIds.includes(option.value)) {
            selectedVals.push(option.text)
          }
        })

        model.set(data, "text", selectedVals.join());
        model.commitTransaction("modified property");
      }

			var newnode = diagram.findNodeForData(toData);
			diagram.select(newnode);
			newnode.location = diagram.toolManager.draggingTool.computeMove(newnode, p);
			shiftNodesToEmptySpaces();
			diagram.commitTransaction("Add State");
		}

    // to delete only the selected one block
    function deleteForm (e, obj) {
      var node = obj.part.diagram.findNodeForData(obj.part.data);

      if (node.findLinksOutOf().count > 1) {
        toastr.error("Can't delete these due to child nodes. Delete them first");
        return;
      } else {
        deleteRequest(node, node.data.key, false, 'form');
      }
    }

    // to delete the whole sub tree
    function deleteTree (e, obj) {
      var node = obj.part.diagram.findNodeForData(obj.part.data);

      let nodeSelected = obj.part.data.key
      deleteRequest(node, nodeSelected, true, 'tree');
    }

    // send request to backend to delete the blocks
    function deleteRequest (node, nodesSelected, deleteTree, formOrTree) {
      $.ajax({
        url: `/admin/master_workflows/${vm.idTarget.value}/blocks/${node.data.key}`,
        method: 'DELETE',
        data: { selected_nodes: nodesSelected, delete_tree: deleteTree },
        success: (result) => {
          if (result.revision) {
            window.location.replace(window.location.origin + `/admin/master_workflows/${result.id}/edit`);
          } else {
            deleteNodes(node, formOrTree);
          }
          return;
        },
        error: (error) => {
          toastr.error('Something went wrong');
          location.reload()
          return;
        },
      });
    }

    function copyTree (e, obj) {
      var nodeSelected = obj.part.data.key
      vm.copiedNode = [nodeSelected];
    }

    function pasteTree(e, obj) {
      var node = obj.part.diagram.findNodeForData(obj.part.data);
      if(vm.copiedNode.length != 0) {
        var model = obj.diagram.model;

        if (node.findNodesOutOf().count > 0) {
          toastr.error("Paste only works on leaf nodes");
          return;
        }

        $.ajax({
          url: `/admin/master_workflows/${vm.idTarget.value}/blocks/copy_tree`,
          method: 'POST',
          data: { copied_parent_node: vm.copiedNode, target_block: node.data.key },
					dataType: "json",
          timeout: 100000,
          success: (result) => {
            if (result.revision) {
              window.location.replace(window.location.origin + `/admin/master_workflows/${result.master_workflow_id}/edit`);
            } else {
              model.commit(m => {
                m.addNodeDataCollection(result.node_data_array);
                m.addLinkDataCollection(result.link_data_array);
              }, "added nodes and links");

              vm.copiedNode = [];
              return;
            }
          },
          error: (error) => {
            toastr.error('Something went wrong');
            location.reload()
            return;
          },
        });
      } else {
        toastr.error('Nothing Selected');
      }
    }

    function shiftNodesToEmptySpaces() {
      vm.myDiagram.selection.each(function(node) {
        if (!(node instanceof go.Node)) return;
        while (true) {
          var exist = vm.myDiagram.findObjectsIn(node.actualBounds,
            function(obj) { return obj.part; },
            function(part) { return part instanceof go.Node && part !== node; },
            true).first();
          if (exist === null) break;
          node.position = new go.Point(node.actualBounds.x, exist.actualBounds.bottom + 10);
        }
      });
    }

    // to check if delete buttons should visible or not
    function checkDeleteButtonsVisibility () {
      return (vm.myDiagram.selection.count <= 1)
    }
  }

  setStartNode(vm, $$, bluegrad, greengrad, newNodeModal) {
    vm.myDiagram.nodeTemplateMap.add("Start",
      $$(go.Node, "Spot",
        $$(go.Shape, "Circle",
          { width: 75, height: 75, fill: greengrad, stroke: "null" }
        ),
        $$(go.TextBlock,
          { font: "bold 10pt Verdana, sans-serif", stroke: "white" },
          new go.Binding("text")
        ),
        $$(go.Panel, "Auto",
          { alignment: go.Spot.Right, portId: "from", fromLinkable: true, cursor: "pointer", click: newNodeModal,  name: 'newBtn' },
          $$(go.Panel,
            $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgadd } ),
          ),
        ),
      )
    );
  }

  setDefaultNode(vm, $$, bluegrad, newNodeModal, configureBlock, actionTemplate, copyTree, pasteTree, checkDeleteButtonsVisibility, deleteForm, deleteTree) {
    vm.myDiagram.nodeTemplate =  // the default node template
      $$(go.Node, "Vertical",
        new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
        new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded

        { selectionObjectName: "BODY" },

        $$(go.Panel, "Auto",
          {
            name: "BODY"
          },
          $$(go.Shape, "RoundedRectangle",
            {
              fill: bluegrad, stroke: "#ccc",
            },
          ),
          $$(go.Panel, "Vertical",
            { margin: 10,
              width: 200,
             },

          $$(go.TextBlock,
            {
              stretch: go.GraphObject.Horizontal,
              font: "bold 12pt Verdana, sans-serif", stroke: "#1934B8",
              margin: new go.Margin(10, 0, 15, 0),
            },
            new go.Binding("text", "question")
            ),
            $$(go.Panel, "Vertical",
              { stretch: go.GraphObject.Horizontal, visible: true },  // not visible unless there is more than one action
              new go.Binding("visible", "actions", function(acts) {
                return true //(Array.isArray(acts) && acts.length > 0);
              }),
              $$(go.Panel, "Table",
                { stretch: go.GraphObject.Horizontal },
                $$(go.TextBlock, "Actions",
                  {
                    alignment: go.Spot.Left,
                    font: "bold 10pt Verdana, sans-serif", stroke: "#1934B8",
                  }
                ),
               $$("PanelExpanderButton", "COLLAPSIBLE",  // name of the object to make visible or invisible
                 { column: 1, alignment: go.Spot.Right },
               ),
             ), // end Table panel
             ),  // end optional Vertical Panel
             $$(go.Panel, "Vertical",
              {
                 name: "COLLAPSIBLE",  // identify to the PanelExpanderButton
                 padding: 2,
                 margin: new go.Margin(10,0,0,0),
                 stretch: go.GraphObject.Horizontal,  // take up whole available width
                 background: "white",  // to distinguish from the node's body
                 defaultAlignment: go.Spot.Left,  // thus no need to specify alignment on each element
                 itemTemplate: actionTemplate  // the Panel created for each item in Panel.itemArray
              },
               new go.Binding("itemArray", "actions")  // bind Panel.itemArray to nodedata.actions
            ),  // end optional Vertical Panel
          ),
        ),
        $$(go.Panel,
          $$(go.Panel, "Table",
            $$("TreeExpanderButton",
              {
                cursor: "pointer",
                "ButtonBorder.strokeWidth": 0,
                "ButtonBorder.fill": "white",
                row: 0,
                column: 1,
                margin: new go.Margin(3, 0, 0, 0),
              },
              $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgremove } ),
            ),
            $$(go.Panel, "Auto",
              {
                row: 0,
                name: 'settingBtn',
                column: 3,
                margin: new go.Margin(3, 0, 0, 3),
                portId: "from",
                fromLinkable: true,
                click: configureBlock,
                cursor: "pointer",
              },
              $$(go.Panel,
                $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgsetting } )
              ),
            ),
            $$(go.Panel, "Auto",
              {
                portId: "from",
                name: 'newBtn',
                fromLinkable: true,
                cursor: "pointer",
                click: newNodeModal,
                row: 0,
                column: 2,
                margin: new go.Margin(3, 0, 0, 0),
              },
              $$(go.Panel,
                $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgadd } ),
              ),
            ),
          ),
        ),
        {
          contextMenu:
            $$("ContextMenu",
              $$("ContextMenuButton",
                {
                  "ButtonBorder.fill": "white",
                  "_buttonFillOver": "skyblue"
                },
                $$(go.TextBlock, "Delete Form"),
                { click: deleteForm },
                new go.Binding("visible", checkDeleteButtonsVisibility)
                ),
              $$("ContextMenuButton",
                {
                  "ButtonBorder.fill": "white",
                  "_buttonFillOver": "skyblue"
                },
                $$(go.TextBlock, "Delete Sub Tree"),
                { click: deleteTree },
                new go.Binding("visible", checkDeleteButtonsVisibility)
                ),
              $$("ContextMenuButton",
                {
                  "ButtonBorder.fill": "white",
                  "_buttonFillOver": "skyblue"
                },
                $$(go.TextBlock, "Copy Tree"),
                { click: copyTree }),
              $$("ContextMenuButton",
              {
                "ButtonBorder.fill": "white",
                "_buttonFillOver": "skyblue"
              },
              $$(go.TextBlock, "Paste Tree",
              new go.Binding("visible", "text", function(s) { if (vm.copiedNode != []) return true; else return false; })),
              { click: pasteTree })
            )
          }
      );
  }

  setExternalFormNode(vm, $$, bluegrad, newNodeModal, configureBlock, actionTemplate, copyTree, pasteTree, checkDeleteButtonsVisibility, deleteForm, deleteTree, blockType) {
    var greengrad = $$(go.Brush, "Linear", { 0: "#fff", 1: "#fff" });

    vm.myDiagram.nodeTemplateMap.add('ExternalForm',
      $$(go.Node, "Vertical",
      new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
      new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded

      { selectionObjectName: "BODY" },
      $$(go.Panel, "Auto",
        {
          name: "BODY",
        },
        $$(go.Shape, "RoundedRectangle",
          {
            fill: greengrad, stroke: "#ccc", strokeWidth: 1
          }
        ),
        $$(go.Panel, "Vertical",
          { margin: 10,
            width: 200,
          },
          $$(go.TextBlock,
            {
              row: 1,
              column: 0,
              font: "bold 12pt Verdana, sans-serif", stroke: "#1934B8",
              margin: new go.Margin(0, 0, 0, 0), stroke: "#1934B8",
            },
            new go.Binding("text", "question")
          ),
        ),
      ),
      $$(go.Panel,
        $$(go.Panel, "Table",
          $$("TreeExpanderButton",
            {
              cursor: "pointer",
              "ButtonBorder.strokeWidth": 0,
              "ButtonBorder.fill": "white",
              row: 0,
              column: 1,
            },
            $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgremove } ),
          ),
          $$(go.Panel, "Auto",
            {
              name: 'settingBtn',
              row: 0,
              column: 3,
              margin: new go.Margin(3, 0, 0, 3),
              portId: "from",
              fromLinkable: true,
              click: configureBlock,
              cursor: "pointer",
            },
            $$(go.Panel,
              $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgsetting } )
            ),
          ),
          $$(go.Panel, "Auto",
            {
              name: 'newBtn',
              portId: "from",
              fromLinkable: true,
              cursor: "pointer",
              click: newNodeModal,
              row: 0,
              column: 2,
              margin: new go.Margin(2, 0, 0, 0),
            },
            $$(go.Panel,
              $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgadd } ),
            ),
          ),
        ),
      ),
      {
        contextMenu:
          $$("ContextMenu",
            $$("ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              "_buttonFillOver": "skyblue"
            },
            $$(go.TextBlock, "Delete Form"),
            { click: deleteForm },
            new go.Binding("visible", checkDeleteButtonsVisibility)
            ),
          $$("ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              "_buttonFillOver": "skyblue"
            },
            $$(go.TextBlock, "Delete Sub Tree"),
            { click: deleteTree },
            new go.Binding("visible", checkDeleteButtonsVisibility)
            ),
            $$("ContextMenuButton",
              {
                "ButtonBorder.fill": "white",
                "_buttonFillOver": "skyblue"
              },
              $$(go.TextBlock, "Copy Tree"),
              { click: copyTree }),
            $$("ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              "_buttonFillOver": "skyblue"
            },
            $$(go.TextBlock, "Paste Tree",
            new go.Binding("visible", "text", function(s) { if (vm.copiedNode != []) return true; else return false; })),
            { click: pasteTree })
          )
        }
      )
    );
  }

  setDefaultActionNode(vm, $$, bluegrad, newNodeModal, configureBlock, actionTemplate, copyTree, pasteTree, checkDeleteButtonsVisibility, deleteForm, deleteTree, nodeType) {
    var greengrad = $$(go.Brush, "Linear", { 0: "#fff", 1: "#fff" });

    vm.myDiagram.nodeTemplateMap.add(nodeType,
      $$(go.Node, "Vertical",
      new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
      new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded

      { selectionObjectName: "BODY" },
      $$(go.Panel, "Auto",
        { name: "BODY" },
        $$(go.Shape, "RoundedRectangle",
          { fill: greengrad, stroke: "#ccc" },
        ),
        $$(go.Panel, "Vertical",
          { margin: 10,
            width: 220,
            row: 0,
            column: 1,
          },
          $$(go.TextBlock,
            {
              stretch: go.GraphObject.Horizontal,
              font: "bold 12pt Verdana, sans-serif", stroke: "#1934B8",
              margin: new go.Margin(10, 0, 15, 0),
            },
            new go.Binding("text", "question")
          ),
          )
      ),
      $$(go.Panel,
        $$(go.Panel, "Table",
          $$("TreeExpanderButton",
            {
              cursor: "pointer",
              "ButtonBorder.strokeWidth": 0,
              "ButtonBorder.fill": "white",
              row: 0,
              column: 1,
            },
            $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgremove } ),
          ),
          $$(go.Panel, "Auto",
            {
              name: 'newBtn',
              portId: "from",
              fromLinkable: true,
              cursor: "pointer",
              click: newNodeModal,
              row: 0,
              column: 2,
              margin: new go.Margin(2, 0, 0, 0),
            },
            $$(go.Panel,
              $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgadd } ),
            ),
          ),
          $$(go.Panel, "Auto",
            {
              name: 'settingBtn',
              row: 0,
              column: 3,
              alignment: go.Spot.Right,
              margin: new go.Margin(2, 0, 0, 3),
              portId: "from",
              fromLinkable: true,
              cursor: "pointer",
              click: configureBlock,
            },
            $$(go.Panel,
              $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgsetting } )
            ),
          ),
        ),
      ),
      {
        contextMenu:
          $$("ContextMenu",
            $$("ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              "_buttonFillOver": "skyblue"
            },
            $$(go.TextBlock, "Delete Form"),
            { click: deleteForm },
            new go.Binding("visible", checkDeleteButtonsVisibility)
            ),
          $$("ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              "_buttonFillOver": "skyblue"
            },
            $$(go.TextBlock, "Delete Sub Tree"),
            { click: deleteTree },
            new go.Binding("visible", checkDeleteButtonsVisibility)
            ),
            $$("ContextMenuButton",
              {
                "ButtonBorder.fill": "white",
                "_buttonFillOver": "skyblue"
              },
              $$(go.TextBlock, "Copy Tree"),
              { click: copyTree }),
            $$("ContextMenuButton",
            {
              "ButtonBorder.fill": "white",
              "_buttonFillOver": "skyblue"
            },
            $$(go.TextBlock, "Paste Tree",
            new go.Binding("visible", "text", function(s) { if (vm.copiedNode != []) return true; else return false; })),
            { click: pasteTree })
          )
        }
      )
    );
  }

  setLinkTemplate(vm, $$) {
    vm.myDiagram.linkTemplate =
      $$(go.Link, go.Link.Orthogonal,
        { deletable: false,
          margin: new go.Margin(100,100,100,100),
          corner: 10 },
        $$(go.Shape,
          { strokeWidth: 2,
            stroke: '#598BFF',
            height: 20,

            toArrow: "Standard",
            margin: new go.Margin(100,100,100,100),

           }
        ),
        $$(go.TextBlock, go.Link.OrientUpright,
          {
            background: "white",
            visible: true,  // unless the binding sets it to true for a non-empty string
            segmentIndex: -2,
            segmentOrientation: go.Link.None,
            font: "bold 8.5px Verdana, Serif",
            height: 50,
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 4,
            width: 190,
            text: "verticalAlignment: Center", verticalAlignment: go.Spot.Center,
            textAlign: "center",
            segmentFraction: 0.4,
          },

          new go.Binding("text", "text"),
          new go.Binding("visible", "text", function(s) { if (s) return true; else return false; })
        )
      );
  }

  validSplitRules () {
    if ($('#where_to').val() !== 'branch')
      return true;

    if ($('.block-question-value')[0] && $('.block-question-value').val() == '') {
      toastr.error('Please create proper branching logics');
      return false;
    }

    if ($('.block-question-value-nested')[0] && $('.block-question-value-nested').val() == '') {
      toastr.error('Please create proper branching logics');
      return false;
    }

    return true;
  }

  refreshNode (ev) {
    let vm = this;
    let blockId = ev.detail.blockId;
    $.ajax({
      url: `/admin/master_workflows/${vm.idTarget.value}/blocks/${blockId}/`,
      method: 'GET',
      success: (result) => {
        var block = JSON.parse(result.block);
        if (block) {
          var diagram = vm.myDiagram;
          var node = diagram.model.findNodeDataForKey(block.id);
          var actionsArray = block.custom_attributes.actions;
          var nodeActions = [];
          if (actionsArray.length > 0) {
            actionsArray.forEach(function(action) {
              nodeActions.push({
                text: action.name,
                fill: 'blue'
              });
            });
          } else {
            nodeActions =  [
              { text: "No actions", fill: "blue" },
            ]
          }
          diagram.startTransaction("Add State");
          diagram.model.setDataProperty(node, 'actions', nodeActions)
          diagram.commitTransaction("Add State");
          diagram.model = new go.GraphLinksModel(diagram.model.nodeDataArray, diagram.model.linkDataArray);
        }
      },
      error: (error) => {
      },
    });
  }

  formEvent () {
    var el = this.formTarget;
    var ev = document.createEvent('Event');
    ev.initEvent('change', true, false);
    el.dispatchEvent(ev);
  }
}