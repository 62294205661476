import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []
  connect() {

  }

  selectAll (e) {
    e.preventDefault();
    $(this.element.querySelectorAll('input')).prop('checked', true);
  }
}
