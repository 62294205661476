import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin,
} from "helpers";

export default class extends ApplicationController {
  static targets = ["patient", 'fulfilment', 'classification', 'requestedBehalf', 'requestedBehalfDiv', 'contentArea', 'labPackForm', 'labScriptBody'
                  ];

  connect() {
    StimulusReflex.register(this);
    this.hideDeleteIcon();
    this.bindAfterRemove();
  }


  labRequestNoteTemplateChanged (e) {
    $('body').addClass('loading');

    var vm = this;
    var target = e.target;
    var patientId = this.patientTarget.value;
    console.log('patientId:', patientId);
    var templateId = target.value;
    console.log('templateIdfor Letter', templateId);
    var url = `/lab_request_note_templates/extract_template`;
    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
          patient_id: patientId,
          id: templateId
        },
        success: function (result) {
          var body = JSON.parse(result.result);
          body = body.replace(/<br>/g, '\n').replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ');
          vm.labScriptBodyTarget.value = body;
          toastr.success('Template Imported.');
          $('body').removeClass('loading');

        },
        error: function(result) {
          toastr.error('Something went wrong.');
          $('body').removeClass('loading');
        }
      }
    );
  }

  hideDeleteIcon(){
    if(parseInt($("#lab_request_nested_count").val()) == 1)
      $('.lr-delete').addClass('d-none');
  }

  patientChanged() {
    var patientId = this.patientTarget.value;
    var vm = this;
    var nodes = vm.element.querySelectorAll('.lr-nest');
    var node = nodes[nodes.length - 1];
    nodes.forEach(function (node) {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'lab-request');
      destCtrl.fetchAndSetRequests(patientId);
      destCtrl.setPatientValue(patientId);
    })
    this.stimulate('LabRequest#render_patient_header', patientId);
  }

  classificationChanged (e) {
    var vm = this;
    var x = $('.nested-fields').length;
    var fiedVal = e.currentTarget.value;


    if(fiedVal == 'added_by_lab') {
      this.toggleRequestedBehalfField(fiedVal);
      this.togglePreviousRequestField(fiedVal);
    }
    else if(fiedVal == 'incident') {
      this.toggleRequestedBehalfField(fiedVal);
      this.togglePreviousRequestField(fiedVal);
    }
    else if(fiedVal == 'new_request') {
      this.toggleRequestedBehalfField(fiedVal);
      this.togglePreviousRequestField(fiedVal);
    }
  }

  togglePreviousRequestField(value) {
    var vm = this
    var nodes = vm.element.querySelectorAll('.lr-nest');
    var node = nodes[nodes.length - 1];

    nodes.forEach(function (node) {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'lab-request');
      if(value == 'incident')
        destCtrl.showPreviousRequestsField();
      else
        destCtrl.removePreviousRequestsField();
    })
  }

  toggleRequestedBehalfField(value) {
    if(value == 'added_by_lab') {
      $(this.requestedBehalfDivTarget).removeClass('d-none');
      $(this.requestedBehalfTarget).attr('disabled', false);
    }
    else {
      $(this.requestedBehalfDivTarget).addClass('d-none');
      $(this.requestedBehalfTarget).attr('disabled', true);
    }
  }

  addLabRequestedNested() {
    var count = parseInt($("#lab_request_nested_count").val()) + 1;
    $("#lab_request_nested_count").val(count);
    if(count > 1) {
      $('.lr-delete').removeClass('d-none');
    }
  }

  fulfilmentChanged () {
    var vm = this
    var nodes = vm.element.querySelectorAll('.lr-nest');
    var node = nodes[nodes.length - 1];
    nodes.forEach(function (node) {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'lab-request');
      destCtrl.setStatuses(vm.fulfilmentTarget.value);
    })
  }

  submitComment(e) {
    e.preventDefault();

    var params = {
      comment: {
        commentable_type: $('#commentable_type').val(),
        commentable_id: $('#commentable_id').val(),
        content: this.contentAreaTarget.value,
      }
    }

    this.stimulate(`Comments#submit`, params);
  }

  submitSuccess (element, reflex, noop, reflexId) {
    toastr.options.timeOut = 90;
    toastr.success('Comment created..');
    this.contentAreaTarget.value  = '';
  }

  submitError (element, reflex, noop, reflexId) {
    toastr.error('Something went wrong..');
  }

  checkFormValidity(e) {
    e.preventDefault();

    var form = this.labPackFormTarget;

    if(!form.reportValidity()) {
      $('.details-nav-btn').click();
      form.reportValidity();

      return false;
    }
    else {
      $('.lr-pack-submit').click();
    }
  }

  bindAfterRemove(){
    var vm = this
    $('#lab-request-nested-details').on('cocoon:after-remove', function(event, added_option) {
      var nodes = this.parentElement.querySelectorAll('.lr-nest');
      nodes.forEach(function (node, index) {
        var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'lab-request');
        destCtrl.setRequestNumber(index + 1);
      })
    });
  }

  clickNextTab() {
    var arch = false
    var restorationValues = this.labPackFormTarget.querySelectorAll('.restoration-type');
    restorationValues.forEach(function(restoration){
      if($(restoration).val() == 'partial' || $(restoration).val() == 'single')
        arch = true;
        return false;
    })

    if(arch)
      $('.arch-nav-btn').click();
    else
      this.clickAttachmentTab();
  }

  clickAttachmentTab() {
    $('.attachment-nav-btn').click();
  }
}
