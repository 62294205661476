import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  serializeFormData
} from "helpers";
export default class extends ApplicationController {
  static targets = ['form', 'url', 'priorityDateDiv'];

  connect() {
    StimulusReflex.register(this)
  }

  toggleCompleteSuccess (element, reflex, noop, reflexId) {
    var rowId = `#row-${element.dataset.id}`
    $(rowId).remove()
    toastr.success('Task updated.');
    var element = $('.refresh-profile')[0];
    if (element) {
      element.click();
    }
  }

  fileChanged () {
    var vm = this;
    var form = this.formTarget;
    var serial = serializeFormData(form)
    serial['only_attachments'] = true;
    $.ajax({
      url: vm.formTarget.dataset['url'],
      method: 'PATCH',
      data: serial,
      success: () => {
      },
      error: () => {
      },
    });
  }

  priorityToggle (e) {
    var value = e.target.checked;
    console.log(value);
    if (value) {
      $(this.priorityDateDivTarget).removeClass('d-none')
    } else {
      $(this.priorityDateDivTarget).addClass('d-none')
    }
  }

  documentTabChanged (e) {
    var el = e.target;
    $(el).siblings('.nav-item').removeClass('show')
    $(el).siblings('.nav-item').removeClass('active')
    $($('#nav-tabContent')[0].querySelectorAll('.tab-pane')).removeClass('show active')

    $(el).addClass('active')
    $(el).addClass('show')
    var id = el.getAttribute("href")
    $(id).addClass('active')
    $(id).addClass('show')
  }

}
