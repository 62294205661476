import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'


export default class extends ApplicationController {
  static targets = ['status', 'workflowSelect', 'mainTab', 'rescheduleTab', 'showTab', 'commentForm', 'startTime', 'calendar']

  connect() {
    StimulusReflex.register(this)
  }

  startTimeChanged () {
    var vm = this;
    var params = {
      'appointment': {
        'start_date': (vm.startTimeTarget.value),
        'calendar_id': (vm.calendarTarget.value)
      }
    }
    this.stimulate(`Appointment#validate`, params);
  }

  statusChanged(e) {
    var status = e.target.value;
    $('.extra-step').addClass('d-none');
    $('.no-way-btn').addClass('d-none');
    if (status == 'show' || status == 'confirmed' || status == 'booked') {
      $('.extra-step').removeClass('d-none');
      $('.reschedule-step').addClass('d-none');
    } else if (status == 'rescheduled') {
      $('.reschedule-step').removeClass('d-none');
    } else {
      $('.no-way-btn').removeClass('d-none');
    }
  }

  nextClicked (e) {
    e.preventDefault();
    var paritalForm = $('.tab-pane.show')[0];
    var allowed = this.validateInformation(paritalForm, e);
    if (!allowed) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    var next = `#${e.target.dataset['nextStep']}`
    console.log(next);
    $(next).click();
  }

  validateInformation (paritalForm, e) {
    var inputs = paritalForm.querySelectorAll("form input, form select");

    // Set a flag to indicate whether the form is valid
    var isValid = true;

    // Loop through all the input elements
    for (var i = 0; i < inputs.length; i++) {
      // If the input is required and it's empty, set the flag to false and show an error message
      if (inputs[i].required && inputs[i].value === "") {
        isValid = false;
        inputs[i].parentNode.classList.add("has-error");
        // inputs[i].nextElementSibling.innerHTML = "This field is required";
      } else {
        // Otherwise, remove the error message and error class
        inputs[i].parentNode.classList.remove("has-error");
        // inputs[i].nextElementSibling.innerHTML = "";
      }
    }

    // If the form is valid, submit it. Otherwise, prevent the default submission behavior
    if (isValid) {
      return true;
    } else {
      toastr.error('Please fill all fields.');
      e.preventDefault();
      return false;
    }
  }
}
