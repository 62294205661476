import { Controller } from "stimulus"
import {
  isAdmin
} from "helpers";
export default class extends Controller {
  static targets = ['product'];
  static values = {
    productTemplateId: String
  }
  connect() {
    var vm = this;
    console.log('this.productTemplateIdValue', this.productTemplateIdValue)
    this.setFirstProduct();

  }
  setFirstProduct(){
    var vm = this;
    var url = `/product_templates/${this.productTemplateIdValue}/products`;
    if (isAdmin()) { url = '/admin' + url; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
        },
        success: function (result) {
          var products = JSON.parse(result.products)
          if (products.length == 1) {
            var product = products[0];
            var newOption = new Option(product.name, product.id, false, true);
            $(vm.productTarget).append(newOption)
            $(vm.productTarget).trigger('change')
          }
        }
      }
    );

  }
}
