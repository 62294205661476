import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import { param } from 'jquery'

export default class extends ApplicationController {
  static targets = [ "output" ]

  connect() {
    console.log('Admin dashboard connected')
    StimulusReflex.register(this)
  }

  tileClicked (e) {
    var el = e.target;
    if (!$(el).hasClass('tile-outer'))
      el = $(el).closest('.tile-outer')[0];

    var params = {}
    params['ids'] = JSON.parse(el.dataset.ids);
    params['tile_type'] = el.dataset.tileType;
    params['query_string'] = el.dataset.queryString;
    params['dashboard_type'] = el.dataset.dashboardType;

    var heading = 'Assigned Forms';

    if (params['tile_type'] == 'clinical_notes') {
      heading = 'Clinical Notes'
    } else if (params['tile_type'] == 'appointments') {
      heading = 'Appointments'
      $("#chartsModalBody").html('');
      $("#chartsModal").modal('show');
      if (el.dataset['dashboardType'] == 'yesterday') {
        params['go_to_date'] = new Date(Date.now() - 86400000);
      } else {
        params['go_to_date'] = new Date();
      }
    }

    $('#chartModalHeading').html(heading);

    $.ajax({
      url: 'admin/dashboard/requests_table',
      method: 'GET',
      dataType: "script",
      data: params,
      success: (result) => {
        $("#chartsModal").modal('show');
        return;
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }

}
