import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['preference', 'scheduler', 'form', 'taskForm', 'staffSelect', 'groupSelect', 'assignemntDiv', 'expirationDiv'];

  connect() {
    var schedulerDiv = $('.scheduling-condition')[0];

    if (schedulerDiv) {
      var val = schedulerDiv.querySelector('input[checked]').value
      this.toggleSchedulerVisibility(val);
    }

    if (this.hasAssignemntDivTarget) {
      var val = this.assignemntDivTarget.querySelector('input[checked]').value
      this.toggleAssignmentVisibility(val);
    }
  }

  expirationChanged (e) {
    var val = e.target.value;
    if (val == 'true') {
      $(this.expirationDivTarget).removeClass('d-none');
    }
    else {
      $(this.expirationDivTarget).addClass('d-none');
    }

  }

  scheduleOptionChanged (event) {
    this.toggleSchedulerVisibility(event.target.value);
  }

  assignmentOptionChanged (event) {
    this.toggleAssignmentVisibility(event.target.value);
  }

  toggleAssignmentVisibility (val) {
    $(this.staffSelectTarget).removeClass('d-none');
    $(this.groupSelectTarget).removeClass('d-none');

    $(this.staffSelectTarget.querySelectorAll('select')).prop('required', true);
    $(this.groupSelectTarget.querySelectorAll('select')).prop('required', true);

    if (val == 'staff') {
      $(this.groupSelectTarget).addClass('d-none');
      $(this.groupSelectTarget.querySelectorAll('select')).prop('required', false);
    } else if (val == 'role') {
      $(this.staffSelectTarget).addClass('d-none');
      $(this.staffSelectTarget.querySelectorAll('select')).prop('required', false);
    } else {
    }
  }

  toggleSchedulerVisibility (val) {
    if (val == 'true') {
      this.schedulerTarget.classList.remove('d-none');
    } else {
      this.schedulerTarget.classList.add('d-none');
    }
  }

  deleteTask (e) {
    if (confirm("Are you sure you want delete this task?")) {
      var url = e.target.href;

      $.ajax({
        url: url,
        method: 'DELETE',
        dataType: "json",
        success: (result) => {
          if (result.status == 'ok') {
            if (result.revision) {
              window.location.replace(window.location.origin + `/admin/master_workflows/${result.master_workflow_id}/edit`);
            } else {
              $("#configureBlockModal").modal('hide');
              vm.dispatchNodeEvent(blockId);
            }
            toastr.success('Task Deleted');
          }
          return;
        },
        error: (error) => {
          toastr.error('Something went wrong');
          return;
        },
      });
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  }
}
