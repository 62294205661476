import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = []
  static values = {
    patientId: String
  };

  connect() {
    StimulusReflex.register(this)
    this.countPatientInList();
  }

  tabClicked(e) {
    var newurl = '#' + e.target.href.substring(e.target.href.indexOf('#')+1);
    window.history.pushState('Title', 'Title', newurl);
    var params = {
    	patient_id: e.target.dataset['patientId'],
    	tab_name: e.target.dataset['tabName'],
    }
    this.stimulate(`Event#patient_card_tabs`, params);
  }

  countPatientInList () {
    var params = {};
    params['patient_id'] = this.patientIdValue;

    this.stimulate("SmartFilter#calculate_patient_smart_list_count", params);
  }

  toggleGantt (e) {
    var ganttElement = $('.gant-screen');
    if (ganttElement.hasClass('d-none')) {
      $('.close-gantt').removeClass('d-none');
      $('.open-gantt').addClass('d-none');
      $('body').addClass('gant-switch')
    } else {
      $('.close-gantt').addClass('d-none');
      $('.open-gantt').removeClass('d-none');
      ganttElement.addClass('d-none');
      $('body').removeClass('gant-switch')
      e.preventDefault();
      e.stopPropagation();
    }
  }
}
