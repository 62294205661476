import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = ['form']
  connect() {
  	StimulusReflex.register(this)
    console.log('connected with stimulus')
  }

  openPasswordResetModal(e) {
  	var userId = e.target.dataset['id']
  	this.stimulate('StaffUser#reset_password', userId)
  }
  
  resetPasswordSuccess(element, reflex, noop, reflexId) {
  	$("#resetPasswordModal").modal("show");
  }

  passwordMatching(e) {
  	if(!this.formTarget.reportValidity()){
      return false;
    }

  	if ($('.password').val() == $('.password-confirmation').val()) {
    	$('#password-message').html('Matching').css('color', 'green');
    	$('#staff-user-form-submit').click();
  	}
  	else 
    $('#password-message').html('Not Matching').css('color', 'red');
  }
}
