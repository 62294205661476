import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fieldTypeDropdown', 'optionsDiv', 'staticTextDiv', 'tableDataRows', 'tableDataColumns', 'tableNamesDiv', 'productsDiv', 'tableOptionsDiv', 'editableDiv', 'label', 'value']

  connect() {
    if ($('.field-options-tags')[0]) {
      this.initSelect('.field-options-tags');
      this.initSelect('.sub-field-options-name');
      this.initSelect('.sub-field-rows');
      this.initSelect('.sub-field-options-values');
      this.initSelect('.sub-field-options-cols');
    }
    if ($('.fixed-values')[0]) {
      this.initFixedSelect('.fixed-values');
    }

    if (this.hasTableNamesDivTarget) {
      var val = this.fieldTypeDropdownTarget.value;
      this.determineTabularFieldVisibility(val);
    }
  }

  initFixedSelect(identifier) {
    var dropdownParent = $('.modal-open');

    $(identifier).select2({
      dropdownParent: dropdownParent,
      tags: false,
      tokenSeparators: [",", "\t", "\n"],
      closeOnSelect: false,
      placeholder: "Add your options here",
      selectOnClose: true,
    });
    $(identifier).on('paste', function(e) {
      //get the raw pasted text
      var data = e.originalEvent.clipboardData.getData('Text');
      //replace newlines by tabs and add to conent
      $(this).val($(this).val() + '\t' + data.replace(/\n/g, '\t') + '\t');
      //prevent normal paste behaviour
      return false;
    });

    if (identifier == '.sub-field-options-values' || identifier == '.sub-field-options-cols')
    {
      $('.sub-field-options-values option').attr('selected', true).parent().trigger('change');
      $('.sub-field-options-cols option').attr('selected', true).parent().trigger('change');
      $('.sub-field-options-name option').attr('selected', true).parent().trigger('change');
    }

    if (identifier == '.sub-field-rows')
    {
      $('.sub-field-rows option').attr('selected', true).parent().trigger('change');
    }
  }

  initSelect(identifier) {
    var dropdownParent = $('.modal-open');

    $(identifier).select2({
      dropdownParent: dropdownParent,
      tags: true,
      tokenSeparators: [",", "\t", "\n"],
      closeOnSelect: false,
      placeholder: "Add your options here",
      selectOnClose: true,
    });
    $(identifier).on('paste', function(e) {
      //get the raw pasted text
      var data = e.originalEvent.clipboardData.getData('Text');
      //replace newlines by tabs and add to conent
      $(this).val($(this).val() + '\t' + data.replace(/\n/g, '\t') + '\t');
      //prevent normal paste behaviour
      return false;
  });

    if (identifier == '.sub-field-options-values')
    {
      $('.sub-field-options-values option').attr('selected', true).parent().trigger('change');
      $('.sub-field-options-name option').attr('selected', true).parent().trigger('change');
      $('.sub-field-options-cols option').attr('selected', true).parent().trigger('change');
    }

    if (identifier == '.sub-field-rows')
    {
      $('.sub-field-rows option').attr('selected', true).parent().trigger('change');
    }
  }

  typeChanged () {
    var val = this.fieldTypeDropdownTarget.value;
    this.productsDivTarget.classList.add('hide');
    $(".field-options-tags").select2("val", "All");
    this.determineTabularDataFieldVisibility(val);
    if (val == 'text') {
      $('.patient_field_2_select').attr('name', '');
      $('.patient_field_3_select').attr('name', '');
      $('.patient_field_4_select').attr('name', '');
      $('.patient_field_5_select').attr('name', '');
      $('.patient_field_1_select').attr('name', 'field[default_field_value]');
      $('.patient_field_1').removeClass('hide')
      $('.patient_field_2').addClass('hide')
      $('.patient_field_3').addClass('hide')
      $('.patient_field_4').addClass('hide')
      $('.patient_field_5').addClass('hide')
    }else if (val == 'number'){
      $('.patient_field_2_select').attr('name', '');
      $('.patient_field_3_select').attr('name', 'field[default_field_value]');
      $('.patient_field_1_select').attr('name', '');
      $('.patient_field_4_select').attr('name', '');
      $('.patient_field_5_select').attr('name', '');
      $('.patient_field_1').addClass('hide')
      $('.patient_field_2').addClass('hide')
      $('.patient_field_3').removeClass('hide')
      $('.patient_field_4').addClass('hide')
      $('.patient_field_5').addClass('hide')
    }else if (val == 'date'){
      $('.patient_field_2_select').attr('name', 'field[default_field_value]');
      $('.patient_field_3_select').attr('name', '');
      $('.patient_field_1_select').attr('name', '');
      $('.patient_field_4_select').attr('name', '');
      $('.patient_field_5_select').attr('name', '');
      $('.patient_field_1').addClass('hide')
      $('.patient_field_2').removeClass('hide')
      $('.patient_field_3').addClass('hide')
      $('.patient_field_4').addClass('hide')
      $('.patient_field_5').addClass('hide')
    }else if (val == 'address'){
        $('.patient_field_4_select').attr('name', 'field[default_field_value]');
        $('.patient_field_3_select').attr('name', '');
        $('.patient_field_2_select').attr('name', '');
        $('.patient_field_1_select').attr('name', '');
        $('.patient_field_5_select').attr('name', '');
        $('.patient_field_1').addClass('hide')
        $('.patient_field_2').addClass('hide')
        $('.patient_field_3').addClass('hide')
        $('.patient_field_4').removeClass('hide')
        $('.patient_field_5').addClass('hide')
    }else if (val == 'phone'){
      $('.patient_field_4_select').attr('name', '');
      $('.patient_field_3_select').attr('name', '');
      $('.patient_field_2_select').attr('name', '');
      $('.patient_field_1_select').attr('name', '');
      $('.patient_field_5_select').attr('name', 'field[default_field_value]');
      $('.patient_field_1').addClass('hide')
      $('.patient_field_2').addClass('hide')
      $('.patient_field_3').addClass('hide')
      $('.patient_field_4').addClass('hide')
      $('.patient_field_5').removeClass('hide')
    } else if (val == 'inventory_checklist') {
      this.productsDivTarget.classList.remove('hide');
    }else{
      $('.patient_field_2_select').attr('name', '');
      $('.patient_field_3_select').attr('name', '');
      $('.patient_field_1_select').attr('name', '');
      $('.patient_field_4_select').attr('name', '');
      $('.patient_field_5_select').attr('name', '');
      $('.patient_field_1').addClass('hide')
      $('.patient_field_2').addClass('hide')
      $('.patient_field_3').addClass('hide')
      $('.patient_field_4').addClass('hide')
      $('.patient_field_5').addClass('hide')
    }

    if (val == 'radio' || val == 'checkbox' || val == 'single_select' || val == 'multi_select') {
      $('.add-flag-div').removeClass('hide')
      $("#option-input-field").prop('required',true);
      this.optionsDivTarget.classList.remove('hide');
      if ($('.option-field').length <= 1) {
        $('.remove-option-btn').hide();
      }
      else{
        $('.remove-option-btn').show();
      };
      if ($('.option-form-field').length <= 1) {
        $('.remove-option-btn-form-field').hide();
      }
      else{
        $('.remove-option-btn-form-field').show();
      };
    } else {
      $('.add-flag-div').addClass('hide')
      // $('.nested-fields').remove()
      $("#option-input-field").prop('required',false);
      this.optionsDivTarget.classList.add('hide');
    }
    if (val == 'static_text') {
      this.staticTextDivTarget.classList.remove('hide');
    } else {
      this.staticTextDivTarget.classList.add('hide');
    }
    this.determineTabularFieldVisibility(val);
  }

  determineTabularFieldVisibility (val) {
    if (val == 'tabular') {
      this.tableNamesDivTarget.classList.remove('hide');
      this.tableOptionsDivTarget.classList.remove('hide');
      $('.add-flag-div').removeClass('hide')
    } else {
      // $('.add-flag-div').addClass('hide')
      // $('.nested-fields').remove()
      this.tableNamesDivTarget.classList.add('hide');
      this.tableOptionsDivTarget.classList.add('hide');
    }
  }

  determineTabularDataFieldVisibility (val) {
    if (val == 'tabular_data') {
      this.tableDataRowsTarget.classList.remove('hide');
      this.tableDataColumnsTarget.classList.remove('hide');
    } else {
      this.tableDataRowsTarget.classList.add('hide');
      this.tableDataColumnsTarget.classList.add('hide');
    }
  }

  labelClicked () {
    this.editableDivTarget.classList.remove('hide');
    this.valueTarget.focus();
    this.valueTarget.value = this.labelTarget.innerHTML;
  }

  valueFocusOut () {
    this.editableDivTarget.classList.add('hide');
  }

  updateAllLabels (id, value) {
    var allLabels = $('.label-field-'+id);
    allLabels.each(function(index, element){
      element.innerHTML = value;
    });
  }

  updateField () {
    var id = this.valueTarget.dataset['fieldId'];
    var workflow_id = $('#workflow_id').val();
    var value = this.valueTarget.value;
    if (value.length > 0) {
      $.ajax({
        url:  '/admin/fields/' + id + '/form_field_update?workflow_id='+ workflow_id,
        type: 'PUT',
        dataType: 'js',
        data: {
          field: {
            name: value,
            allow_blank_options: true
          }
        },
        complete: () => {
          this.labelTarget.innerHTML = value;
          this.editableDivTarget.classList.add('hide');
          this.updateAllLabels(id, value);
        }
      });
    }
    event.preventDefault();
  }
}
