import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin
} from "helpers";
export default class extends ApplicationController {
  static targets = []
  static values = {
    actionId: String
  }

  connect() {
    this.timer;
    var vm = this;
    if (isAdmin()) {
      var uplaodUrl = '/admin/files/bulk_upload/'
    } else {
      var uplaodUrl = '/files/bulk_upload/'

    }
    CKEDITOR.plugins.addExternal("autotag", "/assets/ckeditor/plugins/autotag/", "plugin.js");
    CKEDITOR.plugins.addExternal("autotag", "/assets/ckeditor/plugins/autotag/", "plugin.js");
    CKEDITOR.plugins.addExternal("autocomplete", "/assets/ckeditor/plugins/autocomplete/", "plugin.js");
    CKEDITOR.plugins.addExternal("textwatcher", "/assets/ckeditor/plugins/textwatcher/", "plugin.js");
    CKEDITOR.plugins.addExternal("textmatch", "/assets/ckeditor/plugins/textmatch/", "plugin.js");
    CKEDITOR.plugins.addExternal("placeholder", "/assets/ckeditor/plugins/placeholder/", "plugin.js");
    CKEDITOR.plugins.addExternal("oembed", "/assets/ckeditor/plugins/oembed/", "plugin.js");
    console.log('connected..312')
    CKEDITOR.addCss('.cke_editable img { max-width: 100% !important; height: auto !important; }');

    StimulusReflex.register(this)
    var editor1 = CKEDITOR.replace( 'ckme', {
      uiColor: '#0376E3',
      extraPlugins: 'oembed,autotag,autocomplete,textwatcher,textmatch,placeholder',
      autoEmbed_widget: 'customEmbed',
      filebrowserBrowseUrl: '/ckeditor/attachment_files',
      filebrowserImageBrowseLinkUrl: '/ckeditor/pictures',
      filebrowserImageBrowseUrl: '/ckeditor/pictures',
      filebrowserImageUploadUrl: uplaodUrl,
      filebrowserUploadUrl: uplaodUrl,
    }
    );
    CKEDITOR.addCss('.cke_editable img { max-width: 100% !important; height: auto !important; }');
    vm.autosave(editor1.getData());

    editor1.on('change', function () {
      clearTimeout(vm.timer);
      vm.timer = setTimeout(() => vm.autosave(editor1.getData()), 1000);
    });
  }

  autosave (content) {
    var vm = this;

    this.stimulate(`Document#autosave`, {
      id: vm.actionIdValue,
      content: content
    });
  }

  autosaveSuccess (element, reflex, noop, reflexId) {
    toastr.options.timeOut = 90;
    toastr.success('Document Autosaved..');
  }
}
