import { Controller } from "stimulus";
import { Device } from 'twilio-client';
import {
  isAdmin
} from "helpers";

export default class extends Controller {
  static targets = ["patientId", "phoneNumber", "body", "scheduledAt"];
  static values = {
    patients: Array
  }

  connect() {
    console.log('me here..')
  }

  toggleSchedule(event) {
    const shouldSchedule = event.target.value === 'yes';

    if (shouldSchedule) {
      this.scheduledAtTarget.classList.remove('d-none');
      // Initialize or reinitialize Flatpickr
      if (!this.flatpickrInstance) {
        // this.flatpickrInstance = flatpickr(this.scheduledAtTarget.querySelector('[data-target="flatpickr.field"]'), {/* options */});
      }
    } else {
      this.scheduledAtTarget.classList.add('d-none');
      if (this.flatpickrInstance) {
        // this.flatpickrInstance.clear();
      }
    }
  }

  patientChanged (e) {
    var patientId = e.target.value;
    var patient = this.patientsValue.find(p => p.id == patientId);
    if (patient) {
      this.phoneNumberTarget.value = patient.phone_number;
    }
  }

  letterMessageTemplateChanged (e) {
    $('body').addClass('loading');
    var vm = this;
    var target = e.target;
    var patientId = this.patientIdTarget.value;

    if (!patientId){
      toastr.error('Please select a patient');
      $('body').removeClass('loading');
      e.target.value = '';
      return;
    }

    var templateId = target.value;
    var url = `/patients/${patientId}/patient_emails/extract_message_template`;
    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
          patient_id: patientId,
          letter_message_template_id: templateId
        },
        success: function (result) {
          var body = JSON.parse(result.result);
          body = body.replace(/<br>/g, '\n').replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ');
          vm.bodyTarget.value = body;
          $('body').removeClass('loading');
          toastr.success('Message Template Imported.');
        },
        error: function(result) {
          $('body').removeClass('loading');
          toastr.error('Something went wrong.');
        }
      }
    );
  }

  fetchRecordings(e) {
    e.preventDefault();
    var id = e.target.dataset['id'];
    console.log('id')

    var url_ = `/call_logs/${id}/fetch_recordings`

    if (isAdmin()) { url_ = '/admin' + url_; }
    toastr.info('Fetching recordings, this might take a while...');
    $('body').addClass('loading')
    $.ajax({
      url: url_,
      method: 'GET',
      dataType: "script",
      success: (result) => {

      },
      error: (errors) => {
        $('body').removeClass('loading')
        console.log(errors)
        toastr.error(errors.responseJSON.errors);
        return;
      },
    });
  }
}
