// app/javascript/controllers/zapier_webhook_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["activityType", "triggerActionForm", "workflow", "triggerActionPatient", "triggerActionAppointment"]

  connect() {
    console.log('ZapierWebhookTemplateController connected')
    this.toggleFields()
  }

  toggleFields() {
    const activityType = this.activityTypeTarget.value
    const isFormsActivity = activityType === 'forms_activity'
    const isPatientsActivity = activityType === 'patients_activity'
    const isAppointmentsActivity = activityType === 'appointments_activity'

    this.toggleDisplayAndRequirement(this.triggerActionFormTarget, isFormsActivity)
    this.toggleDisplayAndRequirement(this.workflowTarget, isFormsActivity)
    this.toggleDisplayAndRequirement(this.triggerActionPatientTarget, isPatientsActivity)
    this.toggleDisplayAndRequirement(this.triggerActionAppointmentTarget, isAppointmentsActivity)
  }

  toggleDisplayAndRequirement(element, shouldBeVisible) {
    if (shouldBeVisible) {
      element.classList.remove('d-none')
      element.querySelector('select').required = true
    } else {
      element.classList.add('d-none')
      element.querySelector('select').required = false
    }
  }
}
