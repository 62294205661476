import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin
} from "helpers";

export default class extends ApplicationController {
  static targets = ['location', 'products', 'inventoryCategory', 'templateCategory']

  connect() {
    StimulusReflex.register(this)
    console.log('connected..')
  }

  intentChanged (e) {
    var intent = e.target.value;
    console.log(intent);
    $('.pref-selection').addClass('d-none');
    if (intent == 'bundle') {
      $(this.inventoryCategoryTarget).removeClass('d-none');
    } else if (intent == 'category') {
      $(this.templateCategoryTarget).removeClass('d-none');
    }
  }

  preferenceChanged (e) {
    var vm = this;
    var id = e.target.value;
    var preference = e.target.dataset.preference;
    var url_ = `/inventory_count_requests/apply_preference/`;

    if (isAdmin()) { url_ = '/admin' + url_; }

    $.ajax({
      url: url_,
      method: 'GET',
      data: {
        id: id,
        preference: preference
      },
      dataType: "json",
      success: (result) => {
        var products = result.products;
        var ids = [];
        products.forEach(function (v) {
          ids.push(v.id)
        });
        $(vm.productsTarget).val(ids).trigger('change');
        return;
      },
      error: (errors) => {
        console.log(errors)
        toastr.error(errors.responseJSON.errors);
        return;
      },
    });
  }
}
