import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin,
} from "helpers";

export default class extends ApplicationController {
  static targets = ['variant', 'attributes']

  connect() {
    console.log('connected..');
    if ($(this.variantTarget).val() == '') {
      $(this.attributesTarget)[0].disabled = true;
    }
  }

  variantChanged(e) {
    var vm = this;
    var val = e.target.value;
    var params = {
      id: val
    }

    var url = `/variants/create_and_fetch_attributes`;
    if (isAdmin()) {
      url = '/admin/' + url;
    }

    $(vm.attributesTarget)[0].disabled = true;

    $.ajax({
      url: url,
      method: 'GET',
      data: params,
      dataType: "script",
      success: (result) => {
        var variant = JSON.parse(JSON.parse(result).results);
        var varId = variant.id.toString()
        if (vm.variantTarget != varId) {
          $(vm.variantTarget).find(":selected")[0].value = varId;
        }
        vm.updateAttributes(variant);
        $(vm.attributesTarget)[0].disabled = false;
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }

  updateAttributes(variant) {
    var element = this.attributesTarget;
    var values = variant.variant_attributes;
    $(element).empty()

    values.forEach(function (v) {
      var name = v.name;
      var newOption = new Option(name, v.id, false, false);
      element.append(newOption);
    });
    $(element).val(null)
  }

  attributesChanged (e) {
    var vm = this;
    var val = $(vm.attributesTarget).val().at(-1);
    var params = {
      attribute_id: val
    }
    var variantId = $(this.variantTarget).val();

    var url = `/variants/${variantId}/create_attribute`;
    if (isAdmin()) {
      url = '/admin/' + url;
    }

    $(this.attributesTarget)[0].disabled = true;

    $.ajax({
      url: url,
      method: 'GET',
      data: params,
      dataType: "script",
      success: (result) => {
        var attribute = JSON.parse(JSON.parse(result).results);
        var newRecord = attribute.new_record;
        if (newRecord) {
          var allOptions = (vm.attributesTarget).options;
          var selectedOptions = $(vm.attributesTarget).find(":selected");
          var lastOption = selectedOptions[selectedOptions.length - 1]
          var attributId = attribute.id.toString()

          allOptions.forEach(function (option) {
            if (option.value == attribute.name) {
              $(option).remove();
              var newOption = new Option(attribute.name, attribute.id, true, true);
              $(vm.attributesTarget).append(newOption);
            }
          })
          $(this.attributesTarget).trigger('change')
        }
        $(this.attributesTarget)[0].disabled = false;
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }
}
