import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'blockId', 'checkableStage', 'masterWorkflowId' ]
  connect() {
    let vm = this;
  }

  toggle (e) {
    let vm = this;

    var url = `/admin/master_workflows/${vm.masterWorkflowIdTarget.value}//blocks/${vm.blockIdTarget.value}/`;
    $.ajax({
        url: url,
        method: 'patch',
        data: {
          block: {
            checkable_stage: vm.checkableStageTarget.checked,
          }
        },
        dataType: "js",
        success: (result) => {
          if (result.status == 'ok') {
            if (result.revision) {
              window.location.replace(window.location.origin + `/admin/master_workflows/${result.master_workflow_id}/edit`);
            } else {
              toastr.success('Successfully Updated');
              return;
            }
          }
        },
        error: (error) => {
          toastr.error('Something went wrong');
          return;
        },
    });
  }
}
