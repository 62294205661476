import { Controller } from "stimulus"
import {
  isAdmin,
} from "helpers";


export default class extends Controller {
  static targets = ['deleteFile']

  connect() {}

  delete (e) {
    e.preventDefault();
    var id = $(e.target).attr("id")
    var url = `/files/${id}`;
    if (isAdmin()) { url = '/admin' + url; }

    if (confirm('Are you sure you want to delete this file?')) {
      $.ajax(
        {
          url: url,
          type: "DELETE",
          data: {
            id: id
        },
          success: function () {
            $("#file-" + id).remove()
          }
        });
    };
  }
}
