import { Controller } from "stimulus"
import {
  serializeFormData
} from "helpers";

export default class extends Controller {
  static targets = ['checkInStageBtn', 'checkInStageField', 'workflowSelect', 'assignSelect', 'formSelect',
                    'stageSelect', 'workflowSelectDiv', 'role', 'partneringSource', 'marketingSource', 'generalMarketingDiv',
                    'partneringPracticeDiv', 'continueWorkflowSelectDiv', 'continueWorkflowSelect']
  static values = {
    forms: Array,
  }

  connect() {
    if ($('#smartwizard')[0]) {
      this.initStepWizard();
    }
    if (this.hasAssignSelectTarget) {
      this.assignSelectTarget.checked = true;
    }
  }

  referralToggle (e) {
    var target = e.target.dataset['target'].replace('patient.', '');
    if (target == 'partneringSource') {
      this.partneringPracticeDivTarget.classList.remove('d-none');
      this.generalMarketingDivTarget.classList.add('d-none');
      this.marketingSourceTarget.checked = false;
      this.partneringSourceTarget.checked = true;
    } else {
      this.partneringPracticeDivTarget.classList.add('d-none');
      this.generalMarketingDivTarget.classList.remove('d-none');
      this.partneringSourceTarget.checked = false;
      this.marketingSourceTarget.checked = true;
    }
  }

  initStepWizard () {
    var vm = this;
    if ($("#smartwizard").hasClass('clearfix')) {
      return;
    }

    $("#smartwizard").steps({
      bodyTag: "fieldset",
      titleTemplate: '<span class="number">#index#</span> #title#',
      onStepChanging: function (event, currentIndex, newIndex) {
          if (currentIndex > newIndex) {
              return true;
          }
          var form = $(this);
          if (currentIndex < newIndex) {
              $(".body:eq(" + newIndex + ") label.error", form).remove();
              $(".body:eq(" + newIndex + ") .error", form).removeClass("error");
          }
          form.validate().settings.ignore = ":disabled,:hidden";
          return form.valid();
      },
      onFinishing: function (event, currentIndex) {
          var form = $(this);
          form.validate().settings.ignore = ":disabled";
          return form.valid();
      },
      onFinished: function (event, currentIndex) {
        var finishButton = $('#smartwizard').find('a[href="#finish"]');
        finishButton.addClass("d-none");
        setTimeout(function(){finishButton.removeClass("d-none");;},5000);

          var form = $(this);
          var formData = new FormData(form[0]);

          $.ajax({
            type: 'POST',
            url: form.attr("action"),
            cache: false,
            contentType: false,
            processData: false,
            data: formData,
          });
      }
    }).validate({
        errorPlacement: function (error, element) {
            element.before(error);
        },
        rules: {
            confirm: {
                equalTo: "#password"
            }
        }
    });
  }

  workflowSelectChanged (e) {
    var val = e.target.value;
    if (val) {
      this.fetchWorkflow();
    }
  }

  continueWorkflowSelectChanged (e) {
    var val = e.target.value;
    if (val) {
      this.fetchExistingWorkflow();
    }
  }

  getForms (e) {
    var mwf = e.target.href.split('-')[1];
    var patient = e.target.dataset['patient'];
    var colapseElement = $(`#collapse-${mwf}`);

    var url = '';
    if(window.location.pathname == '/patients') {
      url = `/patients/${patient}/workflow_forms?master_workflow_id=${mwf}`;
    } else {
      url = `/admin/patients/${patient}/workflow_forms?master_workflow_id=${mwf}`;
    }

    if(colapseElement.children().length == 0) {
      $.ajax({
        url: url,
        method: 'GET',
        success: (result) => {
        },
        error: (error) => {
          toastr.error('Something went wrong');
          return;
        },
      });
    }
  }

  formTypeChanged (e) {
    var val = e.target.value;
    var forms = this.formsValue.filter(function( item ) {
      if (item.form_type_id == val){
       return item;
      }
    });
    $('.form-select').empty();
    forms.forEach(function(item) {
      var newOption = new Option(item.title, item.id, false, false);
      $('.form-select').append(newOption).trigger('change');
    });
    $('.form-select').val(null).trigger('change');
  }

  assignmentPreferenceChanged (e) {
    var val = e.target.value;
    this.formSelectTarget.classList.add('d-none');
    this.formSelectTarget.classList.add('d-none');
    this.workflowSelectDivTarget.classList.add('d-none');
    if (this.hasContinueWorkflowSelectDivTarget)
      this.continueWorkflowSelectDivTarget.classList.add('d-none');

    if (val == 'workflow') {
      this.workflowSelectDivTarget.classList.remove('d-none');
    } else if (val == 'form') {
      this.formSelectTarget.classList.remove('d-none');
    } else if (val == 'continue_workflow') {
      this.continueWorkflowSelectDivTarget.classList.remove('d-none');
    } else {
    }
  }

  fetchWorkflow () {
    if (this.roleTarget.value == 'admin_user') {
      var url = `/admin/patients/workflow_stage_select/`;
    } else {
      var url = `/patients/workflow_stage_select/`;
    }
    var workflowId = this.workflowSelectTarget.value;
    $.ajax({
        url: url,
        method: 'get',
        dataType: "json",
        data: {
          master_workflow_id: workflowId
        },
        success: (result) => {
          var e = $('.stage-select');
          var vals = result.blocks;
          var seloption = '';

          if (vals.length > 0) {
            $.each(vals, function(element){
              seloption += '<option selected value="'+vals[element][1]+'">'+vals[element][0]+'</option>';
            });
            seloption += '<option selected value> Please select a stage (Optional)'+'</option>';
            e.empty().append(seloption);
          }
          return;
        },
        error: (error) => {
          toastr.error('Something went wrong', error);
          return;
        },
    });
  }

  fetchExistingWorkflow () {
    if (this.roleTarget.value == 'admin_user') {
      var url = `/admin/patients/workflow_stage_select/`;
    } else {
      var url = `/patients/workflow_stage_select/`;
    }
    var workflowId = this.continueWorkflowSelectTarget.value;
    $('body').addClass('loading');
    $.ajax({
        url: url,
        method: 'get',
        dataType: "json",
        data: {
          patient_master_workflow_id: workflowId
        },
        success: (result) => {
          var e = $('.secondary-workflow-select');
          e.empty()
          var vals = result.blocks;
          var seloption = '';

          if (vals.length > 0) {
            $.each(vals, function(element){
              seloption += '<option selected value="'+vals[element][1]+'">'+vals[element][0]+'</option>';
            });
            seloption += '<option selected value> Please select a stage (Optional)'+'</option>';
            e.empty().append(seloption);
          }
          $('body').removeClass('loading');
          return;
        },
        error: (error) => {
          toastr.error('Something went wrong', error);
          $('body').removeClass('loading');
          return;
        },
    });
  }
}
