import { Controller } from "stimulus";
import { Device } from 'twilio-client';
import {
  extractSubDomain
} from "helpers";

export default class extends Controller {
  static targets = ["callBtn", "hangupBtn", "phoneNumber", "statusMessage", "time"];
  static values = {
    patientId: String,
    callLogId: String
  }
  device = null; // Keep a reference to the Twilio Device
  interval = null;
  startTime = 0;

  connect() {
    this.fetchTokenAndSetupTwilio();
  }

  fetchTokenAndSetupTwilio() {
    $('body').addClass('loading')
    fetch('/twilio/token', {
      method: 'GET',
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
        "Content-Type": "application/json",
      },
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then(data => this.setupTwilioClient(data.token))
    .catch(error => console.error('Error fetching token:', error));
  }

  setupTwilioClient(token) {
    this.device = new Device(token);

    this.device.on('ready', () => {
      console.log('Twilio.Device Ready!');
      this.isReady = true;
      this.makeCall();
      this.statusMessageTarget.textContent = 'Device is ready. Making Call Now..';
      $('body').removeClass('loading');
    });

    this.device.on('error', (error) => {
      toastr.error('Error Connecting to twilio:', error);

      console.error('Twilio.Device Error: ' + error.message);
      this.statusMessageTarget.textContent = 'Error: ' + error.message;
      $('body').removeClass('loading');
    });

    this.device.on('connect', (conn) => {
      console.log('Call in progress.');
      this.statusMessageTarget.textContent = 'Call in progress...';
      this.hangupBtnTarget.style.display = 'inline';
      this.callBtnTarget.style.display = 'none';
      this.start();
      $('body').removeClass('loading');
    });

    this.device.on('disconnect', (conn) => {
      console.log('Call ended.');
      this.statusMessageTarget.textContent = 'Call finished.';
      this.hangupBtnTarget.style.display = 'none';
      // this.callBtnTarget.style.display = 'inline';
      $('body').removeClass('loading');
    });
  }

  makeCall() {
    if (this.isReady) {

      var params = {
        "To": this.phoneNumberTarget.value,
        subdomain: extractSubDomain(),
        patient_id: this.patientIdValue,
        call_log_id: this.callLogIdValue
      }; // Number to call

      console.log('paraams:', params);

      this.device.connect(params);
    } else {
      console.error('Twilio.Device not ready. Make sure Device.setup() has completed.');
    }
  }

  hangUp(e) {
    e.preventDefault();
    this.device.disconnectAll();
    console.log('Hanging up...');
    this.reset();
  }


  start() {
    if (this.interval) return;  // Avoid multiple intervals

    this.startTime = Date.now() - this.startTime;
    this.interval = setInterval(() => {
      const elapsedTime = Date.now() - this.startTime;
      this.timeTarget.textContent = this.formatTime(elapsedTime);
    }, 1000);
  }

  reset() {
    clearInterval(this.interval);
    this.resetTimer();
  }

  resetTimer() {
    this.interval = null;
    this.startTime = 0;
    this.timeTarget.textContent = "00:00:00";
  }

  formatTime(ms) {
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  pad(value) {
    return String(value).padStart(2, '0');
  }
}
