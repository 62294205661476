// app/javascript/controllers/count_down_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["display"];
  static values = {
    seconds: Number
  }

  connect() {
    this.remainingTime = this.secondsValue;
    this.updateDisplay();
    this.startCountdown();
  }

  startCountdown() {
    this.interval = setInterval(() => {
      if (this.remainingTime <= 0) {
        clearInterval(this.interval);
        this.remainingTime = 0;
      } else {
        this.remainingTime--;
      }
      this.updateDisplay();
    }, 1000);
  }

  updateDisplay() {
    const hours = String(Math.floor(this.remainingTime / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((this.remainingTime % 3600) / 60)).padStart(2, '0');
    const seconds = String(this.remainingTime % 60).padStart(2, '0');
    this.displayTarget.innerHTML = `${hours}:${minutes}:${seconds}`;
  }

  disconnect() {
    clearInterval(this.interval);
  }
}

