import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin
} from "helpers";
export default class extends Controller {
  static targets = []

  connect() {
    console.log('MainController connected')
    if (!isAdmin()) {
      this.checkForReminders();
    }
  }

  checkForReminders() {
    var url = '/staff_users/important_reminders';
    $.ajax({
      url: url,
      method: 'GET',
      dataType: "script",
      success: (result) => {
      },
      error: (errors) => {
        console.log(errors)
      },
    });
  }

  refreshableModalClosed(e) {
    var element = $('.refresh-profile')[0];
    if (element) {
      element.click();
    }
  }

  forceCloseModal (e) {
    var reference = e.target.dataset['reference'];
    $(reference).hide();
    $('.modal-backdrop').remove();

    var openModals = $('.modal.show');
    if (reference == '#patient_details_modal' && openModals.length == 1) {
      $('.gant-screen').addClass('d-none');
      if (openModals[0].id == 'patient_details_modal'){
        console.log('going in..')
        $('.patient-profile-modal-body').remove();
        $('.modal-div').html('');
        $('body').removeClass('modal-open');

        $('.close-gantt').addClass('d-none');
        $('.open-gantt').removeClass('d-none');
        $('.gant-screen').addClass('d-none');
        $('body').removeClass('gant-switch')
      }

    }
  }
}
