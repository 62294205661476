import { Controller } from "stimulus"
import ChartJS from "chart.js";
import {
  isAdmin,
} from "helpers";
export default class extends Controller {
  static targets = ['wonDiv']
  static values = {
    patientId: String
  };

  connect() {
  }

  statusChanged (e) {
    var status = e.target.value;
    console.log('status: ' + status);
    this.wonDivTarget.classList.add('d-none');
    if (status == 'won') {
      this.wonDivTarget.classList.remove('d-none');
    }
  }

  patientChanged (e) {
    var vm = this;
    var patientId = e.target.value;

    var url = `/patients/${patientId}/new_follow_up?patient_id=${patientId}`;

    url = isAdmin() ? `/admin/${url}` : url;
    console.log(url)
    $.ajax({
      url: url,
      method: 'get',
      dataType: "script",
      success: (result) => {
          if (result.status == 'ok') {
              vm.reconnect();
          }
      }
    });
  }
}
