import { Controller } from "stimulus"
import {
  isAdmin,
  serializeFormData
} from "helpers";
export default class extends Controller {
  static targets = ['sourceLocation', 'destinationLocation', 'form', 'total']
  static values = {
    operationTemplates: Array,
    state: String,
  }

  connect() {
    if (this.stateValue == 'completed') {
      $(this.formTarget.querySelectorAll('input')).prop('readonly', true);
      $(this.formTarget.querySelectorAll('input')).prop('disabled', true);
      $(this.formTarget.querySelectorAll('select')).prop('disabled', true);
    }

    this.calculateTotal();
  }

  clearAmount(e) {
    $(e.target).closest('.nested-fields').find('input.sub-cost').val("0");
    this.calculateTotal();
  }

  calculateTotal () {
    var cost = [];

    document.getElementsByClassName("transfer-items").forEach(function (row) {
      var itemCost = $((row).querySelector('.sub-cost')).val();
      var demand = $((row).querySelector('.demand-quant')).val();

      if (itemCost && demand){
        itemCost = parseFloat(itemCost) * parseFloat(demand);
        cost.push(itemCost);
      }
    });

    var cost = cost.map(Number).reduce((a, b) => a + b, 0)
    this.totalTarget.innerHTML = `Total: $ ${cost.toFixed(2)}`
  }

  operationTemplateChanged (e) {
    var id = e.target.value;

    var template = this.operationTemplatesValue.find(function(s) {
      return (s.id == id);
    });

    this.sourceLocationTarget.value = template.source_location_id;
    this.destinationLocationTarget.value = template.destination_location_id;
    $(this.sourceLocationTarget).trigger('change');
    $(this.destinationLocationTarget).trigger('change');
  }

  applyBundle (e) {
    var vm = this;
    var inventoryCategoryId = e.target.value;
    var url = `/transfers/new`;
    if (isAdmin()) { url = '/admin' + url; }

    var formData = this.form_data(this.element);

    $.ajax({
      url: url,
      method: 'GET',
      data: formData,
      dataType: "script",
      success: (result) => {
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }

  form_data(form) {
    return serializeFormData(form);
  }
}
