import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['userFormField', 'formFieldSelect', 'patientAttributeSelect', 'radioFalse'];

  connect() {
    if (this.hasUserFormFieldTarget) {
      this.toggleSchedulerVisibility(this.userFormFieldTarget.value);
    }
  }

  scheduleOptionChanged (event) {
    this.toggleSchedulerVisibility(event.target.value);
  }

  toggleSchedulerVisibility (val) {
    if (val == 'true') {
      this.patientAttributeSelectTarget.classList.add('d-none');
      this.formFieldSelectTarget.classList.remove('d-none');
    } else {
      this.patientAttributeSelectTarget.classList.remove('d-none');
      this.formFieldSelectTarget.classList.add('d-none');
      this.radioFalseTarget.checked = 'true';
    }
  }
}
