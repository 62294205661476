import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin
} from "helpers";

export default class extends Controller {
  static targets = ['flatPickr', 'interval', 'calendarId', 'appointmentId', 'startTime']

  connect() {
    StimulusReflex.register(this)
  }

  dateTimeChanged (e) {
    var vm = this;

    var calendarId = this.calendarIdTarget.value;
    if (!calendarId)
      return;

    var url_ = `/calendars/${calendarId}/validate_appointment/`;

    if (isAdmin()) { url_ = '/admin' + url_; }

    var params = {
      start_time: vm.startTimeTarget.value,
      interval: this.intervalTarget.value
    }

    if (this.hasAppointmentIdTarget) {
      params['appointment_id'] = this.appointmentIdTarget.value;
    }

    $.ajax({
      url: url_,
      method: 'GET',
      data: params,
      dataType: "json",
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Accept', 'application/json')
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')

        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        options.data = JSON.stringify(params)
        return true
      },
      success: (result) => {
        return;
      },
      error: (errors) => {
        console.log(errors)
        vm.resetFlatPickr(vm);
        toastr.error(errors.responseJSON.errors);
        return;
      },
    });
  }

  resetFlatPickr (vm) {
    vm.flatPickrTarget.value = '';
  }
}

