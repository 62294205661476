import { Controller } from "stimulus"
import {
  isAdmin
} from "helpers";

export default class extends Controller {
  static targets = ['checkInStageBtn', 'checkInStageField', 'workflowSelect', 'assignSelect', 'formSelect',
                    'stageSelect', 'workflowSelectDiv', 'role', 'partneringSource', 'marketingSource',
                    'generalMarketingDiv', 'partneringPracticeDiv', 'profileTab', 'profileActivityTab', 'profilePanel']

  connect() {
    this.setTabs();
    this.openPatientProfile();
  }

  tabClicked(e) {
    var newUrl = '#' + e.target.href.substring(e.target.href.indexOf('#')+1);
    window.history.pushState({ turbolinks: {} }, "Title", newUrl);
  }

  setTabs () {
    var anchor = window.location.hash.substr(1);
    if (anchor) {
      $(this.profileTabTarget).removeClass('active');
      $(this.profileActivityTabTarget).removeClass('active');

      $(this.profilePanelTarget).removeClass('active');
      var tab = $(`a[href="#${anchor}"]`)[0]

      if (anchor == 'summary' || anchor == 'assigned-forms') {
        $('.summary-tab').addClass('active');
      } else if (anchor == 'external-requests') {
        $(this.profileActivityTabTarget).addClass('active')
      }

      $(tab).addClass('active')

      if (anchor == 'assigned-forms') {
        $('#assignedworkflow').addClass('active');
      } else {
        $(`#${anchor}`).addClass('active');
      }
    }
  }

  editFormTabChanged (e) {
    var hash = e.target.href.substring(e.target.href.indexOf('#')+1);
    $('.profile-tab').addClass('d-none')
    $('#'+hash).removeClass('d-none')
  }

  activityTabChanged (e) {
    var hash = e.target.href.substring(e.target.href.indexOf('#')+1);
    // $('.profile-activity-tab').addClass('d-none')
    $('#'+hash).removeClass('d-none')
    console.log('activitu changed')

  }


  afterReflex(element, reflex, noop, reflexId) {
    toastr.success('Patient updated');
  }

  openPatientProfile() {
    var vm = this
    $(".patient-search-select").on('select2:select select2:unselect', function (e) {
      var openType = e.target.dataset['opentype'] || 'profile';
      var id = e.params.data['id']
      var url;
      if (openType == 'card') {
        if(isAdmin())
          url = `/admin/patients/${id}/profile`
        else
          url = `/patients/profile`

        $.ajax({
          url: url,
          method: 'GET',
          data: {
            patient_id: id,
            id: id
          },
          dataType: "script",
          success: (result) => {
          },
          error: (error) => {
            toastr.error('Something went wrong');
            return;
          },
        });
      } else {
        if(isAdmin())
          url = `/admin/patients/${id}`
        else
          url = `/patients/${id}`

        window.location = url;
      }
    });
  }
}
