
$(document).on('ready turbolinks:load', () => {
  $('.modal-backdrop').remove();
  $('.modal.show').modal();
  $('#toast-container').remove();
  var element = $('.refresh-profile')[0];
  if (element) {
    element.click();
  }
  $("#patient_details_modal").on('hidden.bs.modal', function() {
    $(this).remove();
  });

  bindStickyHeader('sticky--header');
  bind_add_new_fields()
  bind_fields_options()
  bind_accordion_title()
  if ($('.steps-form')[0])
  bind_standard_fields_search()
  bind_custom_fields_search()
  bind_patient_search()
  bind_master_workflow_search()
  bind_workflows_search()
  bind_staff_users_search()
  bind_field_tiles()
  bind_roles_search()
  bind_placeholders_search()
  $(".field-options-tags").select2();
  $(".field-options-tags-data-collection").select2();
  bind_fields_search()
  bind_placeholder_model()
  bind_display_placeholder_options()
  bind_field_flag()
  bind_add_cocoon()
  bind_tabular_question_field()
  bind_tabular_answer_field()
  $('.js-states').select2()
  bind_wizard()
  bind_patient_report_search()
  bind_collect_options()
  bind_collect_options_form_field()
  bind_fields_select_search()
  bind_staff_user_select_search()
  bind_patient_select_search()
  bind_master_workflow_select_search()
  bind_staff_profile_picture()
  bind_assigned_forms_search()
  bind_master_workflow_save()
  bind_workflow_save()
  bind_pleceholders_select_search()
  bind_form_select_search()
  bind_role_dropdown();
  bind_hide_drop_div()
  bind_standard_fields_forms_search()
  bind_only_numbers()
  bind_admin_users_search()
  bind_clinics_search()
  bind_admin_user_select_search()
  bind_flowy()
  bind_rearrange()
  bind_ipda_staff()
  bind_number_field_increment()
  bind_number_field_decrement()
  bind_rule_box();
});

function bind_number_field_increment() {
  $('body').on('click', '.increment', function(event) {
    event.target.parentNode.querySelector('input[type=number]').stepUp();
    return false;
  })
}

function bind_number_field_decrement() {
  $('body').on('click', '.decrement', function(event) {
    event.target.parentNode.querySelector('input[type=number]').stepDown();
    return false;
  })
}

function bind_ipda_staff () {
  if ($('.staff_listing_page').length == 1) {
    if (screen.width == 1366) {
      $('.ipad-class').addClass('hide')
    }
    else {
      $('.ipad-class').removeClass('hide')
    }
  }
}

function bind_role_dropdown () {
  $('#role-dropdown').on('change', function(e) {
    var value = $('#role-dropdown')[0].selectedOptions[0].innerHTML;
    if (value == 'Doctor') {
      $('.dr-license-number').removeClass('d-none');
    } else {
      $('.dr-license-number').addClass('d-none');
    }
  });
}

function bind_staff_profile_picture () {
  $('body').on('change', '#staff_user_profile_picture', function(e) {
    let image_name = $('#staff_user_profile_picture').val().replace(/^.*[\\\/]/, '')
    toastr.info('Image Uploaded: ' + image_name);
  });
}

function bind_add_cocoon() {

}

function bind_field_flag () {
  $('body').on('change', '#fields-options', function(e) {
    update_flag_options('#fields-options', '.field_flag_value', true)
  });
}

function bind_tabular_question_field () {
  $('body').on('change', '#tabular-question', function(e) {
    update_flag_options('#tabular-question', '.tabular_question_value', false)
  });
}

function bind_tabular_answer_field () {
  $('body').on('change', '#tabular-answer', function(e) {
    update_flag_options('#tabular-answer', '.tabular_answer_value', false)
  });
}

function update_flag_options(source, target, option_field) {
  if (option_field && $(source).val()){
    var flag_value_options = $(source).val().split(',')
  }else{
    var flag_value_options = $(source).val()
  }
  var seloption = "";
  $.each(flag_value_options,function(element){
    seloption += '<option value="'+flag_value_options[element]+'">'+flag_value_options[element]+'</option>';
  });
  $(target).empty().append(seloption);
}

function bind_add_new_fields () {
  $('#add_new_field').on('click', function(e) {
    number = Math.floor(Math.random() * 100);
    $('.card-header').last().attr('id', ($('.card-header').last().attr('id')+ '_' + number ))
    $('.card-header-link').last().attr('data-target', ($('.card-header-link').last().attr('data-target') + '_'+ number))
    $('.collapse').last().attr('id', ($('.collapse').last().attr('id')+ '_' + number) )
    $('.collapse').last().attr('aria-labelledby', ($('.collapse').last().attr('aria-labelledby')+ '_' + number) )
  });
}
function bind_fields_options () {
  $('body').on('change', '.field_type' , function(e) {
    if ($(this).val() === 'radio_button' || $(this).val() === 'checkbox' || $(this).val() === 'dropdown' || $(this).val() === 'multiselect') {
      $(this).closest('.card-body').find('.options_container').removeClass('hide');
    } else {
      $(this).closest('.card-body').find('.options_container').addClass('hide');
    }
  });
}

function bind_accordion_title () {
  $('body').on('focusout', '.field_name', function(e) {
    if ($(this).val() !== '') {
      $(this).closest('.card').find('.card-header-link').text($(this).val())
    }
  });
}

function bind_rule_box () {
  $('body').on('click', '.hide_rule_box', function(e) {
    $('body').find('#drop_target').find(".field").css("background-color", "#ffffff")
    $('.logic-section').addClass('hide')
  });
}

function bind_standard_fields_search (){
  return $('#standard_fields_search input').keyup(function() {
    return $.get($('#standard_fields_search').attr('action'), $('#standard_fields_search').serialize(), null, 'script');
  });
}

function bind_custom_fields_search (){
  return $('#custom_fields_search input').keyup(function() {
    return $.get($('#custom_fields_search').attr('action'), $('#custom_fields_search').serialize(), null, 'script');
  });
}

function show_actual_field (){
  $('.current-field').find('.text-field').addClass('hide')
  $('.current-field').find('.actual-field').removeClass('hide')
  $('.current-field').find('.text-field').removeClass('style-inline')
  $('.current-field').removeClass('current-field')
}

function bind_patient_search () {
  return $('#patients_search input').keyup(function() {
    return $.get($('#patients_search').attr('action'), $('#patients_search').serialize(), null, 'script');
  });
}

function bind_master_workflow_search () {
  return $('#master-workflows_search input').keyup(function() {
    return $.get($('#master-workflows_search').attr('action'), $('#master-workflows_search').serialize(), null, 'script');
  });
}

function bind_workflows_search () {
  return $('#workflows_search input').keyup(function() {
    return $.get($('#workflows_search').attr('action'), $('#workflows_search').serialize(), null, 'script');
  });
}

function bind_placeholders_search () {
  return $('#placeholders_search input').keyup(function() {
    return $.get($('#placeholders_search').attr('action'), $('#placeholders_search').serialize(), null, 'script');
  });
}

function bind_workflow_remove () {
  $('body').on('click', '.remove-workflow-btn',function(e) {
    drop_container = $(this).closest('.drop-container')
    workflow_container = $(this).closest('.children-workflow')
    workflow_id = workflow_container.data('children-workflow-id')
    workflow_container.remove()
    $('#master_workflow_children_workflow_ids').val($('#master_workflow_children_workflow_ids').val().replace(workflow_id,''))
    if(drop_container.children().length == 1){
      $('.drop-div').removeClass('hide');
    }
  });
}

function bind_fields_search () {
  return $('#fields_search input').keyup(function() {
    return $.get($('#fields_search').attr('action'), $('#fields_search').serialize(), null, 'script');
  });
}

function bind_staff_users_search () {
  return $('#staff_users_search input').keyup(function() {
    return $.get($('#staff_users_search').attr('action'), $('#staff_users_search').serialize(), null, 'script');
  });
}

function bind_roles_search () {
  return $('#roles_search input').keyup(function() {
    return $.get($('#roles_search').attr('action'), $('#roles_search').serialize(), null, 'script');
  });
}

function bind_field_tiles () {
  $('body').on('click', '.field-type-tiles', function(e) {
    $('.field-type-container').addClass('hide');
    field_type = $(this).data('field-type');
    $('#field_field_type').val(field_type);
    if (field_type == 'radio' || field_type == 'checkbox' || field_type == 'single_select' || field_type == 'multi_select') {
      $('.options_field').removeClass('hide');
      $('.add-flag-div').removeClass('hide');
      $('.flag').addClass('hide');
      bind_remove_btn()
    }

    if (field_type == 'static_text') {
      $('.staticTextDiv')[0].classList.remove('hide');
    }

    if (field_type == 'tabular') {
      $('.tableNamesDiv')[0].classList.remove('hide');
      $('.tableOptionsDiv')[0].classList.remove('hide');
      $('.add-flag-div').removeClass('hide');
      $('.flag').addClass('hide');
    }
    if (field_type == 'tabular_data') {
      $('.tableDataRows')[0].classList.remove('hide');
      $('.tableDataColumns')[0].classList.remove('hide');
    }
    $('.form-fields').removeClass('hide');
  });
}

function bind_placeholder_model () {
  $('body').on('change', '#placeholder_model', function(e) {
    bind_display_placeholder_options()
  });
}
function bind_display_placeholder_options () {
  if ($('#placeholder_model').val() == "Patient"){
    $('#patient_attribute').show()
    $('#field_attribute').hide()
    $('#staffuser_attribute').hide()
  }else if ($('#placeholder_model').val() == "Field"){
    $('#patient_attribute').hide()
    $('#field_attribute').show()
    $('#staffuser_attribute').hide()
  }else if ($('#placeholder_model').val() == "StaffUser"){
    $('#patient_attribute').hide()
    $('#field_attribute').hide()
    $('#staffuser_attribute').show()
  }
}

function bind_wizard () {
  $("#wizard").steps({
    bodyTag: "fieldset",
    onStepChanging: function (event, currentIndex, newIndex) {
        if (currentIndex > newIndex) {
            return true;
        }
        var form = $(this);
        if (currentIndex < newIndex) {
            $(".body:eq(" + newIndex + ") label.error", form).remove();
            $(".body:eq(" + newIndex + ") .error", form).removeClass("error");
        }
        form.validate().settings.ignore = ":disabled,:hidden";
        return form.valid();
    },
    onStepChanged: function (event, currentIndex, priorIndex) {
        if (currentIndex === 2 && Number($("#age").val()) >= 18) {
            $(this).steps("next");
        }
        if (currentIndex === 2 && priorIndex === 3) {
            $(this).steps("previous");
        }
    },
    onFinishing: function (event, currentIndex) {
        var form = $(this);
        form.validate().settings.ignore = ":disabled";
        return form.valid();
    },
    onFinished: function (event, currentIndex) {
        var form = $(this);
        enableFinishButton: false;
        form.submit();
    }
  }).validate({
      errorPlacement: function (error, element) {
          element.before(error);
      },
      rules: {
          confirm: {
              equalTo: "#password"
          }
      }
  });
}

function bind_patient_report_search () {
  return $('#patient_report_search input').keyup(function() {
    return $.get($('#patient_report_search').attr('action'), $('#patient_report_search').serialize(), null, 'script');

  });
}

function bind_collect_options () {
  $('body').on('click', '.add-option-btn', function(e) {
    var container = document.getElementById("container");
    var section = document.getElementById("mainsection");
    container.appendChild(section.cloneNode(true));
    var element = $('#container div.add-option:last');
    $(element).find('.option-field').val('');
    bind_options_name()
    bind_remove_btn ()
    bind_option_fields_tag()
  });

  $('body').on('click', '.remove-option-btn', function(e) {
    var element = $(this).closest('#mainsection');
    $(element).find('.option-field').removeAttr('name');
    $(element).find('.option-field').removeClass('option-field');
    $(element).find("#option-input-field").prop('required',false);
    $(element).removeAttr('id');

    $(element).hide()
    bind_remove_btn ()
    bind_option_fields_tag()
  });

  $('body').on('change', '.option-field', function(e) {
    bind_options_name()
    bind_option_fields_tag()
  });
}

function bind_remove_btn () {
  if ($('.option-field').length <= 1) {
    $('.remove-option-btn').hide();
  }
  else{
    $('.remove-option-btn').show();
  };
}

function bind_options_name () {
  $('.option-field').each( function(e){
    if ($(this).val() == ''){
      $(this).attr('name', '');
    } else{
      $(this).attr('name', 'field[options][]');
    }
  });
}

function bind_collect_options_form_field () {
  $('body').on('click', '.add-option-btn-form-field', function(e) {
    var container = document.getElementById("container");
    var section = document.getElementById("mainsection");
    container.appendChild(section.cloneNode(true));
    var element = $('#container div.add-option:last');
    $(element).find('.option-form-field').val('');
    bind_options_name_form_field()
    bind_remove_btn_form_field ()
    bind_option_fields_tag_form_field()
  });

  $('body').on('click', '.remove-option-btn-form-field', function(e) {
    var element = $(this).closest('#mainsection');
    $(element).find('.option-form-field').removeAttr('name');
    $(element).find('.option-form-field').removeClass('option-form-field');
    $(element).find("#option-input-field").prop('required',false);
    $(element).removeAttr('id');

    $(element).hide()
    bind_remove_btn_form_field ()
    bind_option_fields_tag_form_field()
  });

  $('body').on('change', '.option-form-field', function(e) {
    bind_options_name_form_field()
    bind_option_fields_tag_form_field()
    update_flag_options('#fields-options', '.field_flag_value', true)
  });
}

function bind_remove_btn_form_field () {
  if ($('.option-form-field').length <= 1) {
    $('.remove-option-btn-form-field').hide();
  }
  else{
    $('.remove-option-btn-form-field').show();
  };
}

function bind_options_name_form_field () {
  $('.option-form-field').each( function(e){
    if ($(this).val() == ''){
      $(this).attr('name', '');
    } else{
      $(this).attr('name', 'form_field[options][]');
    }
  });
}
function bind_option_fields_tag_form_field () {
  var values = [];
  $('.option-form-field').each( function(e){
    if ($(this).val() == ''){

    }else{
      values.push($(this).val())
    }
  });
  $('#fields-options').val(values)
}
function bind_fields_select_search () {
  return $('#fields_search select').change(function() {
    return $.get($('#fields_search').attr('action'), $('#fields_search').serialize(), null, 'script');
  });
}

function bind_staff_user_select_search () {
  return $('#staff_users_search select').change(function() {
    return $.get($('#staff_users_search').attr('action'), $('#staff_users_search').serialize(), null, 'script');
  });
}

function bind_patient_select_search () {
  return $('#patients_search select').change(function() {
    return $.get($('#patients_search').attr('action'), $('#patients_search').serialize(), null, 'script');
  });
}

function bind_master_workflow_select_search () {
  return $('#master-workflows_search select').change(function() {
    return $.get($('#master-workflows_search').attr('action'), $('#master-workflows_search').serialize(), null, 'script');
  });
}

function bind_option_fields_tag () {
  var values = [];
  $('.option-field').each( function(e){
    if ($(this).val() == ''){

    }else{
      values.push($(this).val())
    }
  });
  $('#fields-options').val(values)
}

function bind_assigned_forms_search () {
  return $('#assigned_workflows_search select').change(function() {
    return $.get($('#assigned_workflows_search').attr('action'), $('#assigned_workflows_search').serialize(), null, 'script');
  });
}

function bind_master_workflow_save () {
  return $('.master-save-btn').click(function() {
    $("[name='_method']").val('Patch');
  });
}

function bind_workflow_save () {
  return $('.workflow-save-btn').click(function() {
    $("[name='_method']").val('Patch');
  });
}

function bind_pleceholders_select_search () {
  return $('#placeholders_search select').change(function() {
    return $.get($('#placeholders_search').attr('action'), $('#placeholders_search').serialize(), null, 'script');
  });
}

function bind_form_select_search () {
  return $('#workflows_search select').change(function() {
    return $.get($('#workflows_search').attr('action'), $('#workflows_search').serialize(), null, 'script');
  });
}


function bind_hide_drop_div () {
  if($('.drop-container').children().length >= 2){
    $('.drop-div').addClass('hide');
  }
}

function bind_standard_fields_forms_search (){
  return $('#standard_fields_forms_search input').keyup(function() {
    return $.get($('#standard_fields_forms_search').attr('action'), $('#standard_fields_forms_search').serialize(), null, 'script');
  });
}

function bind_only_numbers (){
  $(".number-field").keydown(function(e) {
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      (e.keyCode >= 35 && e.keyCode <= 40)) {
        return;
    }
    else {
      if (e.keyCode < 48 || e.keyCode > 57 ) {
        e.preventDefault();
      }
    }
  });
}

function bind_admin_users_search () {
  return $('#admin_users_search input').keyup(function() {
    return $.get($('#admin_users_search').attr('action'), $('#admin_users_search').serialize(), null, 'script');
  });
}

function bind_clinics_search () {
  return $('#clinics_search input').keyup(function() {
    return $.get($('#clinics_search').attr('action'), $('#clinics_search').serialize(), null, 'script');
  });
}

function bind_admin_user_select_search () {
  return $('#admin_users_search select').change(function() {
    return $.get($('#admin_users_search').attr('action'), $('#admin_users_search').serialize(), null, 'script');
  });
}

function bind_rearrange () {
  $('body').on('click', '.rearrange_button', function(e) {
    flowy.rearrangeIt();
  });
}

function bind_flowy () {
  if ($('#show-canvas').length == 1) {
    var spacing_x = 20;
    var spacing_y = 100;
    var fowlyObject = flowy(document.getElementById("show-canvas"), '', '', '', '', spacing_x, spacing_y, '');
    $.ajax({
      url: "/admin/master_workflows/flowy_data",
      method: 'GET',
      data: {
        id: window.location.href.split('/').slice(-1)[0]
      },
      success: (result) => {
        flowy.import(result);
        flowy.rearrangeIt();
      }
    });

    function onExistingSnap (a, b) {return false;}
    function onGrab(block){return false;}
    function onRelease(block){return false;}
    function onRearrange(block, parent) {return false;}
    function onSnap(block, first, parent) {return false;}
  }
}

function bindStickyHeader(resource_class) {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 1) {
      $('body').addClass(`${resource_class}`);
    }
    else {
      $('body').removeClass(`${resource_class}`);
    }
  });
}
