import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import ChartJS from "chart.js";
import {
  randomColor,
  toTitleCase,
  formatFloat,
} from "helpers";

export default class extends ApplicationController {
  static targets = ['']
  static values = {
    patientTreatmentsCreatedGraphData: Object,
    revenueGraphData: Object,
    completedTreatmentsPerDayGraphData: Object,
    presentedTreatmentsGraphData: Object,
    acceptedTreatmentsGraphData: Object,
    rejectedTreatmentsGraphData: Object,
    unscheduledTreatmentsGraphData: Object,
    scheduledTreatmentsGraphData: Object,
    completedTreatmentsGraphData: Object,
    acceptedTreatmentsAverageGraphData: Object,
    rejectedTreatmentsAverageGraphData: Object,
  }
  connect() {
    StimulusReflex.register(this)
    $('body')[0].classList.add('mini-navbar');
    this.drawChart('#treatmentsCreatedGraphChart', 'Patient Treatments Created', this.patientTreatmentsCreatedGraphDataValue, 'rrgba(51, 204, 51, 0.2)');
    this.drawLineBarChart('#revenueGraphChart', 'Collection', this.revenueGraphDataValue, 'rrgba(51, 204, 51, 0.2)');
    this.drawLineBarChart('#completedTreatmentsPerDayGraphChart', 'Production', this.completedTreatmentsPerDayGraphDataValue, 'rrgba(51, 204, 51, 0.2)');
    this.drawPieChart('#presentedTreatmentsGraphChart', 'Presented Treatments', this.presentedTreatmentsGraphDataValue, $('#presented_treatment_plan_ids').val());
    this.drawPieChart('#acceptedTreatmentsGraphChart', 'Accepted Treatments', this.acceptedTreatmentsGraphDataValue, $('#accepted_treatment_plan_ids').val());
    this.drawPieChart('#rejectedTreatmentsGraphChart', 'Rejected Treatments', this.rejectedTreatmentsGraphDataValue, $('#rejected_treatment_plan_ids').val());
    this.drawPieChart('#unscheduledTreatmentsGraphChart', 'Unscheduled Treatments', this.unscheduledTreatmentsGraphDataValue, $('#unscheduled_treatment_plan_ids').val());
    this.drawPieChart('#scheduledTreatmentsGraphChart', 'Scheduled Treatments', this.scheduledTreatmentsGraphDataValue, $('#scheduled_treatment_plan_ids').val());
    this.drawPieChart('#completedTreatmentsGraphChart', 'Completed Treatments', this.completedTreatmentsGraphDataValue, $('#completed_treatment_plan_ids').val());
    this.drawPieChart('#acceptedTreatmentsAverageGraphChart', 'Average Days For Treatment Acceptance', this.acceptedTreatmentsAverageGraphDataValue, $('#accepted_treatment_average_ids').val());
    this.drawPieChart('#rejectedTreatmentsAverageGraphChart', 'Average Days For Treatment Rejection', this.rejectedTreatmentsAverageGraphDataValue, $('#rejected_treatment_average_ids').val());
  }

  drawChart (identifier, title, data, color) {
    var vm = this;
    var ctx = $(identifier)[0];
    var graph1 = new ChartJS(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            type: 'bar',
            borderColor: 'green',
            data:  Object.values(data),
            backgroundColor: color,
            fill: false,
          },
        ],
        labels: Object.keys(data),
      },
      options: {
        legend: {
          display: false //This will remove inner labels
        },
        title: {
          display: true,
          text: title
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Number Of Treatments',
              fontStyle: 'bold'
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Date',
              fontStyle: 'bold'
            }
          }]
        },
        onClick: (e) => {
          var activePoints = graph1.getElementsAtEvent(e);

          if (activePoints[0]) {
            var chartData = activePoints[0]['_chart'].config.data;
            var idx = activePoints[0]['_index'];

            var label = chartData.labels[idx];
            var value = chartData.datasets[0].data[idx];
            var params = {
              start_date: $('#start_date').val(),
              end_date: $('#end_date').val(),
              title: title,
              date_label: label
            }

            $("#date_clicked").val(label);
            $("#graph_title").val(title);

            vm.stimulate('TreatmentAnalytic#treatment_plans_table', params);
          }
        }
      },
      plugins: {
        afterDraw: function(chart) {
          if (chart.data.datasets[0].data.every(item => item === 0)) {
            let ctx = chart.chart.ctx;
            let width = chart.chart.width;
            let height = chart.chart.height;

            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText('No data to display', width / 2, height / 2);
            ctx.restore();
          }
        }
      }
    });
    graph1.update();
  }

  drawLineBarChart(identifier, title, data, color) {
    var vm = this;
    var ctx = $(identifier)[0];
    var graph1 = new ChartJS(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            type: 'line',
            label: title,
            borderColor: '#0376E3',
            tension: 0.1,
            data:  Object.values(data),
            fill: false,

          },
          {
            type: 'bar',
            borderColor: 'green',
            data:  Object.values(data),
            backgroundColor: color,
            fill: false,
          },
        ],
        labels: Object.keys(data),
      },
      options: {
        legend: {
          display: false //This will remove inner labels
        },
        title: {
          display: true,
          text: title
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function(label, index, labels) {
                return "$" + formatFloat(label);
              }
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount',
              fontStyle: 'bold'
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Date',
              fontStyle: 'bold'
            }
          }]
        },
        onClick: (e) => {
          var activePoints = graph1.getElementsAtEvent(e);

          if (activePoints[0]) {
            var chartData = activePoints[0]['_chart'].config.data;
            var idx = activePoints[0]['_index'];

            var label = chartData.labels[idx];
            var value = chartData.datasets[0].data[idx];
            var params = {
              title: title,
              date_label: label
            }

            $("#date_clicked").val(label);
            $("#graph_title").val(title);

            if(title == 'Collection')
              vm.stimulate('TreatmentAnalytic#transaction_table', params);
            else
              vm.stimulate('TreatmentAnalytic#treatment_plans_table', params);
          }
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']];
            },
            label: function(tooltipItem, data) {
              var pieLabel = data['datasets'][0]['data'][tooltipItem['index']]
              pieLabel = '$' + formatFloat(pieLabel);

              return pieLabel;
            }
          }
        }
      }
    });
    graph1.update();
  }

  drawPieChart (identifier, title, data, treatmentsData) {
    var colors_array = []
    if(Object.keys(data).length > 0) {
      (Object.keys(data)).forEach(function(element, index) {
        colors_array[index] = randomColor();
      });
    }

    var outcomeTreatment = true;
    var pieLegend = false;
    var pieDollar = true
    if(title == 'Average Days For Treatment Acceptance' || title == 'Average Days For Treatment Rejection')
      pieDollar = false

    var labelsArray = Object.keys(data)
    var x;
    if(Object.keys(data).length > 0) {
      (Object.keys(data)).forEach(function(element, index) {
        x = element.replaceAll('_', ' ')
        labelsArray[index] = toTitleCase(x)
      });
    }

    var vm = this;
    var ctx = $(identifier)[0];
    var graph = new ChartJS(ctx, {
      type: 'pie',
      data: {
        datasets: [{
          backgroundColor: colors_array,
          data: Object.values(data)
        }],
        labels: labelsArray
      },
      options: {
        legend: {
          display: pieLegend //This will remove inner labels
        },
        responsive: true,
        title: {
          display: true,
          text: title
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']];
            },
            label: function(tooltipItem, data) {
              var pieLabel = data['datasets'][0]['data'][tooltipItem['index']]
              if(pieDollar){
                pieLabel = '$' + pieLabel;
              }
              else{
                pieLabel = pieLabel + 'days';
              }

              return pieLabel
            }
          }
        },
        onClick: (e) => {
          var activePoints = graph.getElementsAtEvent(e);

          if (activePoints[0]) {
            var chartData = activePoints[0]['_chart'].config.data;
            var idx = activePoints[0]['_index'];

            var label = chartData.labels[idx];
            var value = chartData.datasets[0].data[idx];
            var treatmentPlanId;

            var params = {
              start_date: $('#start_date').val(),
              end_date: $('#end_date').val(),
              label: label,
              title: title
            }
            if(outcomeTreatment) {
              treatmentPlanId = JSON.parse(treatmentsData)[idx];
              params['treatment_plan_id'] = treatmentPlanId;
            }
            $("#graph_title").val(title);

            this.stimulate('TreatmentAnalytic#treatment_plans_table', params)
          }
        }
      },
      plugins: {
        afterDraw: function(chart) {
          if (chart.data.datasets[0].data.every(item => item === 0)) {
            let ctx = chart.chart.ctx;
            let width = chart.chart.width;
            let height = chart.chart.height;

            chart.clear();
            ctx.save();
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText('No data to display', width / 2, height / 2);
            ctx.restore();
          }
        }
      }
    });
    graph.update();
  }

  transactionTableSuccess(element, reflex, noop, reflexId, data) {
    var date = new Date($("#date_clicked").val());
    date = this.formatDate(date);
    $('#chartModalHeading').html("");
    $('#chartModalHeading').html(`Revenue Transactions on ${date}`);
    $("#chartsModal").modal('show');
  }

  treatmentPlansTableSuccess(element, reflex, noop, reflexId) {
    var date = new Date($("#date_clicked").val());
    date = this.formatDate(date);
    var title = $("#graph_title").val();
    var modal_title;
    if(title == 'Average Days For Treatment Acceptance') {
      modal_title = 'Accepted Treatments'
    }
    else if(title == 'Average Days For Treatment Rejection') {
      modal_title = 'Rejected Treatments'
    }
    else{
      modal_title = title
    }

    $('#chartModalHeading').html("")
    if(title == 'Patient Treatments Created'){
      $('#chartModalHeading').html(`Treatments Created on ${date}`);
    }
    else {
      $('#chartModalHeading').html(`${modal_title}`);
    }
    $("#chartsModal").modal('show');
  }

  formatDate(date) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0');
    var yyyy = date.getFullYear();

    return dd + '/' + mm + '/' + yyyy;
  }
}
