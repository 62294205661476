import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ['overview', 'form', 'id', 'submitBtn']

	connect() {
		if(this.hasFormTarget){
			this.initDragula();
      $('body')[0].classList.add('mini-navbar');
		}

		this.activeRequests = 0;
		this.form = $(this.element)
		this.timeout = null
	}

	mainAttributesChanged () {
		this.saveDraft();
	}

	initDragula () {
		var _self = this;
		var drake = dragula([document.getElementById('drag_element'), document.getElementById('drag_custom_element'), document.getElementById('drop_target')], {
			copy: function (el, source) {
				return source === document.getElementById('drag_element') ||
				source === document.getElementById('drag_custom_element')
			},
			accepts: function (el, target) {
				return target !== document.getElementById('drag_element') ||
				target !== document.getElementById('drag_custom_element')
			}
		}).on('drop', function (el, target, source, sibling) {
			if(target !== source){
				el.className += ' current-field'
				show_actual_field()
				_self.createCopy($(el).data('field-id'))
				_self.bind_field_indexing ('.drop-container')
				if($('.drop-container').children().length >= 2){
					$('.drop-div').addClass('hide');
				}
			} else {
				_self.bind_field_indexing ('.drop-container')
				_self.saveDraft();
			}
		});

		scroll = autoScroll([
			window,
			document.querySelector('#drop_target')
		],{
			margin: 20,
			autoScroll: function(){
				return this.down && drake.dragging;
			}
		});

		function show_actual_field (){
			$('.current-field').find('.text-field').addClass('hide')
			$('.current-field').find('.actual-field').removeClass('hide')
			$('.current-field').find('.text-field').removeClass('style-inline')
			$('.current-field').removeClass('current-field')
		}

		function bind_field_remove () {
			$('body').on('click', '.remove-field', function(e) {
				var field_container = $(this).closest('.field')
				field_container.remove()
				_self.bind_field_indexing ('.drop-container')
				if($('.drop-container').children().length == 1){
					$('.drop-div').removeClass('hide');
				}
				_self.saveDraft();
			});
		}
		bind_field_remove()
	}

	bind_field_indexing (klass) {
		var field_ids = []
		this.submitBtnTarget.disabled = true;
		$(klass).children().each( function( index, element ){
			field_ids.push($( this ).data('field-id'));
		});
		$('#workflow_field_ids').val(field_ids);
		this.submitBtnTarget.disabled = false;
	}

	formEvent () {
		var el = this.formTarget;
		var ev = document.createEvent('Event');
		ev.initEvent('change', true, false);
		el.dispatchEvent(ev);
	}

	form_data() {
		var obj = this.serializeForm();

		return obj;
	}

	serializeForm() {
		return this.form.serializeArray().reduce(function(output, value) {
			output[value.name] = value.value
			return output
		}, {})
	}

	saveDraft() {
		var _self = this;

		clearTimeout(this.timeout)
		$('.tabular-scroll').addClass('loading');
		$('.drop-here').addClass('blur-div')
		this.timeout = setTimeout(() => {
			this.activeRequests+=1;
			_self.bind_field_indexing ('.drop-container')

			$.ajax({
				url: '/admin/forms/autosave',
				method: 'POST',
				data: this.form_data(),
				beforeSend: () => {
					if (_self.hasSubmitBtnTarget) {
						_self.submitBtnTarget.disabled = true;
					}
				},
				success: (result) => {
					$('.tabular-scroll').removeClass('loading')
					$('.drop-here').removeClass('blur-div')
					_self.idTarget.value = result.id;
				},
				error: (e) => {
					$('.tabular-scroll').removeClass('loading')
					$('.drop-here').removeClass('blur-div')

					console.log('error', e);
				},
				complete: () => {
					$('.tabular-scroll').removeClass('loading')
					$('.drop-here').removeClass('blur-div')

					this.activeRequests-=1;
					if (_self.hasSubmitBtnTarget && this.activeRequests == 0) {
						console.log('here...')
						_self.submitBtnTarget.disabled = false;
					}
				}
			});
		}, 1000)
	}

	createCopy(field_id) {
		var _self = this;
		$('.tabular-scroll').addClass('loading');
		$('.drop-here').addClass('blur-div')
		var workflow_id = $('#workflow_id').val();
		$.ajax({
			url: '/admin/forms/create_copy',
			method: 'POST',
			dataType: 'script',
			data: {
				workflow_id: workflow_id,
				field_id: field_id
			},
			complete: () => {
				var field_ids = []
				var klass = '.drop-container'
				$(klass).children().each( function( index, element ){
					field_ids.push($( this ).data('field-id'));
				});
				$('#workflow_field_ids').val(field_ids);
				_self.saveDraft();
			}
		});

	}
}
