import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController{
  connect () {
    StimulusReflex.register(this)
  }

  viewAllSteps (e) {
    var el = e.target;
    this.stimulate('Event#view_all_steps', el)
  }

  patientProfileSummary (e) {
    this.stimulate('Event#patient_profile_summary')
  }

  fileDownload (e) {
    var el = e.target;
    this.stimulate('Event#file_download', el)
  }

  viewPreviousForm (e) {
    var el = e.target;
    console.log('prevv', e)
    this.stimulate('Event#view_previous_form', el)
  }
}
