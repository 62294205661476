import { Controller } from "stimulus"
import {
  isAdmin,
} from "helpers";


export default class extends Controller {
  static targets = ['mainTreatment', 'newSubTreatmentBtnDiv', 'newSubTreatmentBtn', 'form', 'totalPatientPortion', 'discount', 'patientId', 'mode']
  static values = {
    patientId: String,
  }

  connect() {
    if (this.mainTreatmentTarget.value != '') {
      $(this.newSubTreatmentBtnDivTarget).removeClass('d-none')
      this.priceChanged();
    }
    this.modeChanged();
  }

  modeChanged () {
    var vm = this;
    var val = this.modeTarget.checked;

    if (val) {
      $('.medical-column').removeClass('d-none');
      $('.dental-column').removeClass('col-2');
      $('.dental-column').removeClass('col-sm-2');
      $('.dental-column').addClass('col-1');
    } else {
      $('.medical-column').addClass('d-none');
      $('.dental-column').removeClass('col-1');
      $('.dental-column').removeClass('col-sm-1');
      $('.dental-column').addClass('col-2');
    }
    var nodes = this.element.querySelectorAll('.sub-treatments');
    nodes.forEach(function(node){
      var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'treatment-field-row')
      destCtrl.resetSubTreatment();
    })
  }

  treatmentChanged () {
    var vm = this;
    var url = `/patients/${vm.patientIdValue}/patient_master_treatments/build_sub_treatments`;
    if (isAdmin()) { url = '/admin' + url; }

    $('.sub-treatments').html('');

    if (this.mainTreatmentTarget.value != '') {
      $(this.newSubTreatmentBtnDivTarget).removeClass('d-none')
      $.ajax({
        url: url,
        method: 'GET',
        data: {
          treatment_plan_id: vm.mainTreatmentTarget.value,
        },
        dataType: "script",
        success: (result) => {
        },
        error: (error) => {
          toastr.error('Something went wrong');
          return;
        },
      });
    } else {
      $(this.newSubTreatmentBtnDivTarget).addClass('d-none')
    }
  }

  priceChanged () {
    var amounts = this.formTarget.querySelectorAll('.amount-input');
    var allValues = Array.from(amounts).map(function(element) {
      if (element.value != '') {
        return parseFloat(element.value);
      }  else { return 0; }
    });

    var sum = allValues.reduce((a, b) => a + b, 0)
    var discountVal = this.discountTarget.value;
    if (!discountVal) {
      this.discountTarget.value = 0;
    }
    var discount = parseFloat(this.discountTarget.value);
    this.totalPatientPortionTarget.value = (sum - discount).toFixed(2)
  }

  subTreatmentRemoved (e) {
    $(e.target).closest('.nested-fields').find('.amount-input').val(0);
    this.priceChanged();
  }

  duplicateTreatment(e) {
    e.preventDefault();
    var vm = this;
    var element = e.target;
    var srcNode = $(element.closest('.sub-treatments'))[0];
    var srcCtrl = vm.application.getControllerForElementAndIdentifier(srcNode, 'treatment-field-row');

    $(this.newSubTreatmentBtnDivTarget).click()

    $(this.newSubTreatmentBtnTarget)[0].click()

    setTimeout(function (vm) {
      var nodes = vm.element.querySelectorAll('.sub-treatments');
      var node = nodes[nodes.length - 1];
      var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'treatment-field-row')
      destCtrl.duplicate({
        srcCtrl: srcCtrl
      });
      destCtrl.scrollToRow();
      vm.priceChanged();
      vm.modeChanged();
    }, 100, vm);
  }
}
