import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin
} from "helpers";

export default class extends ApplicationController {
  static targets = ['countedQuantity', 'difference', 'actualQuantity', 'templateCategory']

  connect() {
    StimulusReflex.register(this)
  }

  calculateDifference () {
    var countedQuantity = this.countedQuantityTarget.value;
    var actualQuantity = this.actualQuantityTarget.value;
    countedQuantity = parseFloat(countedQuantity);
    actualQuantity = parseFloat(actualQuantity);
    this.differenceTarget.value = actualQuantity - countedQuantity;
  }
}
