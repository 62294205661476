import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'childrenWorkflowId', 'showForm', 'emergencyTrigger', 'addToPriority', 'autoMap']
  connect() {
    let vm = this;
  }

  toggle (e) {
    let vm = this;

    var url = `/admin/children_workflows/${vm.childrenWorkflowIdTarget.value}/update_show_forms`;
    var trigger_value = (!vm.hasEmergencyTriggerTarget === true) ? null : vm.emergencyTriggerTarget.checked;
    $.ajax({
        url: url,
        method: 'patch',
        data: {
          children_workflow: {
            show_forms: vm.showFormTarget.checked,
            add_to_priority: vm.addToPriorityTarget.checked,
            allow_emergency_trigger:  trigger_value,
            auto_map: vm.autoMapTarget.checked
          }
        },
        dataType: "json",
        success: (result) => {
            if (result.status == 'ok') {
                if (result.revision) {
                  window.location.replace(window.location.origin + `/admin/master_workflows/${result.master_workflow_id}/edit`);
                } else {
                    toastr.success('Successfully Updated');
                    return;
                }
            }
        },
        error: (error) => {
            toastr.error('Something went wrong');
            return;
        },
    });
  }
}
