import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex";
import {
  refreshComponenets
} from "helpers";

export default class extends Controller {
	static targets = ['id', 'flowyInput', 'patientId', 'graphDiv', 'role', 'forceTrigger', 'page']

  connect() {
    StimulusReflex.register(this);
    if (this.hasGraphDivTarget)
      this.initCtrl();
  }


  initCtrl () {
    const vm = this;
    vm.myDiagram = null;
    this.init(vm);
    $('body')[0].classList.add('mini-navbar');
    var out = JSON.parse(vm.flowyInputTarget.value);
    if (out.class)
      vm.myDiagram.model = go.Model.fromJson(out);
    vm.myDiagram.requestUpdate();
  }

  unassignSuccess(element, reflex, noop, reflexId) {
    if (location.pathname.includes('smart_lists')) {
      var element = $('.refresh-profile')[0];
      if (element) {
        element.click();
      }
    } else {
      refreshComponenets();
    }
    toastr.success('Form un-assigned');
  }

  afterReflex(element, reflex, noop, reflexId) {
    if (element.classList.contains('table-action'))
      return;

    console.log('unassigedn2 ')
    location.reload();

    event.detail.controller.disconnect()
    if (reflex.split("#")[1] == 'unassign')
      location.reload();

    var graphdiv = document.getElementById(this.graphDivTarget.id);
    if (graphdiv !== null) {
        var olddiag = go.Diagram.fromDiv(graphdiv);
        if (olddiag !== null) olddiag.div = null;
    }
    this.initCtrl();
  }

  init(vm) {
    var divId = this.graphDivTarget.id

    var $$ = go.GraphObject.make;  // for conciseness in defining templates
     vm.myDiagram =
      $$(go.Diagram, divId,
        {
          allowCopy: false,
          "draggingTool.dragsTree": false,
          "commandHandler.deletesTree": false,
          layout:
            $$(go.TreeLayout,
              { angle: 90, arrangement: go.TreeLayout.ArrangementFixedRoots, nodeSpacing: 10, layerSpacing: 150, }),
          "undoManager.isEnabled": false
        });

    var bluegrad = $$(go.Brush, "Linear", { 0: "#fff", 1: "#fff" });
    var greengrad = $$(go.Brush, "Linear", { 0: "#2667ff", 1: "#2667ff" });
    var actionTemplate =
      $$(go.Panel, "Horizontal",
        $$(go.Shape,
          { width: 12, height: 12, margin: new go.Margin(0,0,0,0) },
          new go.Binding("figure"),
          new go.Binding("fill")
        ),
        $$(go.TextBlock,
          { font: "10pt Verdana, sans-serif" },
          new go.Binding("text")
        )
      );
    vm.setStartNode(vm, $$, bluegrad, greengrad);
    vm.setDefaultNode(vm, $$, bluegrad, actionTemplate, settings, triggerBlock);
    vm.setExternalFormNode(vm, $$, bluegrad, actionTemplate, settings, triggerBlock);
    vm.setTriggerWorkflowNode(vm, $$, bluegrad, actionTemplate);
    vm.setLinkTemplate(vm, $$);

    function triggerBlock (e, obj) {
			var fromNode = obj.part;
      var blockId = fromNode.data.key;
      var url = `/admin/patients/${vm.patientIdTarget.value}/patient_master_workflow/${vm.idTarget.value}/blocks/${blockId}/trigger_block`;
      if (vm.roleTarget.value == 'staff_user') {
        url = `/patients/${vm.patientIdTarget.value}/patient_master_workflow/${vm.idTarget.value}/blocks/${blockId}/trigger_block`;
      }
      $.ajax({
        url: url,
        data: {},
        method: 'POST',
        success: (result) => {
          $('#emergencyWorkflowModal').modal('hide');
          toastr.success('Form assigned')
        },
        error: (error) => {
        },
      });
    }

    function settings (e, obj) {
			var fromNode = obj.part;
      var blockId = fromNode.data.key;

      if (vm.pageTarget.value == 'emergency_trigger') {
        var url = `/admin/patients/${vm.patientIdTarget.value}/patient_master_workflow/`;
        if (vm.roleTarget.value == 'staff_user') {
          url = `/patients/${vm.patientIdTarget.value}/block_setting/`;
        }
      } else {
        var url = `/admin/patients/${vm.patientIdTarget.value}/block_setting/`;
        if (vm.roleTarget.value == 'staff_user') {
          url = `/patients/${vm.patientIdTarget.value}/block_setting/`;
        }
      }


      $.ajax({
        url: url,
        data: {
          block_id: blockId,
          patient_master_workflow_id: vm.idTarget.value
        },
        method: 'GET',
        success: (result) => {
        },
        error: (error) => {
        },
      });
    }

    var nodeDataArray = [
      { key: 0, category: "Start", text: "Start" },
    ];
    var linkDataArray = [

    ];
    vm.myDiagram.model = $$(go.GraphLinksModel,
      {
        copiesArrays: true,
        copiesArrayObjects: true,
        nodeDataArray: nodeDataArray,
        linkDataArray: linkDataArray
      });
  }

  setStartNode(vm, $$, bluegrad, greengrad) {
    vm.myDiagram.nodeTemplateMap.add("Start",
      $$(go.Node, "Spot",
        $$(go.Shape, "Circle",
          { width: 75, height: 75, fill: greengrad, stroke: null }
        ),
        $$(go.TextBlock,
          { font: "10pt Verdana, sans-serif", stroke: "white" },
          new go.Binding("text")
        ),
        $$(go.Panel, "Auto",
          { alignment: go.Spot.Right, portId: "from", fromLinkable: true, cursor: "pointer", click: 'newNodeModal'  },
        ),
      )
    );
  }

  setTriggerWorkflowNode(vm, $$, bluegrad, actionTemplate) {
    var greengrad = $$(go.Brush, "Linear", { 0: "#2667ff", 1: "#2667ff" });

    vm.myDiagram.nodeTemplateMap.add("TriggerWorkflow",
      $$(go.Node, "Vertical",
      new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
      new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded

      { selectionObjectName: "BODY" },
      $$(go.Panel, "Auto",
        { name: "BODY" },
        $$(go.Shape, "RoundedRectangle",
          { fill: greengrad, stroke: "#ccc" }
        ),
        $$(go.Panel, "Vertical",
          { margin: 10,
            width: 200,
          },
          $$(go.TextBlock,
            {
              stretch: go.GraphObject.Horizontal,
              font: "bold 12pt Verdana, sans-serif",
              margin: new go.Margin(10, 0, 15, 0),
            },
            new go.Binding("text", function(t) { return "(Workflow)\n" + t.question; })
          ),
        )
      ),
      $$(go.Panel,
        $$(go.Panel, "Table",
          $$("TreeExpanderButton",
            {
              cursor: "pointer",
              "ButtonBorder.strokeWidth": 0,
              "ButtonBorder.fill": "white",
              row: 0,
              column: 1,
            },
            $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgremove } ),
          ),
        ),
      ),

      )
    );
  }

  setDefaultNode(vm, $$, bluegrad, actionTemplate, settings, triggerBlock) {
    var settingsVisible = true;
    var assignVisible = false;

    if (vm.pageTarget.value == 'emergency_trigger') {
      settingsVisible = false;
      assignVisible = true;
    }
    vm.myDiagram.nodeTemplate =  // the default node template
      $$(go.Node, "Vertical",
        new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
        new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded
        { selectionObjectName: "BODY" },
        $$(go.Panel, "Auto",
          { name: "BODY" },
          $$(go.Shape, "RoundedRectangle",
            { fill: bluegrad, stroke: "#ccc", strokeWidth: 2 },
            new go.Binding("stroke", function(t) {
              if (t.assignment_data.status == 'Completed')
                return "blue";
              else if (t.assignment_data.status == 'In progress')
                return 'green';
              else if (t.assignment_data.status == 'Pending')
              return 'red';

            })
          ),
          $$(go.Panel, "Vertical",
            { margin: 10,
              width: 200,
            },
            $$(go.TextBlock,
              {
                stretch: go.GraphObject.Horizontal,
                font: "bold 12pt Verdana, sans-serif",
                margin: new go.Margin(10, 0, 15, 0),
              }, new go.Binding("text", "question")
            ),

            $$(go.TextBlock, { stroke: "red" }, new go.Binding("text", function(t) { return "Status: \t" + t.assignment_data.status; })),
            $$(go.TextBlock, { stroke: "red" }, new go.Binding("text", function(t) { return "Assigned To: \t" + t.assignment_data.assigned_to; })),
            $$(go.TextBlock, { stroke: "red" }, new go.Binding("text", function(t) { return "Due Date: \t" + t.assignment_data.due_date; })),
            $$(go.Panel, "Auto",
              {
                visible: settingsVisible,
                row: 0,
                column: 3,
                alignment: go.Spot.Right,
                margin: new go.Margin(0, 0, 30, 3),
                portId: "from",
                fromLinkable: true,
                cursor: "pointer",
                click: settings,
              },
              $$(go.Panel,
                $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgsetting } ),
              ),
            ),
            $$(go.Panel, "Horizontal",
              {
                visible: assignVisible,
                row: 1,
                column: 4,
                alignment: go.Spot.Right,
                margin: new go.Margin(0, 0, 30, 3),
                portId: "from",
                fromLinkable: true,
                cursor: "pointer",
                click: triggerBlock
              },
              $$(go.TextBlock, { stroke: "blue" } , new go.Binding("text", function(t) { if (t.assignment_data.due_date == 'N/A') return 'Trigger'; })),
            ),
          )
        ),
        $$(go.Panel,
            $$("TreeExpanderButton",
              {
                cursor: "pointer",
                "ButtonBorder.strokeWidth": 0,
                "ButtonBorder.fill": "white",
                // row: 0,
                // column: 1,
              },
              $$(go.Picture, { desiredSize: new go.Size(30, 30), source: window.imgremove } ),
            ),
        ),
  );}

  setExternalFormNode(vm, $$, bluegrad, actionTemplate, settings, triggerBlock) {
    var settingsVisible = true;
    var assignVisible = false;

    if (vm.pageTarget.value == 'emergency_trigger') {
      settingsVisible = false;
      assignVisible = true;
    }

    var bluegrad = $$(go.Brush, "Linear", { 0: "#fff", 1: "#fff" });

    vm.myDiagram.nodeTemplateMap.add("ExternalForm", // the default node template
      $$(go.Node, "Vertical",
        new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
        new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded
        { selectionObjectName: "BODY" },
        $$(go.Panel, "Auto",
          { name: "BODY" },
          $$(go.Shape, "RoundedRectangle",
            { fill: bluegrad, stroke: "#ccc", strokeWidth: 2 },
            new go.Binding("stroke", function(t) {
              if (t.assignment_data.status == 'Completed')
                return "blue";
              else if (t.assignment_data.status == 'In progress')
                return 'green';
              else if (t.assignment_data.status == 'Pending')
                return 'red';
            })
          ),
          $$(go.Panel, "Vertical",
            { margin: 10,
              width: 200,
            },
            $$(go.TextBlock,
              {
                stretch: go.GraphObject.Horizontal,
                font: "bold 12pt Verdana, sans-serif",
                margin: new go.Margin(10, 0, 15, 0),
              }, new go.Binding("text", function(t) { return "(External)\n" + t.question })
            ),

            $$(go.TextBlock, { stroke: "red" }, new go.Binding("text", function(t) { return "Status: \t" + t.assignment_data.status; })),
            $$(go.TextBlock, { stroke: "red" }, new go.Binding("text", function(t) { return "Assigned To: \t" + t.assignment_data.assigned_to; })),
            $$(go.TextBlock, { stroke: "red" }, new go.Binding("text", function(t) { return "Due Date: \t" + t.assignment_data.due_date; })),
            $$(go.Panel, "Auto",
              {
                visible: settingsVisible,
                row: 0,
                column: 3,
                alignment: go.Spot.Right,
                margin: new go.Margin(0, 0, 0, 3),
                portId: "from",
                fromLinkable: true,
                cursor: "pointer",
                click: settings,
              },
              $$(go.Panel,
                $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgsetting } ),
              ),
            ),
            $$(go.Panel, "Auto",
            {
              visible: assignVisible,
              row: 0,
              column: 3,
              alignment: go.Spot.Right,
              margin: new go.Margin(10, 10, 0, 3),
              portId: "from",
              fromLinkable: true,
              cursor: "pointer",
              click: triggerBlock,
            },
              $$(go.TextBlock,
                {
                  font: "bold 10pt Verdana, sans-serif", stroke: "#598BFF",
                },
                new go.Binding("text", function(t) { if (t.assignment_data.due_date == 'N/A') return 'Trigger'; })),
            ),
          )
        ),
        $$(go.Panel,
          $$(go.Panel, "Table",
            $$("TreeExpanderButton",
              {
                cursor: "pointer",
                "ButtonBorder.strokeWidth": 0,
                "ButtonBorder.fill": "white",
                row: 0,
                column: 1,
              },
              $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgremove } ),
            ),

          ),
        ),
      )
    );
  }

  setLinkTemplate(vm, $$) {
    vm.myDiagram.linkTemplate =
      $$(go.Link, go.Link.Orthogonal,
        { deletable: false,
          margin: new go.Margin(100,100,100,100),
          corner: 10 },
        $$(go.Shape,
          { strokeWidth: 2,
            stroke: '#598BFF',
            height: 20,

            toArrow: "Standard",
            margin: new go.Margin(100,100,100,100),

           }
        ),
        $$(go.TextBlock, go.Link.OrientUpright,
          {
            background: "white",
            visible: true,  // unless the binding sets it to true for a non-empty string
            segmentIndex: -2,
            segmentOrientation: go.Link.None,
            font: "bold 8.5px Verdana, Serif",
            height: 50,
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 4,
            width: 190,
            text: "verticalAlignment: Center", verticalAlignment: go.Spot.Center,
            textAlign: "center",
            segmentFraction: 0.4,
          },

          new go.Binding("text", "text"),
          new go.Binding("visible", "text", function(s) { if (s) return true; else return false; })
        )
      );
  }
}
