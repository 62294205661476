import { Controller } from "stimulus"
import ChartJS from "chart.js";
import {
  formatFloat
} from "helpers";
export default class extends Controller {
  static values = {
    formGraphData: Object,
    taskGraphData: Object,
  }
  connect() {
    this.drawFormsChart('#formGraphChart', 'form', this.formGraphDataValue, 'rgba(255, 71, 26, 0.4)');
    this.drawTasksChart('#taskGraphChart', 'task', this.taskGraphDataValue, 'rgba(51, 204, 51, 0.2)');
  }

  drawFormsChart (identifier, label, data, color) {
    var ctx = $(identifier)[0];
    var graph = new ChartJS(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            type: 'bar',
            label: 'Completed',
            backgroundColor: 'green',
            tension: 0.1,
            data:  Object.values(data['completed']),
            fill: false,
          },
          {
            type: 'bar',
            label: 'Pending',
            backgroundColor: 'blue',
            tension: 0.1,
            data:  Object.values(data['pending']),
            fill: false,
          },
          {
            type: 'bar',
            label: 'In Progress',
            backgroundColor: color,
            tension: 0.1,
            data:  Object.values(data['in_progress']),
            fill: false,
          },
          {
            type: 'bar',
            label: 'Overdue',
            backgroundColor: 'red',
            tension: 0.1,
            data:  Object.values(data['overdue']),
            fill: false,
          },

      ],
        labels: Object.keys(data['completed']),
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }]
        },
        title: {
          display: true,
          text: 'Forms Satistics'
        },
      }
    });
    graph.update();
  }

  drawTasksChart (identifier, label, data, color) {
    var ctx = $(identifier)[0];
    var graph = new ChartJS(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            type: 'bar',
            label: 'Completed',
            backgroundColor: 'green',
            tension: 0.1,
            data:  Object.values(data['completed']),
            fill: false,
          },
          {
            type: 'bar',
            label: 'Pending',
            backgroundColor: 'blue',
            tension: 0.1,
            data:  Object.values(data['pending']),
            fill: false,
          },
          {
            type: 'bar',
            label: 'Awaiting',
            backgroundColor: color,
            tension: 0.1,
            data:  Object.values(data['awaiting']),
            fill: false,
          },
          {
            type: 'bar',
            label: 'Overdue',
            backgroundColor: 'red',
            tension: 0.1,
            data:  Object.values(data['overdue']),
            fill: false,
          },
      ],
        labels: Object.keys(data['completed']),
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }]
        },
        title: {
          display: true,
          text: 'Tasks Satistics'
        },
      }
    });
    graph.update();
  }
}
