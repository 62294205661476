import { Controller } from "stimulus"
import ChartJS from "chart.js";
import {
  formatFloat
} from "helpers";
export default class extends Controller {
  static targets = ['']
  static values = {
    expenseGraphData: Object,
    revenueGraphData: Object,
  }
  connect() {
    this.drawChart('#expenseGraphChart', 'Expense', this.expenseGraphDataValue, 'rgba(255, 71, 26, 0.4)');
    this.drawChart('#revenueGraphChart', 'Revenue', this.revenueGraphDataValue, 'rgba(51, 204, 51, 0.2)');
  }

  drawChart (identifier, label, data, color) {
    var ctx = $(identifier)[0];
    var graph = new ChartJS(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            type: 'line',
            label: label,
            borderColor: '#0376E3',
            tension: 0.1,
            data:  Object.values(data),
            fill: false,

          },
          {
            type: 'bar',
            label: label,
            borderColor: 'green',
            data:  Object.values(data),
            backgroundColor: color,
            fill: false,
          },

      ],
        labels: Object.keys(data),
      },
      options: {
        legend: {
          display: false //This will remove inner labels
        },
        title: {
          display: true,
          text: label
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function(label, index, labels) {
                return "$" + formatFloat(label);
              }
            }
          }]
        }
      }
    });
    graph.update();
  }
}
