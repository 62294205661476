import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  static targets = ['answer', 'bmi', 'bmiWeight', 'bmiHeightFeet', 'bmiHeightInches', 'measurementSystem',
                     'fileField', 'fileInput', 'fileName', 'templatesModal', 'viewTemplateModal', 'dataFieldId', 'preview', 'gptModal',
                    'pipelineId', 'patientState', 'stageId', 'followUpDate', 'followUpNotes', 'leadStatusId']
  static values = {
    templates: Array,
    fieldId: String,
    stages: Array,
    existingStageId: String
  }


  connect() {
    StimulusReflex.register(this)

    if (this.hasPatientStateTarget) {
      console.log('Exisstages;', this.existingStageIdValue)

      this.patientStateChanged(null);
    }
  }

  fileChanged (e) {
    $(this.previewTarget).addClass('d-none')

    var [file] = e.target.files;
    if (file) {
      var { name: fileName, size } = file;
      if (fileName.length > 20) {
        fileName = fileName.substring(0, 20) + '...';
      }
      var fileSize = (size / 1000).toFixed(2);
      var fileNameAndSize = `${fileName} - ${fileSize}KB`;
      e.target.parentNode.querySelector('.file-name').textContent = fileNameAndSize;;

      // New Code for Image Preview
      if (file.type.startsWith('image/')) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewTarget.src = e.target.result; // Use target
          $(this.previewTarget).removeClass('d-none')
        }
        reader.readAsDataURL(file);
      } else {
        this.previewTarget.src = ''; // Use target
      }
    }
  }

  calculateBMI () {
    var weight = Number(this.bmiWeightTarget.value);
    var height = Number(this.bmiHeightFeetTarget.value) * 12;
    var heightInches = Number(this.bmiHeightInchesTarget.value);
    height += heightInches;
    var BMI = weight / Math.pow(height, 2) * 703

    this.bmiTarget.value = BMI.toFixed(2);;
  }

  openNoteTemplatesModal () {
    $(this.templatesModalTarget).show();
  }

  openGptModal () {
    var id = '#' + $(this.gptModalTarget)[0].id;
    $(id).modal('show')
  }

  applyTemplate (e) {
    $(this.templatesModalTarget).hide()
    var templateId = e.target.dataset['templateId'];
    var assignedWorkflowId = e.target.dataset['assignedWorkflowId']

    var template = this.templatesValue.find(function(template) {
      if (template.id == templateId)
        return template;
      });

    this.answerTarget.value = template.body

    var params = {
      template_id: templateId,
      assigned_workflow_id: assignedWorkflowId
    }

    this.stimulate("Event#clinical_note_template_used", params);
  }

  //// Followup field actions start

  patientStateChanged (e) {
    if (!this.hasPipelineIdTarget) {
      return;
    }
    // $('#workflow_fields_0_patient_state').closest('.form-group').addClass('d-none')

    var value = this.patientStateTarget.value;
    [this.pipelineIdTarget, this.stageIdTarget, this.followUpNotesTarget].forEach((target) => {
      $(target).closest('.form-group').addClass('d-none')
      $(target).removeAttr('required')
    });

    // debugger;
    if (value == 'patient_converted') {

    } else if (value == 'opportunity') {
      [this.pipelineIdTarget, this.stageIdTarget, this.followUpDateTarget, this.followUpNotesTarget].forEach((target) => {
        $(target).closest('.form-group').removeClass('d-none')
        $(target)[0].required = true;
      });
    } else if (value == 'lead') {
      [this.followUpNotesTarget].forEach((target) => {
        $(target).closest('.form-group').removeClass('d-none');
        $(target)[0].required = true;
      });
    }
    this.pipelineChanged();
  }

  pipelineChanged (e) {
    var vm = this;
    var existingStageId = this.existingStageIdValue;
    var pipelineId = this.pipelineIdTarget.value;
    var stages = this.stagesValue.filter((stage) => {
      return stage.pipeline_id == pipelineId;
    });


    $(vm.stageIdTarget).empty();

    stages.forEach(function (v) {
      var newOption = new Option(v.name, v.id, false, false);
      vm.stageIdTarget.append(newOption);
    });
    console.log('existingStageId', existingStageId)

    $(vm.stageIdTarget).val(existingStageId);
  }

  //// Followup field actions end
}