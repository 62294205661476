import { Controller } from "stimulus"
import {
  serializeFormData
} from "helpers";

export default class extends Controller {
  static targets = ['childrenWorkflowId', 'blockId', 'form', 'id', 'addLogicBtn' ]
  static values = {
    templates: Array,
  }
  connect() {
    let vm = this;
  }

  promptChanged (e) {
    var id = e.target.value;
    var template = this.templatesValue.find((template) => template.id == id);
    if (template) {
      CKEDITOR.instances['ckme'].setData(template.prompt);
    }
  }

  submitForm (e) {
    let vm = this;

    var isValid = vm.formTarget.reportValidity();
    if (!isValid) return;
    e.preventDefault();
    var url = (vm.idTarget.value ? `/admin/actions/${vm.idTarget.value}` : `/admin/actions`);

    var params = vm.form_data(vm.formTarget);

    $.ajax({
      url: url,
      method: 'POST',
      data: params,
      dataType: "json",
      success: (result) => {
        if (result.status == 'ok') {
          toastr.success('Action saved');
          $("#actionModal").modal('hide');
          vm.dispatchNodeEvent(vm.blockIdTarget.value);
          if (result.revision) {
            window.location.replace(window.location.origin + `/admin/master_workflows/${result.master_workflow_id}/edit`);
          }
        }
        return;
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }

  delete (e) {
    let vm = this;
    var blockId = e.target.dataset.blockId;

    e.preventDefault();
    if (confirm("Are you sure you want delete this action?")) {
      var url = e.target.href;

      $.ajax({
        url: url,
        method: 'DELETE',
        dataType: "json",
        success: (result) => {
          if (result.status == 'ok') {
            if (result.revision) {
              window.location.replace(window.location.origin + `/admin/master_workflows/${result.master_workflow_id}/edit`);
            } else {
              $("#configureBlockModal").modal('hide');
              vm.dispatchNodeEvent(blockId);
            }
            toastr.success('Action Deleted');
          }
          return;
        },
        error: (error) => {
          toastr.error('Something went wrong');
          return;
        },
      });
    }
  }

  dispatchNodeEvent (id) {
    let vm = this;
    var id = id;

    vm.element.dispatchEvent(new CustomEvent("nodeEvent", {
      detail: {
      blockId: id
      },
      bubbles: true
    }));
  }

  removeLogic () {
    this.addLogicBtnTarget.classList.remove('d-none');
  }

  addeLogic () {
    this.addLogicBtnTarget.classList.add('d-none');
  }

  serializeForm(form) {
    var result = serializeFormData(form)

    var docuEditor = CKEDITOR.instances['ckme'];

    if (docuEditor) {
      if (result["document_action[priority]"]) {
        result["document_action[content]"] = docuEditor.getData();
      } else if (result["automated_document_action[priority]"]) {
        result["automated_document_action[content]"] = docuEditor.getData();
      } else if (result["patient_summary_action[children_workflow_id]"]) {
        result["patient_summary_action[content]"] = docuEditor.getData();
      }
      CKEDITOR.remove(docuEditor);
    }

    result['children_workflow_id'] = this.childrenWorkflowIdTarget.value

    return result;
  }

  form_data(form) {
    let vm = this;

    var obj = vm.serializeForm(form);

    return obj;
  }
}
