import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = ["patient", 'fulfilment', 'classification', 'requestedBehalf', 'requestedBehalfDiv', 'contentArea', 'labPackForm'
                  ];

  connect() {
    StimulusReflex.register(this);
  }

  actionToggle() {
    var action = document.querySelector(".sticky-action");
    action.classList.toggle("active");
  }

  checkinClicked (e) {
    e.preventDefault();
    var patientId = e.currentTarget.dataset['id'];
    var vm = this;
    this.stimulate("Checkin#checkin", patientId);
  }

  checkinSuccess (element, reflex, noop, reflexId) {
    toastr.success('Patient Checked in..');
  }
}
