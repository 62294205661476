import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin,
  formatDateTime,
  formatDate,
  serializeFormData,
  toTitleCase,
} from "helpers";

export default class extends ApplicationController {
  static targets = ["patient", 'appointments', 'forms', 'status', 'dueDate', 'shareModal',
                    'notifyForm', 'labRequestId', 'clearStaff', 'applianceType', 'previousRequestsDiv', 'previousIncidents',
                    'dueDateText', 'restoration', 'toothNumber', 'requestNumber', 'requestIndex', 'action'
                  ];
  static values = {
    statuses: Array,
    appliances: Object,
  }
  connect() {
    StimulusReflex.register(this)
    var nestedCount = $("#lab_request_nested_count").val();
    var action;
    if(this.hasActionTarget) {
      action = this.actionTarget.value;
    }
    this.setPatientOnConnect(action);
    this.setStatusOnConnect();
    this.setDueDateAndToothNumberId(nestedCount);
    this.setRequestNumberOnConnect(action, nestedCount);
  }

  setRequestNumberOnConnect(action, nestedCount) {
    var vm = this
    if(action && action == "edit") {
      var nestedRequests = this.element.parentElement.querySelectorAll('.lr-nest');
      nestedRequests.forEach(function(request, index) {
        if(vm.element == request){
          if(vm.hasRequestNumberTarget) {
            vm.requestNumberTarget.value = index + 1;
            $(vm.requestIndexTarget).html(index + 1);
          }
        }
      })
    }
    else{
      if(this.hasRequestNumberTarget) {
        this.requestNumberTarget.value = nestedCount;
        $(this.requestIndexTarget).html(nestedCount);
      }
    }
  }

  setDueDateAndToothNumberId (nestedCount) {
    if(this.hasDueDateTextTarget && nestedCount) {
      this.dueDateTextTarget.id = `date-${nestedCount}`;
    }
    if(this.hasToothNumberTarget){
      this.toothNumberTarget.id = `tooth-lr-${nestedCount}`;
    }
  }

  setPatientOnConnect(action) {
    var patientValue = $('.patient-select-new-app').val();
    if(patientValue && this.hasPatientTarget) {
      this.setPatientValue(patientValue);
      if(action && action != "edit")
        this.togglePrevRequests(patientValue);
    }
  }

  setStatusOnConnect() {
    var fulfilmentValue = $('.fulfilment-tag').val();
    if(fulfilmentValue && this.hasStatusTarget){
      if(this.statusTarget.value == '') {
        this.setStatuses(fulfilmentValue);
      }
    }
  }

  restorationChanged() {
    if(this.restorationTarget.value == 'single' || this.restorationTarget.value == 'partial') {
      $(this.toothNumberTarget).attr('disabled', false);
      var requestNumber = this.requestNumberTarget.value;
      var id = `arch-lr-${requestNumber}`;
      $('#lab-request-archs').append(`<div id=${id}></div>`);
      if($('#arch-li').hasClass('d-none')){
        $('#arch-li').removeClass('d-none');
        $('#lab-request-archs').removeClass('d-none');
      }

      var applianceName = '';
      if(this.applianceTypeTarget.value != ''){
        applianceName = this.appliancesValue[this.applianceTypeTarget.value];
      }
      var params = {
        num: requestNumber,
        restoration: this.restorationTarget.value,
        appliance: applianceName
      }
      this.stimulate('LabRequest#render_arch', params);
    }
    else{
      $(this.toothNumberTarget).attr('disabled', true);
      var requestNumber = this.requestNumberTarget.value;
      this.toothNumberTarget.value = "";
      var divId = `#arch-lr-${requestNumber}`;
      if(divId) {
        $(divId).remove();
      }
      var restorationValues = $('#lab-request-pack-form')[0].querySelectorAll('.restoration-type');
      var showArch = false;
      restorationValues.forEach(function(restoration){
        if($(restoration).val() == 'partial' || $(restoration).val() == 'single')
          showArch = true;
      })
      if(!showArch) {
        $('#arch-li').addClass('d-none');
        $('#lab-request-archs').addClass('d-none');
      }
    }
  }

  togglePrevRequests(patientId) {
    if($('.classification-tag').val() == 'incident'){
      this.showPreviousRequestsField();
      if(this.previousIncidentsTarget.value == ''){
        this.fetchAndSetRequests(patientId);
      }
    }
  }

  setPatientValue(patientId) {
    this.patientTarget.value = patientId;
  }

  showPreviousRequestsField() {
    $(this.previousRequestsDivTarget).removeClass('d-none');
    $(this.previousIncidentsTarget).attr('disabled', false);
  }

  removePreviousRequestsField() {
    $(this.previousRequestsDivTarget).addClass('d-none');
    $(this.previousIncidentsTarget).attr('disabled', true);
  }

  applianceTypeChanged () {
    $('.appliance-row').addClass('d-none');
    $('.marked').val(false);
    var applianceTypes = $('#lab-request-pack-form')[0].querySelectorAll('.appliance-type-tag');
    var applianceId;
    var applianceAttachments = [];
    var appliancesArray = [];
    applianceTypes.forEach(function(el) {
      applianceId = el.value;
      if(applianceId != "" && !(appliancesArray.includes(applianceId))) {
        appliancesArray.push(applianceId);
        var klass = $(`.app-type-${applianceId}`);
        klass.removeClass('d-none');
        $(klass[0].querySelectorAll('.marked')).val(true);
        var applianceBoxes = klass[0].querySelectorAll('.filled-data-point-box');
        applianceBoxes.forEach(function(attachmentbox) {
          $(attachmentbox).removeClass('d-none');
          applianceAttachments.push(attachmentbox);
        })
      }
    })

    var applianceAttachmentHash = {}
    applianceAttachments.forEach(function(attachment) {
      var name = $($(attachment.children)[0]).children('label.appliance-attachment-name')[0].textContent;
      if (name in applianceAttachmentHash){
        $(attachment).addClass('d-none');
        $($(attachment)[0].querySelectorAll('.marked')).val(false);
      }
      else {
        applianceAttachmentHash[name] = 0;
      }
    })

    var archAppliance = `#arch-appliance-${this.requestNumberTarget.value}`;
    if($(archAppliance) && parseInt(this.applianceTypeTarget.value)){
      $(archAppliance).html(toTitleCase(this.appliancesValue[this.applianceTypeTarget.value]));
    }
  }

  subtractLabRequestNested() {
    var count = parseInt($("#lab_request_nested_count").val()) - 1;
    $("#lab_request_nested_count").val(count);
    this.applianceTypeChanged();
    if(count == 1) {
      $('.lr-delete').addClass('d-none');
    }
    var requestNumber = this.requestNumberTarget.value;
    if(this.restorationTarget.value == 'single' || this.restorationTarget.value == 'partial') {
      var divId = `#arch-lr-${requestNumber}`;
      $(divId).remove();
    }
  }

  openNotifyModal (e) {
    e.preventDefault();
    e.stopPropagation();
    this.labRequestIdTarget.value = e.target.dataset['labId'];
    $(this.shareModalTarget).modal('show');
  }

  notifyUsers (e) {
    e.preventDefault();
    e.stopPropagation();
    var valid = (this.notifyFormTarget).reportValidity();

    if (!valid) return;

    var params = serializeFormData(this.notifyFormTarget);
    var vm = this;
    var labId = params['notify_lab_request[lab_request_id]'];
    var url = `/lab_requests/${labId}/notify`;
    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
          notify_params: params,
          staff_ids: params['notify_lab_request[staff_user_ids][]']
        },
        success: function (result) {
          toastr.success('Notified.');
          $(vm.shareModalTarget).modal('hide');
          (vm.clearStaffTarget).click();
          $(vm.notifyFormTarget)[0].reset();
          var element = $('.refresh-profile')[0];
          if (element) {
            element.click();
          }
        },
        error: function(result) {
          toastr.error('Something went wrong.');
        }
      }
    );
  }

  dueDateChanged () {
    var vm = this;
    var params = {
      'lab_request': {
        'due_date': (vm.dueDateTarget.value),
        'id': vm.dueDateTextTarget.id
      }
    }

    this.stimulate(`LabRequest#validate`, params);
  }

  fulfilmentChanged () {
    this.setStatuses();
  }

  toggleRequestState (e) {
    var vm = this;
    var id = e.target.dataset.id;
    var url = `/lab_requests/${id}/toggle_complete`;
    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {},
        success: function (result) {
          var elRefresh = $('.refresh-profile')[0];
          if (elRefresh) {
            elRefresh.click();
          }
          var rowId = `#row-${id}`;
          $(rowId).remove();
        },
        error: function(result) {
          toastr.error('Something went wrong.');
        }
      }
    );
  }

  setStatuses (fulfilmentValue) {
    var vm = this;
    var newStatuses = this.statusesValue.filter(function(s) {
      return (s.fulfilment == fulfilmentValue);
    })

    $(vm.statusTarget).empty();

    newStatuses.forEach(function (v) {
      var newOption = new Option(v.name, v.id, false, false);
      vm.statusTarget.append(newOption);
    });

    $(vm.statusTarget).val(null)
  }

  patientChanged () {
    var patientId = this.patientTarget.value;
    this.fetchAndSetAppointments(patientId);
    this.fetchAndSetForms(patientId);
    this.fetchAndSetRequests(patientId);
  }

  setRequestNumber(number) {
    var number = number.toString();
    if(this.hasRequestNumberTarget && this.requestNumberTarget.value != number) {
      if(this.restorationTarget.value == 'single' || this.restorationTarget.value == 'partial') {
        var archId = `#arch-lr-${this.requestNumberTarget.value}`;
        $(archId).attr('id', `arch-lr-${number}`);
        var archNum = `#arch-num-${this.requestNumberTarget.value}`;
        var restorationNum = `#arch-restoration-${this.requestNumberTarget.value}`;
        var applianceNum = `#arch-appliance-${this.requestNumberTarget.value}`;
        $(archNum).html(number);
        $(archNum).attr('id', `arch-num-${number}`);
        $(restorationNum).attr('id', `arch-restoration-${number}`);
        $(applianceNum).attr('id', `arch-appliance-${number}`);
        this.toothNumberTarget.id = `tooth-lr-${number}`;
      }
      this.dueDateTextTarget.id = `date-${number}`;
      $(this.requestIndexTarget).html(number);
      this.requestNumberTarget.value = number;
    }
  }

  fetchAndSetAppointments (patientId) {
    var vm = this;
    var url = `/calendars/tabular_index/`;
    if (isAdmin()) { url = '/admin' + url; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
          patient_id: [patientId],
          current_location: 'patient_profile'
        },
        success: function (result) {
          var appointments = JSON.parse(result.appointments)
          $(vm.appointmentsTarget).empty()

          if (!appointments || appointments.length == 0) return;

          appointments.forEach(function (v) {
            var name = `${v.title} - ${formatDateTime(v.start_time)}`;
            var newOption = new Option(name, v.id, false, false);
            vm.appointmentsTarget.append(newOption);
          });
          $(vm.appointmentsTarget).val(null)
        }
      }
    );
  }

  fetchAndSetForms (patientId) {
    var vm = this;
    var url = `/assigned_workflows`;
    if (isAdmin()) { url = '/admin' + url; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
          patient_id: [patientId],
          current_location: 'patient_profile',
          status: 'completed'
        },
        success: function (result) {
          var forms = JSON.parse(result.assigned_workflows)
          $(vm.formsTarget).empty()

          if (!forms || forms.length == 0) return;

          forms.forEach(function (v) {
            var name = `${v.custom_attributes.title} - ${formatDate(v.submmitted_at)}`;
            var newOption = new Option(name, v.id, false, false);
            vm.formsTarget.append(newOption);
          });
          $(vm.formsTarget).val(null)
        }
      }
    );
  }

  fetchAndSetRequests(patientId) {
    var vm = this;
    var url = `/lab_requests`;
    if (isAdmin()) { url = '/admin' + url; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
          patient_ids: [patientId],
          current_location: 'patient_profile',
        },
        success: function (result) {
          var requests = JSON.parse(result.lab_requests)
          $(vm.previousIncidentsTarget).empty()

          if (!requests || requests.length == 0) return;

          requests.forEach(function (v) {
            var name = `${v.code} - ${formatDate(v.due_date)}`;
            var newOption = new Option(name, v.id, false, false);
            vm.previousIncidentsTarget.append(newOption);
          });
          $(vm.previousIncidentsTarget).val(null)
        }
      }
    );
  }
}
