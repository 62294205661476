import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['sourceForm', 'sourceFields']
  static values = {
    fields: Array,
  }

  connect() {}

  sourceFormChanged (e) {
    var vm = this;
    var formId = vm.sourceFormTarget.value;

    $(vm.sourceFieldsTarget).empty()

    var fields = vm.fieldsValue.filter(function (field) {
      if (field.workflow_id == formId) { return field; }
    })

    fields.forEach(function (v) {
      var newOption = new Option(v.name, v.id, false, false);
      vm.sourceFieldsTarget.append(newOption);
    });
  }
}
