import { Controller } from "stimulus"
import {
  isAdmin
} from "helpers";


export default class extends Controller {
  static targets = ['treatmentPlanId', 'patientPortion', 'subTreatment', 'medicalCode', 'price', 'patientPortion', 'toothNumber', 'diagnosisCodes', 'sides']

  static values = {
    subTreatments: Array,
    newRecord: String,
    duplicate: Boolean,
  }

  connect() {
    this.element[this.identifier] = this

    var id = $(this.element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelectorAll('.treatment-plan-id')).val();
    if (!id) {
      id = $(this.element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.querySelectorAll('.treatment-plan-id')).val();
    }
    if (this.newRecordValue == 'true') {
      this.setCdtCodes(id);
      this.scrollToRow();
    }
    this.setMode();
  }

  getMode () {
    var val =  $('.toggle-checkbox')[0].checked;
    if (val) {
      return 'medical';
    } else {
      return 'patient';
    }
  }

  setMode () {
    var val =  $('.toggle-checkbox')[0].checked;
    if (val) {
      $('.medical-column').removeClass('d-none');
    } else {
      $('.medical-column').addClass('d-none');
    }
  }

  scrollToRow () {
    var vm = this;
    setTimeout(function (vm) {
      vm.element.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
        }
      );
    }, 100, vm);
  }

  duplicateOptions (dest, src) {
    $(dest).val(null);
    var options = src.innerHTML;
    var options = dest.innerHTML + options;
    dest.innerHTML = options;
  }

  duplicate(params) {
    var src = params.srcCtrl;

    this.subTreatmentsValue = src.subTreatmentsValue;
    $(this.subTreatmentTarget).val(src.subTreatmentTarget.value).trigger('change');
    $(this.subTreatmentTarget).trigger('change');
    this.setSubTreatments();
    $(this.subTreatmentTarget).val(src.subTreatmentTarget.value).trigger('change');


    this.patientPortionTarget.value = src.patientPortionTarget.value;
    this.duplicateOptions(this.medicalCodeTarget, src.medicalCodeTarget)
    this.medicalCodeTarget.value = src.medicalCodeTarget.value;
    this.priceTarget.value = src.priceTarget.value;
    this.medicalCodeTarget.value = src.medicalCodeTarget.value;
    this.toothNumberTarget.value = src.toothNumberTarget.value;
    $(this.diagnosisCodesTarget).val($(src.diagnosisCodesTarget).val())
    this.sidesTarget.value = src.sidesTarget.value;
    this.toggleSides(this.medicalCodeTarget.value);

    $(this.medicalCodeTarget).trigger('change');
    $(this.diagnosisCodesTarget).trigger('change');

    var subTreatment = this.subTreatmentsValue.find(function(s) {
      return (s.id == src.subTreatmentTarget.value);
    })
    if (subTreatment.treatment_intention == 'patient') {
      $(this.medicalCodeTarget).removeAttr('readonly');
    } else {
      (this.medicalCodeTarget).setAttribute('readonly', true);
    }

    this.scrollToRow();

    this.updateGlobalPrice();
  }

  updateGlobalPrice () {
    var parentNode = $(this.element.closest('.master-treatment-form'))[0];
    var parentCtrl = this.application.getControllerForElementAndIdentifier(parentNode, 'master-treatment');
    parentCtrl.priceChanged();
  }

  sidesChanged (e) {
    var vm = this;
    var sides = parseInt(e.target.value);

    if (!vm.subTreatmentTarget.value || vm.subTreatmentTarget.value == '' || !sides) { return; }
    var subTreatment = this.subTreatmentsValue.find(function(s) {
      return (s.id == vm.subTreatmentTarget.value);
    })

    var price = 0;
    var mode = this.getMode();
    if (mode == 'medical') {
      price = subTreatment.cpt_price;
    } else {
      price = subTreatment.cdt_price;
    }

    vm.priceTarget.value = price * sides;
  }

  medicalCodeChanged (e) {
    this.toggleSides(e.target.value);

  }

  toggleSides (value) {
    if (value == '') {
      (this.sidesTarget).setAttribute('readonly', true);
    }
    else {
      $(this.sidesTarget).removeAttr('readonly');
    }
  }

  setCdtCodes (id) {
    var vm = this;
    var url = `/treatment_plans/${id}/sub_treatments/`;

    if (isAdmin()) { url = '/admin' + url; }

    $.ajax({
      url: url,
      method: 'GET',
      data: {},
      dataType: "json",
      success: (result) => {
        var res = JSON.parse(result.result);
        vm.subTreatmentsValue = JSON.parse(res.sub_treatments);
        vm.setSubTreatments();
      },
      error: (error) => {
        toastr.error('Something went wrong', error);
        return;
      },
    });
  }

  setSubTreatments() {
    var vm = this;
    var subTreatments = this.subTreatmentsValue;

    if (!subTreatments || subTreatments.length == 0 || vm.subTreatmentTarget.value != '') return;

    subTreatments.forEach(function (v) {
      var name = `(${v.custom_attributes.cdt_code_code}) - ${v.custom_attributes.cdt_code_name}`;
      var newOption = new Option(name, v.id, false, false);

      vm.subTreatmentTarget.append(newOption);
    });
  }

  subTreatmentChanged (e) {
    var subTreatment = this.subTreatmentsValue.find(function(s) {
      return (s.id == e.target.value);
    });

    this.setMedicalCode(subTreatment)
    this.setPrices(subTreatment);
    this.toggleSides(this.medicalCodeTarget.value);
    this.updateGlobalPrice();
  }

  getSubTreatmentPrice (subTreatment) {
    var mode  = this.getMode();
    var price = 0;
    if (mode == 'medical') {
      price = subTreatment.cpt_price;
    } else {
      price = subTreatment.cdt_price;
    }

    if (mode == 'medical' && subTreatment.treatment_intention == 'patient') {
      price = subTreatment.cdt_price;
    }

    return price;
  }

  resetSubTreatment () {
    var id = this.subTreatmentTarget.value;
    var subTreatment = this.subTreatmentsValue.find(function(s) {
      return (s.id == id);
    });
    this.setPrices(subTreatment);
    console.log('coolllll', this.subTreatmentTarget.value);
  }

  setPrices(subTreatment) {
    var price = this.getSubTreatmentPrice(subTreatment);
    var mode = this.getMode();
    console.log('price: ', price);
    console.log(subTreatment);

    this.priceTarget.value = price;
    this.patientPortionTarget.value = price;
    if (subTreatment.custom_attributes.medical_code_code && mode != 'patient') {
      this.patientPortionTarget.value = 0
    }
  }

  setMedicalCode (subTreatment) {
    if (!subTreatment)
      return;

    if (subTreatment.treatment_intention == 'patient') {
      $(this.medicalCodeTarget).removeAttr('readonly');
    } else {
      (this.medicalCodeTarget).setAttribute('readonly', true);
      $(this.medicalCodeTarget).empty()
      var newOption = new Option(subTreatment.custom_attributes.medical_code_name, subTreatment.custom_attributes.medical_code_id, false, false);
      this.medicalCodeTarget.append(newOption);
    }
    $(this.medicalCodeTarget).val(subTreatment.custom_attributes.medical_code_id).trigger('change');
  }
}
