import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = []
  static values = {
    elementReference: String
  }

  connect() {
    var vm = this;
    CKEDITOR.plugins.addExternal("autotag", "/assets/ckeditor/plugins/autotag/", "plugin.js");
    CKEDITOR.plugins.addExternal("autotag", "/assets/ckeditor/plugins/autotag/", "plugin.js");
    CKEDITOR.plugins.addExternal("autocomplete", "/assets/ckeditor/plugins/autocomplete/", "plugin.js");
    CKEDITOR.plugins.addExternal("textwatcher", "/assets/ckeditor/plugins/textwatcher/", "plugin.js");
    CKEDITOR.plugins.addExternal("textmatch", "/assets/ckeditor/plugins/textmatch/", "plugin.js");
    CKEDITOR.plugins.addExternal("placeholder", "/assets/ckeditor/plugins/placeholder/", "plugin.js");
    CKEDITOR.plugins.addExternal("oembed", "/assets/ckeditor/plugins/oembed/", "plugin.js");
    CKEDITOR.addCss('.cke_editable img { max-width: 100% !important; height: auto !important; }');

    if (!this.elementReferenceValue) {
      this.elementReferenceValue = 'ckme';
    }
    StimulusReflex.register(this)
    CKEDITOR.replace(vm.elementReferenceValue, {
      extraPlugins: 'oembed,autotag,autocomplete,textwatcher,textmatch,placeholder',
      autoEmbed_widget: 'customEmbed',
      filebrowserBrowseUrl: '/ckeditor/attachment_files',
      filebrowserImageBrowseLinkUrl: '/ckeditor/pictures',
      filebrowserImageBrowseUrl: '/ckeditor/pictures',
      filebrowserImageUploadUrl: '/ckeditor/pictures?command=QuickUpload&type=Images',
      filebrowserUploadUrl: '/ckeditor/attachment_files?command=QuickUpload&type=Files',
    } );

    CKEDITOR.addCss('.cke_editable img { max-width: 100% !important; height: auto !important; }');

  }
}
