import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin
} from "helpers";

export default class extends ApplicationController {
  static targets = ['form'];
  static values = {
    fileData: Array
  };

  connect() {
    StimulusReflex.register(this);
    console.log("here");
  }

  removeRow(e) {
    var index = e.target.parentElement.parentElement.dataset['rowIndex'];
    this.stimulate("Product#render_delete_modal", index);
  }

  renderDeleteModalSuccess(element, reflex, noop, reflexId) {
    $('#productRowModal').modal('show');
  }

  removeConfirmedRow(e){
    var index = e.target.dataset.index;
    $(`.row-${index}`).remove();
    $('#productRowModal').modal('hide');
  }

  showChanges() {
    if(!this.formTarget.reportValidity()){
      return false;
    }
    var row;
    var changesHash = {}
    var fileDataHash = {}
    var addData;
    this.fileDataValue.forEach(function(el, index){
      addData = false;
      row = $(`.row-${index}`);

      if(row.length != 0){
        if(!el.variant){
          el.variant = ''
        }
        if(!el.attributes){
          el.attributes = ''
        }

        if($(`#product_name${index}`).val() != el.product.name)
          addData = true;
        else if($(`#product_cost${index}`).val() != el.product.cost)
          addData = true;
        else if($(`#product_type${index}`).val() != el.product.product_type)
          addData = true;
        else if($(`#product_variant${index}`).val() != el.variant)
            addData = true;
        else if($(`#product_attributes${index}`).val() != el.attributes)
          addData = true;

        if(addData){
          changesHash[`${index}`] = {
            product_name: $(`#product_name${index}`).val(),
            product_cost: parseFloat($(`#product_cost${index}`).val()),
            product_type: $(`#product_type${index}`).val(),
            variant: $(`#product_variant${index}`).val(),
            attributes: $(`#product_attributes${index}`).val()
          }

          fileDataHash[`${index}`] = {
            product_name: el.product.name,
            product_cost: el.product.cost,
            product_type: el.product.product_type,
            variant: el.variant,
            attributes: el.attributes
          }
        }
      }
    });

    if($.isEmptyObject(changesHash)){
      $(this.formTarget).submit();
    }
    else{
      var params = {
        changes_hash: changesHash,
        file_data_hash: fileDataHash
      }
      this.stimulate("Product#render_changed_product_rows", params);
    }
  }

  renderChangedProductRowsSuccess(element, reflex, noop, reflexId) {
    $('#productChangesModal').modal('show');
    $('#productChangesModal')[0].style.display = 'block';
  }

  checkPresence(e) {
    var id = e.target.id
    var index = e.target.dataset.indexNumber
    var field_name;
    var field_hash;

    if(id.includes("product_name")){
      field_name = "name";
      if($(`#product_name${index}`).val() == ''){
        $(`#new_product${index}`).val('');
        $(`#product_template_id${index}`).val('');
        return;
      }
    }
    else if(id.includes("product_variant")){
      field_name = "variant";
      if($(`#product_variant${index}`).val() == ''){
        if($(`#product_attributes${index}`).val() == '') {
          $(`#product_variant${index}`).attr('required', false);
          $(`#product_attributes${index}`).attr('required', false);
        }
        $(`#new_variant${index}`).val('');
        $(`#new_attribute${index}`).val('');
        return;
      }
      else{
        $(`#product_variant${index}`).attr('required', true);
        $(`#product_attributes${index}`).attr('required', true);
      }
    }
    else if(id.includes("product_attributes")){
      field_name = "attribute";
      if($(`#product_attributes${index}`).val() == ''){
        if($(`#product_variant${index}`).val() == ''){
          $(`#product_variant${index}`).attr('required', false);
          $(`#product_attributes${index}`).attr('required', false);
        }
        $(`#new_attribute${index}`).val('');
        return;
      }
      else {
        $(`#product_variant${index}`).attr('required', true);
        $(`#product_attributes${index}`).attr('required', true);
      }
    }
    else if(id.includes("product_type")){
      field_name = "product_type";
    }
    else if(id.includes("short_name")){
      field_name = "short_name"
      field_hash = {
        short_name: $(`#short_name${index}`).val()
      }
    }

    if(field_name == 'name' || field_name == 'product_type'){
      field_hash = {
        product_name: $(`#product_name${index}`).val(),
        product_type: $(`#product_type${index}`).val(),
      }
    }

    if(field_name == 'variant' || field_name == 'attribute'){
      field_hash = {
        variant: $(`#product_variant${index}`).val(),
        attribute: $(`#product_attributes${index}`).val()
      }
    }

    var url = `/products/check_product`;
    if (isAdmin()) { url = '/admin' + url; }

    $.ajax(
      {
        url: url,
        type: "GET",
        data: {
          field_hash: field_hash,
          index: index,
          field_name: field_name
        }
      }
    );
  }

  submitBulkProductsForm(e) {
    e.preventDefault();
    $("#bulk-form-btn").click();
  }

  showStockChanges() {
    if(!this.formTarget.reportValidity()){
      return false;
    }
    var row;
    var changesHash = {}
    var fileDataHash = {}
    var addData;
    this.fileDataValue.forEach(function(el, index){
      addData = false;
      row = $(`.row-${index}`);
      if(row.length != 0){
        if(!el.variant){
          el.variant = ''
        }
        if(!el.attributes){
          el.attributes = ''
        }
        if(!el.serial_number){
          el.serial_number = ''
        }


        if($(`#short_name${index}`) && $(`#short_name${index}`).val() != el.location)
          addData = true;
        else if($(`#product_name${index}`) && $(`#product_name${index}`).val() != el.product.name)
          addData = true;
        else if($(`#product_variant${index}`) && $(`#product_variant${index}`).val() != el.variant)
            addData = true;
        else if($(`#product_attributes${index}`) && $(`#product_attributes${index}`).val() != el.attributes)
          addData = true;
        else if($(`#product_quantity${index}`) && $(`#product_quantity${index}`).val() != el.quantity)
          addData = true;
        else if($(`#serial_number${index}`) && $(`#serial_number${index}`).val() != el.serial_number) {
          addData = true;
        }
        // else if($(`#location_full_name${index}`) && $(`#location_full_name${index}`).val() != '')
        //   addData = true

        if(addData){
          changesHash[`${index}`] = {
            location_short: $(`#short_name${index}`).val(),
            product_name: $(`#product_name${index}`).val(),
            variant: $(`#product_variant${index}`).val(),
            attributes: $(`#product_attributes${index}`).val(),
            quantity: $(`#product_quantity${index}`).val(),
            serial_number: $(`#serial_number${index}`).val()
          }

          fileDataHash[`${index}`] = {
            location_full_name: '-',
            location_short: el.location,
            product_name: el.product.name,
            variant: el.variant,
            attributes: el.attributes,
            quantity: el.quantity,
            serial_number: el.serial_number
          }
        }
      }
    });

    if($.isEmptyObject(changesHash)){
      $(this.formTarget).submit();
    }
    else{
      var params = {
        changes_hash: changesHash,
        file_data_hash: fileDataHash
      }
      this.stimulate("Product#render_changed_stock_rows", params);
    }
  }

  renderChangedStockRowsSuccess(element, reflex, noop, reflexId) {
    $('#stockChangesModal').modal('show');
    $('#stockChangesModal')[0].style.display = 'block';
  }
}
