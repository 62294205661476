import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  formatFloat
} from "helpers";

export default class extends ApplicationController {
  static targets = ["quantity", 'productId', 'taskId', 'productSelect', 'quantity', 'bill', 'unitPrice', 'vendorId', 'unit', 'noOfUnits', 'perPackQuantity'];
  static values = {
    products: Array,
    vendors: Array,
    vendorProducts: Array,
  }

  connect() {
    StimulusReflex.register(this)
  }

  getProduct () {
    var productId = this.productSelectTarget.value;
    var product = this.productsValue.find(function(product) {
      if (product.id == productId)
        return product
      });

    return product;
  }

  productChanged () {
     var product = this.getProduct();

    if (product) {
      this.unitPriceTarget.value = product.unit_price;
      this.noOfUnitsTarget.value = 1;
    }

    if (this.hasVendorProductsValue) {
      this.setVendors(product.id);
    }
    this.setUnitOfMeasure(product);
    this.calculateOrderBill();
  }

  calculateOrderBill () {
    this.billTarget.value = 0;
    var amount = this.noOfUnitsTarget.value;
    var product = this.getProduct();
    var perPackQuantity = this.perPackQuantityTarget.value;

    if (product && amount) {
      var price = this.unitPriceTarget.value * amount
      this.billTarget.value = (price.toFixed(3));

      if (this.unitTarget.value == 'single') {
        this.quantityTarget.value = (amount);
      } else {
        this.quantityTarget.value = (amount * perPackQuantity).toFixed(2);
      }
    }
  }

  setUnitOfMeasure (product) {
    $(this.unitTarget).select2("trigger", "select", {
      data: { id: product.unit_of_measure }
    });
  }

  setVendors (productId) {
    var vm = this;
    $(vm.vendorIdTarget).empty()

    var vendorIds = this.vendorProductsValue.filter(function(vp) {
      if (vp.product_id == productId)
        return vp.vendor_id
    });

    if (!vendorIds) return;

    var vendorIdsArr = vendorIds.map(a => a.vendor_id);
    var vendors = this.vendorsValue.filter(function(vendor) {
      if (vendorIdsArr.includes(vendor.id)) {
        return vendor;
      }
    });

    vendors = vendors.filter((v, i, a) => a.indexOf(v) === i);

    vendors.forEach(function (v) {
      var newOption = new Option(v.name, v.id, false, false);
      vm.vendorIdTarget.append(newOption);
    });

  }

  unitChanged () {
    var product = this.getProduct();
    if (this.unitTarget.value != 'single') {
      this.perPackQuantityTarget.disabled = false;
      this.perPackQuantityTarget.vlaue = 1;
      if (product) {
        this.perPackQuantityTarget.value = product.per_pack_quantity;
      }
    } else {
      this.perPackQuantityTarget.disabled = true;
      this.perPackQuantityTarget.vlaue = 1;
      this.perPackQuantityTarget.value = 0;
    }
    this.calculateOrderBill()
  }

  create(e) {
    e.preventDefault();
    var vm = this;
    var params = {
      'inventory_order': {
        'quantity': vm.quantityTarget.value,
        'product_id': vm.productIdTarget.value,
        'task_id': vm.taskIdTarget.value,
      }
    }

    if (vm.hasVendorIdTarget) {
      params['vendor_id'] = vm.vendorIdTarget.value;
    } else {
      params['vendor_id'] = null;
    }
    this.stimulate(`Order#create`, params);
  }

  createSuccess (element, reflex, noop, reflexId) {
    toastr.success('Order created..');
  }

  createError (element, reflex, noop, reflexId) {
    toastr.error('Something went wrong..');
  }
}
