import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['elapsedTime']

  connect() {
    $(document).ready(function () {
      if (window.IsDuplicate()) {
        // alert("This step is already opened in another tab.");
        // window.location.href = "/";
      }
    });

    this.load();
    if (this.elapsedTimeTarget) {
      this.startRefreshing()
    }
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, 1000)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  load() {
    var _self = this;
    var val = parseInt(_self.elapsedTimeTarget.value) + 1
    _self.elapsedTimeTarget.value = val;
  }

  disconnect() {
    this.stopRefreshing()
  }
}
