import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  static targets = ["archNumber"];
  
  connect() {   
  this.tooth_arr = [];
  }

  toggleToothNumber(e) {
    var action = $('#request_action').val();
    if(action && action != 'edit') {
      var vm = this;
      var archNumber = parseInt($(vm.archNumberTarget).html());
      var toothId = `#tooth-lr-${archNumber}`;
      var toothNumber = e.target.dataset.key;

      if(!$(e.target).hasClass('colored')){
        $(e.target).addClass('colored');
        $(e.target).css('fill', 'red');
        this.tooth_arr.push(toothNumber);
        $(toothId).val(JSON.stringify(this.tooth_arr));
      } 
      else{
        $(e.target).removeClass('colored');
        $(e.target).css('fill', '#FFFFFF');
        var tIndex = this.tooth_arr.indexOf(toothNumber);
        if(tIndex > -1){
          this.tooth_arr.splice(tIndex, 1);
          $(toothId).val(JSON.stringify(this.tooth_arr));
        }
      }
    }
  }
}
