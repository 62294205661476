import jquery from 'jquery';
import $ from 'jquery';
global.$ = jQuery;

import pace from "js/pace.min";
import "js/duplicate";


import 'bootstrap';
import "@fortawesome/fontawesome-free/js/all";

global.toastr = require("toastr")

toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": false,
  "progressBar": true,
  "positionClass": "toast-bottom-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "100",
  "hideDuration": "100",
  "timeOut": "3000",
  "extendedTimeOut": "100",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

import metismenu from "metismenu";
import slimScroll from "jquery-slimscroll";

import 'select2'
import 'select2/dist/css/select2.css'

global.dragula = require("dragula")
global.autoScroll = require('dom-autoscroller')
import 'dragula/dist/dragula.css'

import 'smartwizard'
import 'smartwizard/dist/css/smart_wizard.css'

import SignaturePad from 'signature_pad';
window.SignaturePad = SignaturePad;

import 'gojs'

require("chart.js")
require("flatpickr/dist/flatpickr.css")
import flatpickr from "flatpickr";
import moment from 'moment-timezone/moment-timezone';
window.moment = moment;

import swal from 'sweetalert';
window.swal = swal;