import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  triggerChange
} from "helpers";


export default class extends ApplicationController {
  static targets = ['defaultValue', 'mode', 'field', 'actualField', 'replicaField']
  static values = {
    mode: String,
    selectedDateTime: String,
    skipAdjustment: Boolean,
    enableChangeOnEmpty: Boolean
  }

  connect() {
    console.log('this.enableChangeOnEmptyValue', this.enableChangeOnEmptyValue)
    if (!this.enableChangeOnEmptyValue) {
      this.enableChangeOnEmptyValue = false;
    }
    var vm = this;
    var defaultDate = this.selectedDateTimeValue;
    var dateFormat = "m/d/Y";
    var enableTime = false;
    enableTime = this.modeValue == 'datetime';

    if (defaultDate) {
      defaultDate = defaultDate.replace('-', '/').replace('-', '/')
      var convertedDate = new moment(defaultDate).tz('America/New_York').format('MM/DD/YYYY HH:mm');
      defaultDate = new Date(convertedDate);
      defaultDate.setDate(defaultDate.getDate() + 0.5)
    }

    if (enableTime) {
      dateFormat = "m/d/Y H:i";
    }

    flatpickr(vm.fieldTarget, {
      enableTime: enableTime,
      time_24hr: true,
      defaultDate: defaultDate,
      focus: false,
      dateFormat: dateFormat,
      disableMobile: true,
      onClose: function(selectedDates, selectedDate) {
        if (vm.hasActualFieldTarget) {
          debugger;
          if (selectedDates[0]) {
            vm.actualFieldTarget.value = moment(selectedDates[0]).parseZone().tz("America/New_York", true).toISOString(true) // => "2019-08-01T05:00:00.000+01:00"
          } else {
            vm.actualFieldTarget.value = null;
          }
          triggerChange((vm.actualFieldTarget))
        }
        if (vm.hasReplicaFieldTarget) {
          vm.replicaFieldTarget.value = vm.fieldTarget.value;

          triggerChange((vm.replicaFieldTarget))
        }
      }
    });
  }
}

