import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  formatAmount,
  toTitleCase,
} from "helpers";

export default class extends ApplicationController {
  static targets = ['receiptForm', 'subTotal', 'amount', 'name', 'transactionType']

  connect() {
    StimulusReflex.register(this)
  }

  formatDecimals (e) {
    var parseCommas = e.currentTarget.value.replaceAll(',', '')
    var parsedPrice = parseFloat(parseCommas).toFixed(2)
    e.currentTarget.value = formatAmount(parsedPrice)
  }

  setRefundPrice(e) {
    var field = $(e.target).closest('.nested-fields').find('input.transaction-amount')
    if (field.hasClass('revenue-amount')) {
      field.removeClass('revenue-amount')
      field.addClass('refund-amount');
    }
    else {
      field.removeClass('refund-amount')
      field.addClass('revenue-amount');
    }

    this.setTotal()
  }

  closePreviewModal () {
    $('#previewModal').hide();
  }

  setTotal() {
    var revenueAmount = this.receiptFormTarget.querySelectorAll('.revenue-amount');
    var refundAmount = this.receiptFormTarget.querySelectorAll('.refund-amount');
    var price = 0;

    if (revenueAmount.length > 0) {
      revenueAmount.forEach(function(el){
        var parseCommas = el.value.replaceAll(',', '')
        var parsedPrice = parseFloat(parseCommas);
        if (parsedPrice > 0) {
          price += parsedPrice;
        }
      });
    }

    if (refundAmount.length > 0) {
      refundAmount.forEach(function(el){
        var parseCommas = el.value.replaceAll(',', '')
        var parsedPrice = parseFloat(parseCommas);
        if (parsedPrice > 0) {
          price -= parsedPrice;
        }
      });
    }

    price = parseFloat(price).toFixed(2);
    this.subTotalTarget.value = formatAmount(price)
  }

  openPreviewModal(e) {
    if(!this.receiptFormTarget.reportValidity()){
      return false;
    }
    var patient_id = e.target.dataset['patientId']
    this.stimulate('Receipt#bill_to_block', patient_id)
    this.stimulate('Event#preview_modal_opened', patient_id)
    $('#previewModal').modal('show');
    $('#previewModal')[0].style.display = 'block';

    var row = '';
    var x = 0;
    while( x < parseInt($('#transactions-count').html())) {
      row += "<tr>"
      row += "<div class='row'>"
      row += "<div class='form-control col-md-3'><td class='col-4'>" + $($('.transaction-name')[x]).val() + "</td></div>"
      row += "<div class='form-control col-md-3'><td class='col-4'>" + toTitleCase($($('.transaction-type')[x]).val()) + "</td></div>"
      row += "<div class='form-control col-md-3'><td class='col-4'>$ " + $($('.transaction-amount')[x]).val() + "</td></div>"
      row += "</div></tr>"
        x = x + 1;
    }
    $('#preview-receipt-body').html(row);
    $('#creator-name').html($("#creator_name")[0].value);
    $('#receipt-sub-total').html(this.subTotalTarget.value);
    $('#receipt-total').html(this.subTotalTarget.value);
    $('.total').html(this.subTotalTarget.value);
    $('.receipt-confirmation-message').removeClass('d-none');
  }

  addTransactionNumber() {
    var count = parseInt($('#transactions-count').html()) + 1;
    $('#transactions-count').html(count);
    var vm = document.querySelectorAll('.nested-fields')
    vm = vm[vm.length - 1]
    setTimeout(function() {
      vm.scrollIntoView(
        {
          behavior: "smooth", block: "center"
        });
    }, 100, vm);
  }

  subtractTransactionNumber() {
    var count = parseInt($('#transactions-count').html()) - 1;
    $('#transactions-count').html(count);
  }

  submitForm(){
    $('#submit-receipt').click();
  }

  sendReceipt () {
    $('#previewModal').hide();
    $('#receiptModal').hide();
    $('#sendReceiptModal').modal("show");
    $('#sendReceiptModal')[0].style.display = 'block';
  }

  clearAmount(e) {
    $(e.target).closest('.nested-fields').find('input.transaction-amount').val("");
    this.setTotal();
  }

  checkOnlyNumber(e) {
    var reg = new RegExp(/^\d*\.?\d*$/);
    if(!reg.test(e.currentTarget.value)) {
      e.currentTarget.value = ''
    }
  }
}
