import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  isAdmin
} from "helpers";

export default class extends ApplicationController {
  static targets = ['placeholder', 'forceDefaultValues', 'hiddenInput', 'tempOptions', 'queryUrl']
  static values = {
    createTags: Boolean,
    attachDropdown: Boolean,
    enableSearch: Boolean,
    queryUrl: String,
    dropdownElement: String,
    clickable: Boolean,
    click: Array,
    extraParams: Object
  }

  connect() {
    var vm = this;
    this.elementIdentifier = this.element.dataset.identifier;
    this.initFixedSelect(this.elementIdentifier);
    this.initBubbleClicks();

  }

  initBubbleClicks () {
    var vm = this;
    if (this.clickableValue) {
      $('body').on('click', '.linkable-bubble', function(event) {
        var url = event.target.parentElement.dataset['link']
        if (isAdmin()) {
          url = '/admin/' + url;
        }
        url = location.protocol + '//' + location.host + '/' + url ;
        window.open(url,'_blank');
      })
    }
  }

  setExtraParams (extraParams) {
    this.extraParamsValue = extraParams;
  }

  initFixedSelect(identifier) {
    var el =$('.'+identifier);
    var parentEl = el[0].parentElement
    if ((parentEl).querySelector('.select2-container')){
      (parentEl).querySelector('.select2-container').remove();
    }

    var tags = this.createTagsValue;
    var placeholder = '';
    var vm = this;
    var ajaxRequest = vm.buildAjaxRequest(vm);
    if (this.element.dataset) {
      placeholder = this.element.dataset.placeholder;
    } else {
      placeholder = 'Search here..'
    }

    if (this.attachDropdownValue && $(this.dropdownElementValue)[0]) {
      var dropdownParent = $(this.dropdownElementValue);
    } else {
      var dropdownParent = null;
    }

    vm.select2 = $('.'+identifier).select2({
      closeOnSelect: true,
      allowClear: true,
      placeholder: placeholder,
      tags: tags,

      dropdownParent: dropdownParent,
      theme: "material",
      templateSelection: function (data, container) {
        if (vm.clickableValue) {
          $(container).addClass('linkable-bubble');
          var found = vm.clickValue.find(function (el) { return el.id.toString() == data.id })

          $(container)[0].dataset['link'] = found.link;
        }

        return data.text;
      },
      templateResult: function(data) {
        var $result = data.text;

        if (data.id == data.text && vm.createTagsValue) {
          $result = `Create '${data.text}'`;
        }

        return $result;
      },
      ajax: ajaxRequest
    });

    vm.select2.on('select2:select select2:unselect', function (e) {
      var data = e.params.data;
      var el = $('.'+identifier)[0];
      var ev = document.createEvent('Event');
      var elm = e.params.data.element;
      var $elm = $(elm);
      var $t = $(this);
      $t.append($elm);
      $t.trigger('change.select2');
      var selectedItems = $(this).select2('data');
      selectedItems = selectedItems.map(function (value, index, array) { return value.id; });
      var parent = this.parentElement;
      var hiddenInput = $(parent).find("[data-target='" + 'select2.hiddenInput' + "']")[0];
      if (hiddenInput) {
        hiddenInput.value = selectedItems.join(',')
      }
      ev.initEvent('change', true, false);
      el.dispatchEvent(ev);
    });

    vm.select2.on('paste', function(e) {
      var data = e.originalEvent.clipboardData.getData('Text');
      $(this).val($(this).val() + '\t' + data.replace(/\n/g, '\t') + '\t');
      return false;
    });

    if (this.hasForceDefaultValuesTarget) {
      var vals = (this.forceDefaultValuesTarget.value).split(',')
      if (vals != '') {
        for (let i=0; i<vals.length; i++) {
          $('.'+identifier).select2("trigger", "select", {
            data: { id: vals[i] }
          });
        }
      }
    }

    if (this.hasHiddenInputTarget) {
      var alreadySelected = vm.select2.select2('data')
      alreadySelected = alreadySelected.map(function (value, index, array) { return value.id; });
      $(this.hiddenInputTarget).val(alreadySelected).trigger('change');
    }
  }

  buildAjaxRequest (vm) {
    var vm = vm;
    if (!vm.enableSearchValue) return null;

    var request = {
      url: vm.queryUrlValue,
      vm: vm,
      abc: 'def',
      data: function (params) {
        var query = {
          search: params.term,
          type: 'public',
          extraParams: vm.extraParamsValue
        }
        return query;
      },

      processResults: function (data) {
        var results = JSON.parse(data.results)
        return {
          results: $.map(results, function (item) {
            return {
              text: item.name,
              id: item.id
            }
          })
        };
      },
    }

    return request;
  }

  clear (e) {
    e.preventDefault();
    $('.'+ this.elementIdentifier).val(null).trigger('change');
  }

  selectAll (e) {
    e.preventDefault();
    var allOptions = this.element.querySelectorAll('option');
    var ids = [];
    for (let i = 0; i < allOptions.length; i++) {
      ids.push(allOptions[i].value);
    }
    $('.'+ this.elementIdentifier).val(ids).trigger('change');
  }
}
