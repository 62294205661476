import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import dragula from "dragula"
import "dragula/dist/dragula.css"

export default class extends ApplicationController {
  connect() {
    this.drake = dragula([this.element], {
      moves: (el, container, handle) => {
        return handle.classList.contains('drag-handle'); // Ensure only elements with 'drag-handle' can initiate a drag
      }
    });

    this.drake.on('drop', (el, target, source, sibling) => {
      this.updatePositions();
    });
  }

  updatePositions() {
    let stages = this.element.querySelectorAll('.pipeline-stage');
    stages.forEach((stage, index) => {
      let positionField = stage.querySelector('.stage-position-field');
      if (positionField) {
        positionField.value = index + 1;
      }
    });
  }

  disconnect() {
    this.drake.destroy();
  }
}

