import { Controller } from "stimulus"
import {
  isAdmin,
} from "helpers";
export default class extends Controller {
  static targets = ['email', 'phone', 'fax', 'faxDiv', 'emailDiv', 'phoneDiv', 'preferenceVal', 'recipientType', 'redirectBody', 'body', 'gptBodyResponse', 'scheduledAt']
  static values = {
    templates: Array,
    // abc: Object
  }
  connect() {
    var checkedElements = $("input[name='patient_email[recipient_info_attributes][mode_of_sending]']:checked");

    if (this.hasPreferenceValTarget){
      this.setPrefVisibility(this.preferenceValTarget.value);
    }

    if (checkedElements.length > 0){
      this.setPrefVisibility(checkedElements[0].value);
      $(checkedElements)[0].click()
    }

  }

  sendingOptionChanged (e) {
    this.setPrefVisibility(e.target.value);
  }

  letterTemplateChanged (e) {
    var vm = this;
    var target = e.target;
    var patientId = target.dataset['patientId'];
    var templateId = target.value;
    console.log('templateIdfor Letter', templateId);
    var url = `/patients/${patientId}/patient_emails/extract_template`;
    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
          patient_id: patientId,
          letter_template_id: templateId
        },
        success: function (result) {
          var body = JSON.parse(result.result);
          vm.redirectBodyTarget.value = body;
          CKEDITOR.instances['ckme'].setData(body);
          toastr.success('Template Imported.');
        },
        error: function(result) {
          toastr.error('Something went wrong.');
        }
      }
    );
  }

  letterMessageTemplateChanged (e) {
    var vm = this;
    var target = e.target;
    var patientId = target.dataset['patientId'];
    var templateId = target.value;
    console.log('templateIdfor Message', templateId);
    var url = `/patients/${patientId}/patient_emails/extract_message_template`;
    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
          patient_id: patientId,
          letter_message_template_id: templateId
        },
        success: function (result) {
          var body = JSON.parse(result.result);
          vm.bodyTarget.value = body;
          CKEDITOR.instances['msgbod'].setData(body);
          toastr.success('Message Template Imported.');
        },
        error: function(result) {
          toastr.error('Something went wrong.');
        }
      }
    );
  }

  recipientTypeChanged (e) {
    var val = e.target.value;
    if (val == 'patient') {
      $('.fax-mode').addClass('d-none');
      $('.email-mode').click();
    } else if (val == 'pcp') {
      $('.fax-mode').removeClass('d-none');
    }
  }

  toggleSchedule(event) {
    const shouldSchedule = event.target.value === 'yes';

    if (shouldSchedule) {
      this.scheduledAtTarget.classList.remove('d-none');
      // Initialize or reinitialize Flatpickr
      if (!this.flatpickrInstance) {
        // this.flatpickrInstance = flatpickr(this.scheduledAtTarget.querySelector('[data-target="flatpickr.field"]'), {/* options */});
      }
    } else {
      this.scheduledAtTarget.classList.add('d-none');
      if (this.flatpickrInstance) {
        // this.flatpickrInstance.clear();
      }
    }
  }

  setPrefVisibility (val) {
    $(this.phoneTarget).attr('required', true);
    $(this.phoneTarget).addClass('phone-validation');
    $(this.emailTarget).attr('required', true);
    $(this.phoneDivTarget).removeClass('d-none');
    $(this.emailDivTarget).removeClass('d-none');
    $('.fax-section').addClass('d-none');

    if(this.hasFaxTarget) {
      $(this.faxTarget).attr('required', true);
      $(this.faxTarget).addClass('phone-validation');
      $(this.faxDivTarget).removeClass('d-none');
      if (val != 'fax') {
        $(this.faxTarget).attr('required', false);
        $(this.faxTarget).removeClass('phone-validation');
        $(this.faxDivTarget).addClass('d-none');
      }
    }

    if (val == 'phone') {
      $(this.emailTarget).attr('required', false);
      $(this.emailDivTarget).addClass('d-none');
    } else if (val == 'email') {
      $(this.phoneTarget).attr('required', false);
      $(this.phoneTarget).removeClass('phone-validation');
      $(this.phoneDivTarget).addClass('d-none');
    }

    if (val == 'fax') {
      $(this.emailTarget).attr('required', false);
      $(this.emailDivTarget).addClass('d-none');
      $(this.phoneTarget).attr('required', false);
      $(this.phoneTarget).removeClass('phone-validation');
      $(this.phoneDivTarget).addClass('d-none');
      $(this.faxTarget).attr('required', true);
      $(this.faxTarget).addClass('phone-validation');
      $(this.faxDivTarget).removeClass('d-none');
      $('.fax-section').removeClass('d-none');
    }
  }

  letterTabChanged (e) {
    var val = e.target.id;
    $('.next-button').removeClass('d-none');
    if (val == 'body-tab') {
      $('.send-letter-btn').addClass('d-none');
    } else {
      $('.send-letter-btn').removeClass('d-none');
      $('#recipient-info-tab')[0].click();
      $('.next-button').addClass('d-none');
    }
  }


  promptChanged (e) {
    var id = e.target.value;
    var template = this.templatesValue.find((template) => template.id == id);
    if (template) {
      var mainTarget = e.target.dataset['mainTarget'];
      if (mainTarget) {
        CKEDITOR.instances[`gpt-bod-${mainTarget}`].setData(template.prompt);
      } else {
        CKEDITOR.instances['gpt-bod'].setData(template.prompt);
      }
    }
  }

  exportToBody (e) {
    var mainTarget = e.target.dataset['mainTarget'];
    var pasteTo = e.target.dataset['pasteTo'];

    if (mainTarget) {
      var body = CKEDITOR.instances[`gpt-bod-resp-${mainTarget}`].getData();
      var plainText = $('<div>').html(body).text(); // Converts HTML to plain text, preserving line breaks.
      var k = `.${pasteTo}`;
      $(k).val(plainText);
      $('#gptModal').modal('hide');
      // debugger;
    } else {
      var body = CKEDITOR.instances['gpt-bod-resp'].getData();
      CKEDITOR.instances[pasteTo].setData(body);
    }
  }

  messagePromptConvert (e) {
    $('body').addClass('loading')
    var vm = this;
    var target = e.target;
    var mainTarget = e.target.dataset['mainTarget'];
    var patientId = target.dataset['patientId'];
    if (mainTarget) {
      var prompt = CKEDITOR.instances[`gpt-bod-${mainTarget}`].getData();
    } else {
      var prompt = CKEDITOR.instances['gpt-bod'].getData();
    }
    var url = `/prompt_templates/extract_template`;

    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "POST",
        dataType: "json",
        data: {
          patient_id: patientId,
          prompt: prompt
        },
        success: function (result) {
          var body = JSON.parse(result.result);
          if (mainTarget) {
            CKEDITOR.instances[`gpt-bod-${mainTarget}`].setData(body);
          } else {
            CKEDITOR.instances['gpt-bod'].setData(body);
          }
          toastr.success('Message Prompt Converted.');
          $('body').removeClass('loading')
        },
        error: function(result) {
          toastr.error('Something went wrong.');
          $('body').removeClass('loading')
        }
      }
    );
  }

  messagePromptApply (e) {
    $('body').addClass('loading')
    $('body').addClass('skin--v2 mini-navbar pace-done modal-open loading')
    var vm = this;
    // debugger
    var mainTarget = e.target.dataset['mainTarget'];
    var target = e.target;
    var patientId = target.dataset['patientId'];
    if (mainTarget) {
      var prompt = CKEDITOR.instances[`gpt-bod-${mainTarget}`].getData();
      toastr.options.closeDuration = 6000;
      CKEDITOR.instances[`gpt-bod-resp-${mainTarget}`].setData('Writing.. Please hold on!');

      toastr.success('Please wait...');
    } else {
      var prompt = CKEDITOR.instances['gpt-bod'].getData();
    }
    console.log('prompt is: ', prompt)
    var url = `/prompt_templates/send_to_gpt`;

    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "POST",
        dataType: "json",
        data: {
          patient_id: patientId,
          prompt: prompt
        },
        success: function (result) {
          var body = JSON.parse(result.result);
          body = body.replace(/\n/g, '<br>'); // Replace newline characters with HTML line breaks
          // vm.bodyTarget.value = body;
          if (mainTarget) {
            CKEDITOR.instances[`gpt-bod-resp-${mainTarget}`].setData(body);
          } else {
            CKEDITOR.instances['gpt-bod-resp'].setData(body);
          }
          $('body').removeClass('loading')
          toastr.success('Message Prompt Applied.');
        },
        error: function(result) {
          $('body').removeClass('loading')
          toastr.error('Something went wrong.');
        }
      }
    );
  }
}
