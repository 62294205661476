import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

import {
  isAdmin,
  serializeFormData,
} from "helpers";
export default class extends ApplicationController {
  static targets = ['filterForm', 'filterBtn', 'modal', 'search', 'availableFilters', 'dateFilter', 'smartFilterBtn', 'smartModal', 'listName', 'smartModal', 'listedConditions', 'saveListBtn', 'saveListModalBtn', 'updateListModalBtn']
  static values = {
    filts: Array,
    selectedList: String
  }
  connect() {
    StimulusReflex.register(this)
    this.initRangeFlatPickr();
    if (this.selectedListValue) {
      setTimeout(() => {
        $(`.list-${this.selectedListValue}`)[0].click();
        setTimeout(() => {
          $('.apply-filter')[0].click();
        }, 1000);
      }, 500);
    }
  }

  goToFilters (e) {
    e.preventDefault();
    $('.main-filters').click();
  }

  backClicked () {
    $("#addFilterModal").modal('hide');
    $('#smartFilterModal').modal('show');
  }

  saveListClicked (e) {
    e.preventDefault();
    var params = {};
    params.listId = null;
    var el = e.target;
    var target = el.dataset['target'];
    $('.list-modal-btn').addClass('d-none');

    if (target == 'filter.saveListBtn') {
    } else if (target == 'filter.updateListBtn') {
      params.list_id = this.appliedList.id;
    }

    this.stimulate("SmartFilter#render_form_list", params);
  }

  renderFormListSuccess(element, reflex, noop, reflexId) {
    $(this.smartModalTarget).modal('toggle');
    $('#listModal').modal('show');
  }

  applyList (e) {
    e.preventDefault();
    var el = e.target;
    var listId = el.dataset['id'];

    var vm = this;
    var url = `/smart_lists/${listId}/`;
    if (isAdmin()) { url = '/admin' + url; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
        },
        success: function (result) {
          var list = JSON.parse(result.smart_list)
          var node = $('.patient-page')[0];
          var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'filter');
          destCtrl.setList(list);
          destCtrl.broadcastToColumnDropdown();
          vm.appliedList = list;
          setTimeout(() => {
            $('.apply-filter')[0].click();
          }, 1000);
        }
      }
    );
  }

  saveList (e) {
    e.preventDefault();
    var vm = this;
    var conditions = [];
    var el = e.target;
    var listedConditions = this.listedConditionsTarget;
    var target = el.dataset['target'];
    var url = `/smart_lists/`;
    var method = 'POST';
    var form = $('#save-list-form')[0];
    var params = serializeFormData(form);

    $(listedConditions)[0].querySelectorAll('.condition-data').forEach(function(link) {
      conditions.push(JSON.parse(link.dataset['params']));
    });

    params['conditions'] = JSON.stringify(conditions);

    if (target == 'smart-filter.updateListModalBtn') {
      url = `/smart_lists/${this.appliedList.id}`;
      method = 'PATCH';
    }

    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: method,
        dataType: "script",
        data: params,
        // data: JSON.stringify(params),

        success: function (result) {
          toastr.success('Smart List updated!');
          $('#listModal').modal('hide');
          $(vm.smartModalTarget).modal('toggle');
        },
        error: function(result) {
          toastr.error('Something went wrong.');
        }
      }
    );
  }

  initRangeFlatPickr () {
    var vm = this;

    vm.flatpickr = flatpickr('.range-pickr', {
      mode: 'range',
      showMonths: 2,
      onReady: function(dateObj, dateStr, instance) {
        var $cal = $(instance.calendarContainer);
        if ($cal.find('.flatpickr-clear').length < 1) {
          $cal.append('<a class="flatpickr-clear">Clear</a>');
          $cal.find('.flatpickr-clear').on('click', function() {
            instance.clear();
            instance.close();
          });
        }
      }
    })
  }

  downloadCsv (e) {
    e.preventDefault();
    var params = {};
    var listedConditions = this.listedConditionsTarget;
    var conditions = [];
    $(listedConditions)[0].querySelectorAll('.condition-data').forEach(function(link) {
      conditions.push(JSON.parse(link.dataset['params']));
    });

    params['conditions'] = JSON.stringify(conditions);
    var node = $('.checkbox-dropdown')[0];
    var destCtrl = this.application.getControllerForElementAndIdentifier(node, 'checkbox-dropdown');
    params['columns'] = destCtrl.getSelectedColumns()

    var vm = this;
    var url = `/smart_lists/select_columns_csv/`;
    if (isAdmin()) { url = '/admin' + url; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "script",
        data: params,
        success: function (result) {
        }
      }
    );
  }
}
