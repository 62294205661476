import { Controller } from "stimulus"
import {
  isAdmin,
} from "helpers";
export default class extends Controller {
  static targets = ['transcriptArea', 'response', 'prompt', 'transcriptId', 'patientId']
  static values = {
    transcripts: Array,
  }
  connect() {
  }

  transcriptChanged (e) {
    var id = e.target.value;
    var transcript = this.transcriptsValue.find((transcript) => transcript.id == id);
    console.log('transcript:', transcript);
    this.transcriptAreaTarget.value = transcript.body;
  }

  ask (e) {
    var vm = this;
    var data = {
      transcript_id: this.transcriptIdTarget.value,
      patient_id: this.patientIdTarget.value,
      prompt: this.promptTarget.value
    }
    console.log('data:', data);
    var url = `/patients/${this.patientIdTarget.value}/transcript_responses/ask`;
    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: data,
        success: function (result) {
          var response = result.result;
          vm.responseTarget.value = response;
        },
        error: function(result) {
          toastr.error('Something went wrong.');
        }
      }
    );
  }

  letterMessageTemplateChanged (e) {
    var vm = this;
    var target = e.target;
    var patientId = target.dataset['patientId'];
    var templateId = target.value;
    console.log('templateIdfor Message', templateId);
    var url = `/patients/${patientId}/patient_emails/extract_message_template`;
    if (isAdmin()) { url = `/admin${url}`; }

    $.ajax(
      {
        url: url,
        type: "GET",
        dataType: "json",
        data: {
          patient_id: patientId,
          letter_message_template_id: templateId
        },
        success: function (result) {
          var body = JSON.parse(result.result);
          vm.bodyTarget.value = body;
          CKEDITOR.instances['msgbod'].setData(body);
          toastr.success('Message Template Imported.');
        },
        error: function(result) {
          toastr.error('Something went wrong.');
        }
      }
    );
  }

  recipientTypeChanged (e) {
    var val = e.target.value;
    if (val == 'patient') {
      $('.fax-mode').addClass('d-none');
      $('.email-mode').click();
    } else if (val == 'pcp') {
      $('.fax-mode').removeClass('d-none');
    }
  }

  setPrefVisibility (val) {
    $(this.phoneTarget).attr('required', true);
    $(this.phoneTarget).addClass('phone-validation');
    $(this.emailTarget).attr('required', true);
    $(this.phoneDivTarget).removeClass('d-none');
    $(this.emailDivTarget).removeClass('d-none');

    if(this.hasFaxTarget) {
      $(this.faxTarget).attr('required', true);
      $(this.faxTarget).addClass('phone-validation');
      $(this.faxDivTarget).removeClass('d-none');
      if (val != 'fax') {
        $(this.faxTarget).attr('required', false);
        $(this.faxTarget).removeClass('phone-validation');
        $(this.faxDivTarget).addClass('d-none');
      }
    }

    if (val == 'phone') {
      $(this.emailTarget).attr('required', false);
      $(this.emailDivTarget).addClass('d-none');
    } else if (val == 'email') {
      $(this.phoneTarget).attr('required', false);
      $(this.phoneTarget).removeClass('phone-validation');
      $(this.phoneDivTarget).addClass('d-none');
    }

    if (val == 'fax') {
      $(this.emailTarget).attr('required', false);
      $(this.emailDivTarget).addClass('d-none');
      $(this.phoneTarget).attr('required', false);
      $(this.phoneTarget).removeClass('phone-validation');
      $(this.phoneDivTarget).addClass('d-none');
      $(this.faxTarget).attr('required', true);
      $(this.faxTarget).addClass('phone-validation');
      $(this.faxDivTarget).removeClass('d-none');
    }
  }
}
