import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'


export default class extends ApplicationController {
  static targets = ['form', 'treatmentPrice']

  connect() {
  }

  newSubTreatmentAdded () {
    var vm = this;
    setTimeout(() => {
      vm.application.register('.sub-treat', clipCtrl)
    }, 500);
  }

  priceChanged () {
    var allSubPrices = this.formTarget.querySelectorAll('.sub-treatment-price')
    var price = 0;

    allSubPrices.forEach(function(el){
      var parsedPrice = parseInt(el.value);
      if (parsedPrice > 0) {
        price += parsedPrice;
      }
    });

    this.treatmentPriceTarget.value = price;
  }
}
