import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'form'];

  connect() {
  }

  validateCheckboxes () {
    var res = true;
    var scrollToElement = null;
    var all = $('.checkbox-required-group');

    if (all.length <1 ) return true;
    for (var i=0; i < all.length; i++) {
      var group = all[i];
      var isSkipped = group.parentElement.parentElement.parentElement.parentElement.classList.contains('hide');
      if (isSkipped) {
        continue;
      }

      if (group.classList.contains('treatment-checkbox-group')){
        var boxes = group.querySelectorAll('.treatment-checkbox-input:checked');
      } else {
        var boxes = group.querySelectorAll(':checked');
      }

      var errorSpan = group.querySelector('.error-validation');
      if (boxes.length == 0) {
        $(errorSpan).removeClass('d-none')
        res = false;

        if (scrollToElement == null) {
          scrollToElement = group
        }
      } else {
        $(errorSpan).addClass('d-none')
      }
    }

    if (scrollToElement) {
      scrollToElement.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
        }
      )
    }

    return res;
  }

  validateFlatpickrs () {

    var res = true;
    var scrollToElement = null;
    var all = (this.formTarget).querySelectorAll('.flatpickr-input');

    if (all.length < 1 ) return true;

    for (var i=0; i < all.length; i++) {
      var el = all[i];
      var errorSpan = (el).parentElement.parentElement.querySelector('.error-validation');

      if (el.required && el.value == '') {
        $(errorSpan).removeClass('d-none')
        res = false;

        if (scrollToElement == null) {
            scrollToElement = el
          }
      } else {
        $(errorSpan).addClass('d-none')
      }
    }

    if (scrollToElement) {
      scrollToElement.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
        }
      )
    }

    return res;
  }

  validateSignatures () {
    var res = true;
    var scrollToElement = null;
    var all = $('.sig-field');

    if (all.length < 1 ) return true;

    for (var i=0; i < all.length; i++) {
      var el = all[i];
      var errorSpan = (el).parentElement.parentElement.querySelector('.error-validation');

      if (el.required && el.value == '') {
        $(errorSpan).removeClass('d-none')
        res = false;

        if (scrollToElement == null) {
            scrollToElement = el
          }
      } else {
        $(errorSpan).addClass('d-none')
      }
    }

    if (scrollToElement) {
      scrollToElement.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
        }
      )
    }

    return res;
  }

  validateTabularData () {
    var res = true;
    var scrollToElement = null;
    var all = $('.tabular-data');

    if (all.length < 1 ) return true;

    for (var i=0; i < all.length; i++) {
      var el = all[i];
      var errorSpan = (el).querySelector('.error-validation');

      if (el.classList.contains('required') && !el.classList.contains('hide') && !((el.parentElement).classList).contains('hide')) {
        var allInputs = el.querySelectorAll('.data-collect-field')
        var inputFound = false;
        for (var j=0; j < allInputs.length; j++) {
          if (allInputs[j].value != '') {
            inputFound = true;
            break;
          }
        }
        if (!inputFound) {
          $(errorSpan).removeClass('d-none')
          res = false;
          if (scrollToElement == null) {
              scrollToElement = el
          }
        }
      } else {
        $(errorSpan).addClass('d-none')
      }
    }

    if (scrollToElement) {
      scrollToElement.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
        }
      )
    }

    return res;
  }

  validateTreatmentField () {
    var res = true;
    var scrollToElement = null;
    var all = $('.tx-validation');

    if (all.length < 1 ) return true;

    for (var i=0; i < all.length; i++) {
      var el = all[i];
      var errorSpan = el;

      if (!el.classList.contains('skip-required')) {
        res = false;
        $(errorSpan).removeClass('d-none')
        if (scrollToElement == null) {
          scrollToElement = el
        }
      } else {
        $(errorSpan).addClass('d-none')
      }
    }

    if (scrollToElement) {
      scrollToElement.scrollIntoView(
        {
          behavior: "smooth",
          block: "center",
        }
      )
    }

    return res;
  }

  phoneNumbers () {
    var res = true;
    var scrollToElement = null;
    var all = $('.phone-validation');
    var patt = new RegExp(/^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/);

    if (all.length <1 ) return true;
    for (var i=0; i < all.length; i++) {
      var number = all[i];
      var errorSpan = number.parentElement.querySelector('.error-validation');
      var numArray = number.value.split(',');
      if (numArray.length > 1) {
        for (var j=0; j < numArray.length; j++) {
          if (!patt.test(numArray[j])) {
            $(errorSpan).removeClass('d-none')
            res = false;
          } else {
            $(errorSpan).addClass('d-none')
          }
        }
      } else {
        if (!patt.test(number.value)) {
          $(errorSpan).removeClass('d-none')
          res = false;
        } else {
          $(errorSpan).addClass('d-none')
        }
      }
    }

    return res;
  }

  submit () {
    var res = this.validateCheckboxes();
    if (res) {
      res = this.phoneNumbers()
    }
    if (res) {
      res = this.validateFlatpickrs()
    }
    if (res) {
      res = this.validateSignatures()
    }
    if (res) {
      res = this.validateTabularData()
    }
    if (res) {
      res = this.validateTreatmentField()
    }
    if (!res) {
      event.preventDefault();
      toastr.error('Please fix form errors');
    } else {
    }

    if (this.hasFormTarget) {
      if (!res) {
        $(this.formTarget).addClass('invalid')
      } else {
        $(this.formTarget).removeClass('invalid')
      }
    }
  }
}
