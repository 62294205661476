export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function isAdmin() {
  return  $("meta[name='current-user-role']")[0].content == 'admin-user'
}

export function isStaff() {
  return  $("meta[name='current-user-role']")[0].content == 'staff-user'
}

export function currentUserInformation() {
  var hash = {};
  hash.userId = $("meta[name='current-user']")[0].content;
  hash.userType = $("meta[name='current-user-role']")[0].content;

  return hash;
}

export function formatFloat(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function formatDateTime(dt) {
  return new moment(dt).tz('America/New_York').format('MM/DD/YYYY HH:mm');
}

export function formatDate(d) {
  return new moment(d).tz('America/New_York').format('MM/DD/YYYY');
}

export function triggerChange (el) {
  var ev = document.createEvent('Event');
  ev.initEvent('change', true, false);
  el.dispatchEvent(ev);
}

export function serializeFormData(form) {
  var data = $(form).serializeArray().reduce(function(output, value) {
    if (output[value.name]) {
      if (Object.prototype.toString.call(output[value.name]) == '[object Array]') {
        var arr = output[value.name];
        arr.push(value.value)
        output[value.name] = arr;
      } else {
        var str = output[value.name];
        var arr = str.split(',');
        arr.push(value.value);
        output[value.name] = arr;
      }
    } else {
      output[value.name] = value.value
    }
    return output;
  }, {})

  return data;
}

export function trimFileName (input) {
  var split = input.split('.');
  var filename = split[0];
  var extension = split[1];

  if (filename.length > 10) {
    filename = filename.substring(0, 10);
  }

  return filename + '.' + extension;
}

export function formatAmount(str) {
  var x = str.toString().split('.')
  x[0] = x[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return x.join('.')
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
  );
}

export function randomColor() {
  return "#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase();
}

export function refreshComponenets() {
  console.log('refreshing this shiz..')
  if ($('.refresh-table-modal')[0]) {
    $('.refresh-table-modal')[0].click();
  }

  if ($('.apply-filter').length > 0){
    console.log('clicking this siz')
    for (const element of $('.apply-filter')) {
      element.click()
    }
  }

  if ($('.refresh-page-table').length > 0){
    for (const element of $('.refresh-page-table')) {
      element.click()
    }
  }

  if ($('.refresh-tiles')[0]) {
    $('.refresh-tiles')[0].click();
  }

  var element = $('.refresh-profile')[0];
  if (element) {
    element.click();
  }
}

export function requireAForm (form) {
  var inputs = form.querySelectorAll("form input, form select");

  for (var i = 0; i < inputs.length; i++) {
    inputs[i].required = true;
  }
}


export function extractSubDomain() {
  var domain = window.location.hostname;
  var split = domain.split('.');

  return split[0];
}