import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fileCover', 'editorCover']

  connect() { }

  toggleCover (e) {
    e.preventDefault();
    var element = e.target;
    if (element.id == 'file-cover') {
      $('.cover-file-choose').show();
      $('.cover-editor').hide();
      CKEDITOR.instances['block_email_body'].setData('');
    } else {
      $('.cover-file-choose').hide();
      $('.cover-editor').show();
      $('#file-input').val('');
    }
  }
}
