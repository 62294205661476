import consumer from "./consumer"

if($("meta[name='current-user']")[0] && $("meta[name='current-user']")[0].content.length > 0)
{
  consumer.subscriptions.create("NotificationChannel", {
    received(data) {
      if (data.notification) {
        var userId = $("meta[name='current-user']")[0].content;
        if (userId == data.notification.staff_user_id) {
          var val = $('#bell-count')[0].innerHTML;
          val = parseInt(val) + 1
          $('#bell-count')[0].innerHTML = val;
        }
      }
    }
  });
}
