import { Controller } from "stimulus"
import {
  isAdmin
} from "helpers";

export default class extends Controller {
  static targets = ['unit', 'serialNumber', 'product', 'demand', 'cost', 'patient', 'productRequest']
  static values = {
    unitProducts: Array,
  }

  connect() {
  }

  productChanged (e) {
    var vm =this;
    var id = e.target.value;

    var product = this.unitProductsValue.find(function(s) {
      return (s.id == id);
    });

    this.unitTarget.value = product.unit_of_purchase_id;
    this.costTarget.value = product.cost;

    $(this.unitTarget).trigger('change');
    $(this.serialNumberTarget).val(null).trigger('change');
    $(vm.serialNumberTarget).empty();


    if (product) {
      var serialNumbers = product.serial_numbers;
      serialNumbers.forEach(function (v) {
        var newOption = new Option(v.name, v.id, false, false);
        vm.serialNumberTarget.append(newOption);
      });

      $(vm.serialNumberTarget).val(null)
      $(vm.serialNumberTarget)[0].disabled = false
      $(vm.patientTarget)[0].required = product.require_patient;
      $(vm.serialNumberTarget)[0].required = product.require_patient;
      vm.updateSerialNumberUrl(product);
      vm.setProductRequests(product);
    }
  }

  updateSerialNumberUrl (product) {
    var vm = this;
    var parentDiv = $(vm.serialNumberTarget)[0].parentElement.parentElement;
    var node = parentDiv
    var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'select2');

    destCtrl.setExtraParams({
      product_id: product.id
    });
  }

  serialNumberChanged (e) {
    var vm = this;
    var val = e.target.value;
    var params = {
      id: val,
      product_id: vm.productTarget.value
    }

    var url = `/serial_numbers/create_and_fetch`;
    if (isAdmin()) {
      url = '/admin/' + url;
    }

    $.ajax({
      url: url,
      method: 'GET',
      data: params,
      dataType: "script",
      success: (result) => {
        var serialNumber = JSON.parse(JSON.parse(result).results);
        var serId = serialNumber.id.toString()
        if (vm.serialNumberTarget != serId) {
          $(vm.serialNumberTarget).find(":selected")[0].value = serId;
        }
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }

  setProductRequests (product) {
    var vm = this;
    var params = {
      id: product.id,
    }

    var url = `/products/${product.id}/fetch_product_requests`;
    if (isAdmin()) {
      url = '/admin/' + url;
    }

    $.ajax({
      url: url,
      method: 'GET',
      data: params,
      dataType: "JSON",
      success: (result) => {
        var result = JSON.parse(result.results);
        $(vm.productRequestTarget).empty()

        if (!result || result.length == 0) return;

        result.forEach(function (v) {
          var newOption = new Option(v.name, v.id, false, false);
          vm.productRequestTarget.append(newOption);
        });
        $(vm.productRequestTarget).val(null)
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }
}
