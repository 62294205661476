import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = ["form", 'contentArea'];

  connect() {
    StimulusReflex.register(this)
  }

  submit(e) {
    e.preventDefault();
    var vm = this;
    this.stimulate(`Comments#submit`, this.formTarget);
  }

  submitSuccess (element, reflex, noop, reflexId) {
    toastr.options.timeOut = 90;
    toastr.success('Comment created..');
    this.contentAreaTarget.value  = ''
  }
  submitError (element, reflex, noop, reflexId) {
    toastr.error('Something went wrong..');
  }
}
