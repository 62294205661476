import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

import {
  requireAForm,
  serializeFormData
} from "helpers";
export default class extends ApplicationController {
  static targets = ['filterForm', 'filterBtn', 'modal', 'search', 'availableFilters', 'dateFilter', 'smartFilterBtn', 'smartModal', 'listName', 'smartModal']
  static values = {
    filts: Array,
  }
  connect() {
    console.log('dynamic condition connected')
    StimulusReflex.register(this)
  }

  backClicked () {
    $("#addFilterModal").modal('hide');
    $('#smartFilterModal').modal('show');
  }

  discardEdit (e) {
    e.preventDefault();
    var target = e.target;
    var id = target.dataset['id'];
    var klass = `.body-nest-edit-${id}`;
    $(klass).html('');
  }

  saveClicked (e) {
    e.preventDefault();
    var form = e.target.closest('form');
    var isValid = form.reportValidity();
    if (!isValid) return;

    var formData = serializeFormData(form);
    var node = $('.patient-page')[0];
    var destCtrl = this.application.getControllerForElementAndIdentifier(node, 'filter');
    destCtrl.addToList(formData);
    $('.sub-filter-dd').addClass('collapsed');
    $('.sub-filter-dd').removeClass('show');
  }

  filterConditionChanged (e) {
    var val = e.target.value;
    $('.filter-condition-options').addClass('d-none');
    var klass = `.condition-${val}`;
    $(klass).removeClass('d-none');
    requireAForm($(klass)[0])
  }
}
