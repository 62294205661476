import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = [];

  connect() {
    StimulusReflex.register(this);
  }

  viewPreviousForm (e) {
    this.stimulate("AssignedForm#view_previous_form", {id: e.target.dataset['formId']});
  }

  viewPreviousFormSuccess (element, reflex, noop, reflexId) {
    $('.sub-form-modal').modal('show');
  }
}
