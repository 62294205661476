import { Controller } from "stimulus"
import {
  serializeFormData,
  triggerChange
} from "helpers";
export default class extends Controller {

  static targets = ['status', 'elapsedTime']
  static values = {
    reinforcementRules: Object,
  }
  activeRequests = 0;

  connect() {
    if (this.reinforcementRulesValue && this.reinforcementRulesValue != {}) {
      this.showReinforcement = true;
    } else {
      this.showReinforcement = false;
    }

    $('body')[0].classList.add('mini-navbar');

    this.activeRequests = 0;
    this.form = $(this.element)
    this.timeout = null
  }

  formEvent () {
    var el = $('#assigned-steps-form')[0];
    var ev = document.createEvent('Event');
    ev.initEvent('change', true, false);
    el.dispatchEvent(ev);
  }


  submitForm(e) {
    e.preventDefault();
    var vm = this;
    var el = e.target;
    if (el.classList.contains('flatpickr-input'))
      return;

    var isValid = this.form[0].reportValidity();
    console.log('class list;', this.form[0].classList);

    document.querySelectorAll('.audio-field').forEach(function (element) {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(element, 'audio-recording');
      var isRecording = destCtrl.openRecorder();
      if (isRecording) {
        isValid = false;
        alert('Please stop all recordings before continuing');
      }
    });

    if (!isValid || this.form[0].classList.contains('invalid')) return;

    var data = this.form_data(e);
    data.delete('autosaved');

    data.append('autosaved', false);
    data.append('commit', 'Submit');

    console.log('saving')

    var _self = this;

    clearTimeout(this.timeout)
    this.analyzeReinforcements(e);

    if (vm.showReinforcement && this.rulesToShow.length > 0) {
      const rulesList = `<ul>${this.rulesToShow.map(rule => `<li>${rule}</li>`).join('')}</ul>`;
      swal({
        title: '[IMPORTANT] Please review the following before proceeding',
        content: {
          element: "div",
          attributes: {
            innerHTML: rulesList
          }
        },
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancel",
            visible: true,
            className: "swal-button--cancel",
            closeModal: true,
          },
          confirm: {
            text: 'Yes, I have reviewed everything',
            className: 'swal-button--confirm',
            value: 'reviewed',
          }
        },
      }).then((value) => {
        if (value === 'reviewed') {
          vm.showReinforcement = false;
        }
      });
    } else {
      this.timeout = setTimeout(() => {
        this.activeRequests+=1;

        $.ajax({
          url: _self.form[0].dataset['autosavePath'],
          method: 'POST',
          data: data,
          processData: false,
          contentType: false,
          dataType: "script",
          mimeType: 'multipart/form-data',    //Property added in 1.5.1
          beforeSend: () => {
            if ($('#submit-button')[0]) {
              $('#submit-button')[0].disabled = true;
              $('.disable-btn').addClass('d-none');
            }
          },
          success: () => {
            console.log('doom..')
          },
          error: () => {
          },
          complete: () => {
            this.activeRequests-=1;
            if ($('#submit-button')[0] && this.activeRequests == 0) {
              $('#submit-button')[0].disabled = false;
              $('.disable-btn').removeClass('d-none');
            }
          }
        });
      }, 1000)
    }

  }

  saveDraft(e) {
    var el = e.target;
    if (el.classList.contains('flatpickr-input'))
      return;

    console.log('drafting')

    var _self = this;

    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.activeRequests+=1;

      $.ajax({
        url: _self.form[0].dataset['autosavePath'],
        method: 'POST',
        data: this.form_data(e),
        processData: false,
        contentType: false,
        mimeType: 'multipart/form-data',    //Property added in 1.5.1
        beforeSend: () => {
          if ($('#submit-button')[0]) {
            $('#submit-button')[0].disabled = true;
            $('.disable-btn').addClass('d-none');
          }
        },
        success: () => {
        },
        error: () => {
        },
        complete: () => {
          this.activeRequests-=1;
          if ($('#submit-button')[0] && this.activeRequests == 0) {
            $('#submit-button')[0].disabled = false;
            $('.disable-btn').removeClass('d-none');
          }
        }
      });
    }, 1000)
  }

  serializeFormParams() {
    return serializeFormData(this.form);
  }

  form_data(e) {
    var vm = this;
    var obj = new FormData(this.form[0]);
    document.querySelectorAll('.audio-field').forEach(function (element) {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(element, 'audio-recording');
      obj = destCtrl.appendFormData(obj);
    });
    obj.append("elapsed_time", this.elapsedTimeTarget.value);
    obj.append('autosaved', true);

    console.log('obect:', obj);
    return obj;
  }

  analyzeReinforcements (e) {
    var vm = this;
    var rulesToShow = [];

    for (var key in vm.reinforcementRulesValue) {
      var el = vm.form[0].querySelectorAll(`[data-option-id='${key}']`);
      if (el) {
        el = el[0]
        var isVisible = $(el).is(':visible')
        if (isVisible){
          var checked = vm.form[0].querySelectorAll(`[data-option-id='${key}']`)[0].checked;
          if (checked) {
            rulesToShow.push(vm.reinforcementRulesValue[key].flat())
          }
        }
      }
    }
    this.rulesToShow = rulesToShow.flat();
  }
}
