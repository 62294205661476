import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import ChartJS from "chart.js";
import {
  randomColor,
  toTitleCase,
} from "helpers";

export default class extends ApplicationController {
  static targets = ['']
  static values = {
    requestsGraphData: Object,
    requestTypesGraphData: Object,
    classificationRequestsGraphData: Object,
    fulfilmentRequestsGraphData: Object,
    priorityRequestsGraphData: Object,
    statusTypeRequestsGraphData: Object,
    averageCompletedRequestsGraphData: Object,
    repeatedIncidentRequestsGraphData: Object,
    applianceCostsGraphData: Object,
    completedByGraphData: Object,
    requestedByGraphData: Object,
    patientRequestsGraphData: Object
  }
  connect() {
    StimulusReflex.register(this)
    $('body')[0].classList.add('mini-navbar');
    this.drawMultipleBarChart('#requestsGraphChart', 'Number Of Requests', this.requestsGraphDataValue, 'rgba(51, 204, 51, 0.2)');
    this.drawPieChart('#requestTypesGraphChart', 'Appliance Types', this.requestTypesGraphDataValue, 0);
    this.drawPieChart('#classificationRequestsGraphChart', 'Lab Request Classifications', this.classificationRequestsGraphDataValue, 0);
    this.drawPieChart('#fulfilmentRequestsGraphChart', 'Lab Request Fulfilments', this.fulfilmentRequestsGraphDataValue, 0);
    this.drawPieChart('#priorityRequestsGraphChart', 'Lab Request Priorities', this.priorityRequestsGraphDataValue, 0);
    this.drawPieChart('#statusTypeRequestsGraphChart', 'Lab Request Statuses', this.statusTypeRequestsGraphDataValue, 0);
    this.drawPieChart('#applianceCostsGraphChart', 'Appliance Cost', this.applianceCostsGraphDataValue, 0);
    this.drawPieChart('#completedByGraphChart', 'Lab Requests Completed By', this.completedByGraphDataValue, $('#completed_users').val());
    this.drawPieChart('#requestedByGraphChart', 'Lab Requests Requested By', this.requestedByGraphDataValue, $('#requested_users').val());
    this.drawPieChart('#patientRequestsGraphChart', 'Requests Per Patient', this.patientRequestsGraphDataValue, $('#patients_data').val());
    this.drawLineChart('#completedAverageRequestsGraphChart', 'Turn Around Average Time', this.averageCompletedRequestsGraphDataValue, 'rgba(51, 204, 51, 0.2)');
    this.drawChart('#repeatedIncidentRequestsGraphChart', 'Repeated Incident Requests', this.repeatedIncidentRequestsGraphDataValue, 'rrgba(255, 71, 26, 0.4)');
  }

  drawChart (identifier, title, data, color) {
    var vm = this;
    var ctx = $(identifier)[0];
    var graph1 = new ChartJS(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            type: 'bar',
            label: title,
            borderColor: 'green',
            data:  Object.values(data),
            backgroundColor: color,
            fill: false,
          },

      ],
        labels: Object.keys(data),
      },
      options: {
        legend: {
          display: false //This will remove inner labels
        },
        title: {
          display: true,
          text: title
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Number Of Requests',
              fontStyle: 'bold'
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Date',
              fontStyle: 'bold'
            }
          }]
        },
        onClick: (e) => {
          var activePoints = graph1.getElementsAtEvent(e);

          if (activePoints[0]) {
            var chartData = activePoints[0]['_chart'].config.data;
            var idx = activePoints[0]['_index'];

            var label = chartData.labels[idx];
            var value = chartData.datasets[0].data[idx];
            var params = {
              start_date: $('#start_date').val(),
              end_date: $('#end_date').val(),
              title: title,
              date_label: label
            }
            vm.stimulate('LabAnalytic#requests_table', params);
          }
        }

      }
    });
    graph1.update();
  }

  drawPieChart (identifier, title, data, usersData) {
    var colors_array = []
    if(Object.keys(data).length > 0) {
      (Object.keys(data)).forEach(function(element, index) {
        colors_array[index] = randomColor();
      });
    }

    var applianceCost = false;
    if(title == 'Appliance Cost')
      applianceCost = true;

    var pieLegend = true;
    if(title == 'Requests Per Patient')
      pieLegend = false;

    var labelsArray = Object.keys(data)
    var x;
    if(Object.keys(data).length > 0 && title != 'Appliance Types' && title != 'Appliance Cost' && title != 'Lab Request Statuses') {
      (Object.keys(data)).forEach(function(element, index) {
        x = element.replaceAll('_', ' ')
        labelsArray[index] = toTitleCase(x)
      });
    }

    var vm = this;
    var ctx = $(identifier)[0];
    var graph = new ChartJS(ctx, {
      type: 'pie',
      data: {
        datasets: [{
          backgroundColor: colors_array,
          data: Object.values(data)
        }],
        labels: labelsArray
      },
      options: {
        legend: {
          display: pieLegend //This will remove inner labels
        },
        responsive: true,
        title: {
          display: true,
          text: title
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']];
            },
            label: function(tooltipItem, data) {
              var pieLabel = data['datasets'][0]['data'][tooltipItem['index']]
              if(applianceCost){
                pieLabel = '$' + pieLabel;
              }

              return pieLabel
            }
          }
        },
        onClick: (e) => {
          var activePoints = graph.getElementsAtEvent(e);

          if (activePoints[0]) {
            var chartData = activePoints[0]['_chart'].config.data;
            var idx = activePoints[0]['_index'];

            var label = chartData.labels[idx];
            var value = chartData.datasets[0].data[idx];
            var userId;

            var params = {
              start_date: $('#start_date').val(),
              end_date: $('#end_date').val(),
              label: label,
              title: title
            }
            if(title == 'Lab Requests Completed By' || title == 'Lab Requests Requested By') {
              userId = JSON.parse(usersData)[label];
              params['user_id'] = userId;
            }
            if(title == 'Requests Per Patient') {
              var patientId = JSON.parse(usersData)[idx];
              params['patient_id'] = patientId
            }

            this.stimulate('LabAnalytic#requests_table', params)
          }
        }
      }
    });
    graph.update();
  }

  drawLineChart (identifier, title, data, color) {
    var vm = this;
    var ctx = $(identifier)[0];
    var graph1 = new ChartJS(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            label: title,
            borderColor: 'green',
            data:  Object.values(data),
            backgroundColor: color,
            fill: false,
          },

      ],
        labels: Object.keys(data),
      },
      options: {
        legend: {
          display: false //This will remove inner labels
        },
        title: {
          display: true,
          text: title
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Number Of Days',
              fontStyle: 'bold'
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Date',
              fontStyle: 'bold'
            }
          }]
        },
        onClick: (e) => {
          var activePoints = graph1.getElementsAtEvent(e);

          if (activePoints[0]) {
            var chartData = activePoints[0]['_chart'].config.data;
            var idx = activePoints[0]['_index'];

            var label = chartData.labels[idx];
            var value = chartData.datasets[0].data[idx];
            var params = {
              start_date: $('#start_date').val(),
              end_date: $('#end_date').val(),
              title: title,
              date_label: label
            }
            vm.stimulate('LabAnalytic#requests_table', params);
          }
        }
      }
    });
    graph1.update();
  }

  requestsTableSuccess(element, reflex, noop, reflexId) {
    $('#chartModalHeading').html('Lab Requests');
    $("#chartsModal").modal('show');
  }

  drawMultipleBarChart (identifier, title, data, color) {
    var ctx = $(identifier)[0];
    var graph = new ChartJS(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            type: 'bar',
            label: 'Created',
            backgroundColor: 'green',
            tension: 0.1,
            data:  Object.values(data['created']),
            fill: false,
          },
          {
            type: 'bar',
            label: 'Completed',
            backgroundColor: 'blue',
            tension: 0.1,
            data:  Object.values(data['completed']),
            fill: false,
          },
        ],
        labels: Object.keys(data['created']),
      },
      options: {
        title: {
          display: true,
          text: title
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Number Of Requests',
              fontStyle: 'bold'
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Date',
              fontStyle: 'bold'
            },
            offset: true,
          }]
        },
        onClick: (e) => {
          var ap = graph.getElementsAtEventForMode(e, 'point', graph.options)

          if(ap[0]) {
            var firstPoint = ap[0];
            var label = graph.data.labels[firstPoint._index];
            var value = graph.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];
            var bar_label = graph.data.datasets[firstPoint._datasetIndex].label
            var params = {
              start_date: $('#start_date').val(),
              end_date: $('#end_date').val(),
              title: title,
              date_label: label,
              bar_label: bar_label
            }
            this.stimulate('LabAnalytic#requests_table', params);
          }
        }
      }
    });
    graph.update();
  }
}
