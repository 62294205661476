import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ['overview', 'form', 'id', 'flowyInput']

  connect() {
    const vm = this;
    vm.myDiagram = null;
    this.init(vm);
    $('body')[0].classList.add('mini-navbar');
    var out = JSON.parse(vm.flowyInputTarget.value);
    if (out.class)
      vm.myDiagram.model = go.Model.fromJson(out);
  }

  init(vm) {
    let _self = this;
    var $$ = go.GraphObject.make;  // for conciseness in defining templates
     vm.myDiagram =
      $$(go.Diagram, "showGraph",
        {
          allowCopy: false,
          "draggingTool.dragsTree": false,
          "commandHandler.deletesTree": false,
          layout:
            $$(go.TreeLayout,
              { angle: 90, arrangement: go.TreeLayout.ArrangementFixedRoots, nodeSpacing: 10, layerSpacing: 150, }),
          "undoManager.isEnabled": false
        });
    vm.myDiagram.addDiagramListener("Modified", function(e) {
      var button = document.getElementById("SaveButton");
      if (button) button.disabled = !vm.myDiagram.isModified;
      var idx = document.title.indexOf("*");
      if (vm.myDiagram.isModified) {
        if (idx < 0) document.title += "*";
      } else {
        if (idx >= 0) document.title = document.title.substr(0, idx);
      }
    });

    var bluegrad = $$(go.Brush, "Linear", { 0: "#fff", 1: "#fff" });
    var greengrad = $$(go.Brush, "Linear", { 0: "#2667ff", 1: "#2667ff" });
    var actionTemplate =
      $$(go.Panel, "Horizontal",
        $$(go.Shape,
          { width: 12, height: 12, margin: new go.Margin(0,10,0,0) },
          new go.Binding("figure"),
          new go.Binding("fill")
        ),
        $$(go.TextBlock,
          { font: "10pt Verdana, sans-serif" },
          new go.Binding("text")
        )
      );
    vm.setStartNode(vm, $$, bluegrad, greengrad);
    vm.setDefaultNode(vm, $$, bluegrad, actionTemplate);
    vm.setExternalFormNode(vm, $$, bluegrad, actionTemplate);
    vm.setTriggerWorkflowNode(vm, $$, bluegrad, actionTemplate);
    vm.setLinkTemplate(vm, $$);

    var nodeDataArray = [
      { key: 0, category: "Start", text: "Start" },
    ];
    var linkDataArray = [

    ];
    vm.myDiagram.model = $$(go.GraphLinksModel,
      {
        copiesArrays: true,
        copiesArrayObjects: true,
        nodeDataArray: nodeDataArray,
        linkDataArray: linkDataArray
      });
  }

  setStartNode(vm, $$, bluegrad, greengrad) {
    vm.myDiagram.nodeTemplateMap.add("Start",
      $$(go.Node, "Spot",
        $$(go.Shape, "Circle",
          { width: 75, height: 75, fill: greengrad, stroke: null }
        ),
        $$(go.TextBlock,
          { font: "10pt Verdana, sans-serif", stroke: "white" },
          new go.Binding("text")
        ),
        $$(go.Panel, "Auto",
          { alignment: go.Spot.Right, portId: "from", fromLinkable: true, cursor: "pointer", click: 'newNodeModal'  },
        ),
      )
    );
  }

  setTriggerWorkflowNode(vm, $$, bluegrad, actionTemplate) {
    var greengrad = $$(go.Brush, "Linear", { 0: "#2667ff", 1: "#2667ff" });

    vm.myDiagram.nodeTemplateMap.add("TriggerWorkflow",
      $$(go.Node, "Vertical",
      new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
      new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded

      { selectionObjectName: "BODY" },
      $$(go.Panel, "Auto",
        { name: "BODY" },
        $$(go.Shape, "RoundedRectangle",
          { fill: greengrad, stroke: "#ccc" }
        ),
        $$(go.Panel, "Vertical",
          { margin: 10,
            width: 200,
          },
          $$(go.TextBlock,
            {
              stretch: go.GraphObject.Horizontal,
              font: "bold 12pt Verdana, sans-serif",
              margin: new go.Margin(10, 0, 15, 0),
            },
            new go.Binding("text", "question")
          ),
        )
      ),
      $$(go.Panel,
        $$(go.Panel, "Table",
          $$("TreeExpanderButton",
            {
              cursor: "pointer",
              "ButtonBorder.strokeWidth": 0,
              "ButtonBorder.fill": "white",
              row: 0,
              column: 1,
            },
            $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgremove } ),
          ),
        ),
      ),

      )
    );
  }


  setDefaultNode(vm, $$, bluegrad, actionTemplate) {
    vm.myDiagram.nodeTemplate =  // the default node template
      $$(go.Node, "Vertical",
        new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
        new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded

        { selectionObjectName: "BODY" },
        $$(go.Panel, "Auto",
          { name: "BODY" },
          $$(go.Shape, "RoundedRectangle",
            { fill: bluegrad, stroke: "#ccc" }
          ),
          $$(go.Panel, "Vertical",
            { margin: 10,
              width: 200,
             },
            $$(go.TextBlock,
              {
                stretch: go.GraphObject.Horizontal,
                font: "bold 12pt Verdana, sans-serif",
                margin: new go.Margin(10, 0, 15, 0),
              },
              new go.Binding("text", "question")
            ),
            $$(go.Panel, "Vertical",
              { stretch: go.GraphObject.Horizontal, visible: true },  // not visible unless there is more than one action
              new go.Binding("visible", "actions", function(acts) {
                return true //(Array.isArray(acts) && acts.length > 0);
              }),
              $$(go.Panel, "Table",
                { stretch: go.GraphObject.Horizontal },
                $$(go.TextBlock, "Actions",
                  {
                    alignment: go.Spot.Left,
                    font: "10pt Verdana, sans-serif"
                  }
                ),
                $$("PanelExpanderButton", "COLLAPSIBLE",  // name of the object to make visible or invisible
                  { column: 1, alignment: go.Spot.Right }
                )
              ), // end Table panel
              ),  // end optional Vertical Panel
              $$(go.Panel, "Vertical",
                {
                  name: "COLLAPSIBLE",  // identify to the PanelExpanderButton
                  padding: 2,
                  margin: new go.Margin(10,0,0,0),
                  stretch: go.GraphObject.Horizontal,  // take up whole available width
                  background: "white",  // to distinguish from the node's body
                  defaultAlignment: go.Spot.Left,  // thus no need to specify alignment on each element
                  itemTemplate: actionTemplate  // the Panel created for each item in Panel.itemArray
                },
                new go.Binding("itemArray", "actions")  // bind Panel.itemArray to nodedata.actions
              ),
          )
        ),
        $$(go.Panel,
          $$(go.Panel, "Table",
            $$("TreeExpanderButton",
              {
                cursor: "pointer",
                "ButtonBorder.strokeWidth": 0,
                "ButtonBorder.fill": "white",
                row: 0,
                column: 1,
              },
              $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgremove } ),
            ),

          ),
        ),
  );}

  setExternalFormNode(vm, $$, bluegrad, actionTemplate) {
    var bluegrad = $$(go.Brush, "Linear", { 0: "#d4ff26", 1: "#2667ff" });

    vm.myDiagram.nodeTemplateMap.add("ExternalForm",
      $$(go.Node, "Vertical",
        new go.Binding("isTreeExpanded").makeTwoWay(),  // remember the expansion state for
        new go.Binding("wasTreeExpanded").makeTwoWay(), //   when the model is re-loaded

        { selectionObjectName: "BODY" },
        $$(go.Panel, "Auto",
          { name: "BODY" },
          $$(go.Shape, "RoundedRectangle",
            { fill: bluegrad, stroke: "#ccc" }
          ),
          $$(go.Panel, "Vertical",
            { margin: 10,
              width: 200,
             },
            $$(go.TextBlock,
              {
                stretch: go.GraphObject.Horizontal,
                font: "bold 12pt Verdana, sans-serif",
                margin: new go.Margin(10, 0, 15, 0),
              },
              new go.Binding("text", "question")
            ),
            $$(go.Panel, "Vertical",
              { stretch: go.GraphObject.Horizontal, visible: true },  // not visible unless there is more than one action
              new go.Binding("visible", "actions", function(acts) {
                return true //(Array.isArray(acts) && acts.length > 0);
              }),
              $$(go.Panel, "Table",
                { stretch: go.GraphObject.Horizontal },
                $$(go.TextBlock, "Actions",
                  {
                    alignment: go.Spot.Left,
                    font: "10pt Verdana, sans-serif"
                  }
                ),
                $$("PanelExpanderButton", "COLLAPSIBLE",  // name of the object to make visible or invisible
                  { column: 1, alignment: go.Spot.Right }
                )
              ), // end Table panel
              ),  // end optional Vertical Panel
              $$(go.Panel, "Vertical",
                {
                  name: "COLLAPSIBLE",  // identify to the PanelExpanderButton
                  padding: 2,
                  margin: new go.Margin(10,0,0,0),
                  stretch: go.GraphObject.Horizontal,  // take up whole available width
                  background: "white",  // to distinguish from the node's body
                  defaultAlignment: go.Spot.Left,  // thus no need to specify alignment on each element
                  itemTemplate: actionTemplate  // the Panel created for each item in Panel.itemArray
                },
                new go.Binding("itemArray", "actions")  // bind Panel.itemArray to nodedata.actions
              ),
          )
        ),
        $$(go.Panel,
          $$(go.Panel, "Table",
            $$("TreeExpanderButton",
              {
                cursor: "pointer",
                "ButtonBorder.strokeWidth": 0,
                "ButtonBorder.fill": "white",
                row: 0,
                column: 1,
              },
              $$(go.Picture, { desiredSize: new go.Size(32, 32), source: window.imgremove } ),
            ),

          ),
        ),
      )
    );
  }


  setLinkTemplate(vm, $$) {
    vm.myDiagram.linkTemplate =
      $$(go.Link, go.Link.Orthogonal,
        { deletable: false,
          margin: new go.Margin(100,100,100,100),
          corner: 10 },
        $$(go.Shape,
          { strokeWidth: 2,
            stroke: '#598BFF',
            height: 20,

            toArrow: "Standard",
            margin: new go.Margin(100,100,100,100),

           }
        ),
        $$(go.TextBlock, go.Link.OrientUpright,
          {
            background: "white",
            visible: true,  // unless the binding sets it to true for a non-empty string
            segmentIndex: -2,
            segmentOrientation: go.Link.None,
            font: "bold 8.5px Verdana, Serif",
            height: 50,
            overflow: go.TextBlock.OverflowEllipsis,
            maxLines: 4,
            width: 190,
            text: "verticalAlignment: Center", verticalAlignment: go.Spot.Center,
            textAlign: "center",
            segmentFraction: 0.4,
          },

          new go.Binding("text", "text"),
          new go.Binding("visible", "text", function(s) { if (s) return true; else return false; })
        )
      );
  }
}
