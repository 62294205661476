import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['startTime', 'endTime']

  connect() {
  }

  dateChanged () {
    var startDate = new Date(this.startTimeTarget.value);
    var endDate = new Date(this.endTimeTarget.value)
    if (startDate > endDate) {
      var dateOffset = (24*60*60*1000) * 7; //7 days
      var newDate = new Date(startDate.setTime(endDate.getTime() - dateOffset));
      this.startTimeTarget.value = newDate.toISOString().split('T')[0];
    }
  }
}
