import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['timeLeft', 'dueDate']

  connect() {
    var _self = this;
    var dueDate = this.dueDateTarget.value;
    var currentTime = new Date().getTime();
    dueDate = new Date(dueDate).getTime();
    var timeStr = _self.timeDiffCalc(dueDate, currentTime);

    if (!timeStr) {
      _self.timeLeftTarget.innerHTML = 'Time Left: Due date for this step has been passed.';
      _self.timeLeftTarget.classList.add('pending-badge');
    } else {
      _self.timeLeftTarget.innerHTML = 'Time Left: ' + timeStr;

      window.setInterval(function(){
        currentTime = new Date().getTime();
        _self.timeLeftTarget.innerHTML = 'Time Left: ' + _self.timeDiffCalc(dueDate, currentTime);
      }, 60 * 1000);
    }
    this.setDateRanges();
  }

  setDateRanges() {
    //only-future
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();

    var maxDate = year + '-' + month + '-' + day;

    $('.only-future').attr('min', maxDate);
  }

  timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = (dateFuture - dateNow) / 1000;

    if (diffInMilliSeconds < 0)
      return false;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
      difference += (days === 1) ? `${days} day, ` : `${days} days, `;
    }

    difference += (hours === 0 || hours === 1) ? `${hours} hour and, ` : `${hours} hours and, `;

    difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;

    return difference;
  }
}