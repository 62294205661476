import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form']
  static values = {
    logics: Object,
  }

  connect() {
    this.formLoadLogics();
  }

  formLoadLogics () {
    var vm = this;
    var allFields = document.querySelectorAll('.form-field');
    allFields.forEach (function (element) {
      var inclusionArr = [];
      var fieldId = element.dataset.fieldId;
      fieldId = fieldId.replace('field-', '')
      fieldId = parseInt(fieldId);
      for (var key in vm.logicsValue) {
        var value = vm.logicsValue[key];
        if (value.includes(fieldId)) {
          inclusionArr.push(key);
        }
      }
      var checked = vm.isChecked(inclusionArr, fieldId);
      vm.hideShowElement(checked, element, null);

    });
    this.broadCastToIndepententFields();
  }

  broadCastToIndepententFields () {
    var vm = this;
    var el = this.formTarget;
    const nodes = el.querySelectorAll('[data-controller="calculatable-field"]');

    nodes.forEach(node => {
      var destCtrl = vm.application.getControllerForElementAndIdentifier(node, 'calculatable-field');
      if (destCtrl) {
        destCtrl.patientStateChanged()
      }
    });
  }

  isChecked (inclusionArr, fieldId) {
    var vm = this;
    var isChecked = false;
    inclusionArr.forEach(function (optionId) {
      var el = $(vm.formTarget.querySelectorAll(`[data-option-id='${optionId}']`))[0];
      if (el) {
        var optionChecked = $(el)[0].checked;
        if (optionChecked) {
          isChecked = true;
          return;
        }
      } else {
      }
    });

    return isChecked;
  }

  // formLoadLogics () {
  //   var vm = this;
  //   var allOptions = Object.keys(this.logicsValue);
  //   console.log('this.logicsValue', this.logicsValue)
  //   console.log('allOptions:', allOptions);

  //   allOptions.forEach(function (optionId) {
  //     var el = $(vm.formTarget.querySelectorAll(`[data-option-id='${optionId}']`))[0];
  //     var checked = $(el)[0].checked;
  //     var correspondingIds = vm.logicsValue[optionId];

  //     correspondingIds.forEach(function (fieldId) {
  //       var elem = $(`.field-${fieldId}`)[0];
  //       vm.hideShowElement(checked, elem, optionId);
  //     });
  //   });
  // }

  formChanged (e) {
    this.formLoadLogics();
    return;
    // var vm = this;
    // var checked = e.target.checked;
    // var correspondingIds = this.logicsValue[e.target.dataset.optionId];
    // var inputType = e.target.type;

    // if (inputType == 'radio') {
    //   var siblingRadioInputs = (e.target).parentElement.parentElement.querySelectorAll('input');
    //   siblingRadioInputs.forEach(function (radio) {
    //     checked = radio.checked;
    //     correspondingIds = vm.logicsValue[radio.dataset.optionId];
    //     vm.applyLogic(correspondingIds, checked, radio.dataset.optionId);
    //   })
    // } else {
    //   vm.applyLogic(correspondingIds, checked, e.target.dataset.optionId);
    // }
  }

  // applyLogic(correspondingIds, checked, currentOptionId) {
  //   var vm = this;
  //   if (!correspondingIds) return;

  //   correspondingIds.forEach(function (fieldId) {
  //     var el = $(`.field-${fieldId}`)[0];
  //     vm.hideShowElement(checked, el, currentOptionId);
  //   });
  // }

  hideShowElement (checked, element, currentOptionId) {
    var isRequiredField = element.classList.contains('is-required');

    if (checked) {
      $(element).addClass('hide');
      $($(element)[0].querySelectorAll('input')).prop('required', false);
      $($(element)[0].querySelectorAll('select')).prop('required', false);
      $($(element)[0].querySelectorAll('textarea')).removeAttr('required', false);
      $($(element)[0].querySelectorAll('.tx-validation')).addClass('skip-required')
    } else if (!checked) {
      $(element).removeClass('hide');
      $($(element)[0].querySelectorAll('.tx-validation')).removeClass('skip-required')

      if (isRequiredField && !this.skipRequiredFields(element)) {
        $($(element)[0].querySelectorAll('input')).prop('required', true);
        $($(element)[0].querySelectorAll('select')).prop('required', true);
        $($(element)[0].querySelectorAll('textarea')).prop('required', true);
        $($(element)[0].querySelectorAll('.skip-required')).prop('required', false);
      }
    }
  }

  // checkRaceCondition (element, currentOptionId) {
  //   var vm = this;
  //   var found = false;
  //   currentOptionId = parseInt(currentOptionId);

  //   var elementId = parseInt(element.dataset.fieldId);
  //   var racingOptions = [];

  //   for (const [key, value] of Object.entries(this.logicsValue)) {
  //     if (key == currentOptionId) continue;

  //     if (value.includes(elementId)) { racingOptions.push(key); }
  //   }

  //   if (racingOptions.length == 0) return false;

  //   racingOptions.forEach(function (optionId) {
  //     var el = $(vm.formTarget.querySelectorAll(`[data-option-id='${optionId}']`))[0];
  //     var checked = $(el)[0].checked;

  //     if (checked) { found = true; }
  //   })

  //   return found;
  // }

  skipRequiredFields (field) {
    return field.dataset.fieldType == 'tabular_data' || field.dataset.fieldType == 'checkbox' || field.dataset.fieldType == 'treatment'
  }
}
