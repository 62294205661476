import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = []

  connect() {
    StimulusReflex.register(this)

    if ($('.doc-list')[0]) {
      this.initSelect('.doc-list');
    }
  }

  initSelect(identifier) {
    $(identifier).select2({
      tags: true,
      tokenSeparators: [",", "\t", "\n"],
      closeOnSelect: false,
      placeholder: "Add your options here",
      selectOnClose: true,
    });
    $(identifier).on('paste', function(e) {
      var data = e.originalEvent.clipboardData.getData('Text');
      $(this).val($(this).val() + '\t' + data.replace(/\n/g, '\t') + '\t');
      return false;
    });

    if (identifier == '.doc-list')
    {
      $('.doc-list option').attr('selected', true).parent().trigger('change');
    }
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    toastr.success('Success!');
  }

}
