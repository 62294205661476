import consumer from "./consumer"

if($("meta[name='current-user']")[0] && $("meta[name='current-user']")[0].content.length > 0)
{
  console.log('2..')
  consumer.subscriptions.create("CheckinChannel", {
    received(data) {
      $('.refresh-checkin')[0].click();
    }
  });
}
