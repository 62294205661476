import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = ["form", 'contentArea'];

  connect() {
    StimulusReflex.register(this)
    console.log('connected config')
  }

  toggleStateSuccess (element, reflex, noop, reflexId) {
    console.log('success mofo!')
    toastr.options.timeOut = 90;
    toastr.success('Comment created..');
  }

  toggleStateError (element, reflex, noop, reflexId) {
    toastr.error('Something went wrong..');
  }

  toggleStateSuccess (element, reflex, noop, reflexId) {
    $('.apply-filter').click();
    toastr.success('Location updated..');
  }
}
