// checkbox_dropdown_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["label", "checkAll", "inputs", 'selectionText']
  static classes = ["on"]

  initialize() {
    this.appliedColumns = [];
    this.optionClicked();
    console.log('connected...')
  }

  stopProp (e) {
    e.stopPropagation();
  }

  toggleDropdown (e) {
    e.preventDefault();
    $(this.element).toggleClass("is-active");
  }

  applySelected (e) {
    if (e)
      e.preventDefault();

    var selected = $(this.element)[0].querySelectorAll('input[type="checkbox"]:checked');
    var columnIds = [];
    var node = $('.patient-page')[0];
    var destCtrl = this.application.getControllerForElementAndIdentifier(node, 'filter');
    selected.forEach(checkbox => columnIds.push(checkbox.value));
    console.log(columnIds);
    destCtrl.setSmartColumns(columnIds);
    this.appliedColumns = columnIds;
  }

  getSelectedColumns () {
    return this.appliedColumns;
  }

  selectBasedOnList (list) {
    var vm = this;
    this.reset();

    var selectedCategories = list.map(cat => cat.filter_category_id);
    selectedCategories.forEach(function (catId) {
      var categoryDivs = vm.element.querySelectorAll(`.smart-column-category-filter-category-${catId}`)
      if (categoryDivs) {
        categoryDivs.forEach(function (categoryDiv) {
          $($(categoryDiv)[0].querySelectorAll('input')).prop('checked', true);
        });
      }
    });

    vm.applySelected(null);
  }

  reset () {
    var categoryDivs = this.element.querySelectorAll(`.sccfc`)
    if (categoryDivs) {
      categoryDivs.forEach(function (categoryDiv) {
        $($(categoryDiv)[0].querySelectorAll('input')).prop('checked', false);
      });
    }

  }
  optionClicked () {
    var selected = $(this.element)[0].querySelectorAll('input[type="checkbox"]:checked');
    if (this.hasSelectionTextTarget){
      this.selectionTextTarget.innerHTML = `${selected.length} selected`;
    }
  }
}
