import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'


export default class extends ApplicationController {
  static targets = ['status', 'workflowSelect', 'mainTab', 'rescheduleTab', 'showTab', 'commentForm', 'startTime', 'calendar', 'formTab']

  connect() {
    StimulusReflex.register(this)
    if (this.statusTarget.value == 'show') {
      $(this.showTabTarget).removeClass('d-none');
      $('.to-assign').removeClass('d-none');
    }
  }

  startTimeChanged () {
    var vm = this;
    var params = {
      'appointment': {
        'start_date': (vm.startTimeTarget.value),
        'calendar_id': (vm.calendarTarget.value)
      }
    }
    this.stimulate(`Appointment#validate`, params);
  }

  statusChanged (e) {
    $('.extra-step').addClass('d-none');
    $('.to-assign').addClass('d-none');

    this.mainTabTarget.click();

    if (this.statusTarget.value == 'show') {
      $(this.commentFormTarget).addClass('d-none');
      $(this.showTabTarget).removeClass('d-none');
      $('.to-assign').removeClass('d-none');
      var formCount = $('.form-count')[0].innerHTML;
      this.showTabTarget.click();
      $('.show-link').click();

      // if (formCount == '0') {
      // } else {
      //   this.formTabTarget.click();
      // }
    }
    else if (this.statusTarget.value == 'rescheduled') {
      $(this.commentFormTarget).addClass('d-none');
      $(this.rescheduleTabTarget).removeClass('d-none');
      this.rescheduleTabTarget.click();
      $('.reschedule-link').click();

    } else {
      $(this.commentFormTarget).removeClass('d-none');
      this.workflowSelectTarget.classList.add('d-none');
    }
  }

  nextClicked (e) {
    e.preventDefault();
    var next = `.${e.target.dataset['nextItem']}`
    console.log(next);
    $(next).click();
  }
  mainTabClicked () {
    $(this.commentFormTarget).removeClass('d-none');
  }
}
