import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'
import {
  triggerChange
} from "helpers";


export default class extends ApplicationController {
  static targets = ['defaultValue', 'mode', 'field', 'actualField', 'replicaField']
  static values = {
    mode: String,
    selectedDateTime: String,
    skipAdjustment: Boolean,
    enableChangeOnEmpty: Boolean
  }

  connect() {
    if (!this.enableChangeOnEmptyValue) {
      this.enableChangeOnEmptyValue = false;
    }
    var vm = this;
    var defaultDate = this.selectedDateTimeValue;
    var dateFormat = "m/d/Y";
    var enableTime = false;
    enableTime = this.modeValue == 'datetime';

    if (defaultDate) {
      var adjustment = 0.5;
      defaultDate = defaultDate.replace('-', '/').replace('-', '/')
      if (this.skipAdjustmentValue == true) {
        var convertedDate = new moment.tz(defaultDate, 'MM/DD/YYYY', 'America/New_York').format('MM/DD/YYYY');
        adjustment = 0;
      } else {
        if (enableTime) {
          var convertedDate = new moment.tz(defaultDate, 'MM/DD/YYYY HH:mm').tz('America/New_York').format('MM/DD/YYYY HH:mm');
        } else {
          defaultDate += ' 00:00'
          var convertedDate = new moment.tz(defaultDate, 'MM/DD/YYYY', 'America/New_York').format('MM/DD/YYYY');
        }
      }
      defaultDate = new Date(convertedDate);

      defaultDate.setDate(defaultDate.getDate() + adjustment)
      vm.actualFieldTarget.value = moment(defaultDate).parseZone().tz("America/New_York", true).toISOString(true)
      triggerChange((vm.actualFieldTarget))
    }

    dateFormat = "m/d/Y-H:i";


    flatpickr(vm.fieldTarget, {
      enableTime: enableTime,
      time_24hr: true,
      defaultDate: defaultDate,
      focus: false,
      dateFormat: dateFormat,
      disableMobile: true,
      onClose: function(selectedDates, selectedDate) {
        if (vm.hasActualFieldTarget) {
          if (selectedDates[0]) {
            vm.actualFieldTarget.value = moment(selectedDates[0]).parseZone().tz("America/New_York", true).toISOString(true) // => "2019-08-01T05:00:00.000+01:00"
          } else {
            vm.actualFieldTarget.value = null;
          }
          triggerChange((vm.actualFieldTarget))
        }
        if (vm.hasReplicaFieldTarget) {
          vm.replicaFieldTarget.value = vm.fieldTarget.value;
          triggerChange((vm.replicaFieldTarget))
        }
      }
    });
  }
}

