
import { Controller } from "stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

import {
  serializeFormData
} from "helpers";
export default class extends Controller {
  static targets = [ "appointments", 'role', 'priorityFormsBtn', 'overdueFormsBtn']
  static values = {
    appointments: Array,
    calendars: Array,
    goToDate: String,
  }

  connect() {
    $('body')[0].classList.add('mini-navbar');

    var vm = this;
    window.vm = this;
    var calendarEl = document.getElementById('cal');
    var calendar = vm.calendar = new Calendar(calendarEl, {
      plugins: [ resourceTimeGridPlugin, interactionPlugin ],
      initialView: 'resourceTimeGrid',
      events: this.appointmentsValue,
      resources: this.calendarsValue,
      resourceOrder: '-type1,type2',
      timeZone: 'America/New_York',
      slotDuration: "00:20",
      weekNumbers: true,
      droppable: true,
      selectable: true,
      scrollTime: '07:00:00',
      customButtons: {
        newAppointment: {
          text: '+ New appointment',
          click: function() {
            vm.newAppointment();
          }
        }
      },
      views: {
        resourceTimeGrid: {
          titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }
        },
      },
      headerToolbar: {
        left: 'prev,next,resourceTimeGridWeek,resourceTimeGridDay,today',
        center: 'title',
        right: ''
      },
      select: function (selectionInfo) {
        if (selectionInfo.jsEvent.target.classList.contains('my-custom-button')) return;
        vm.openDragDropActions(selectionInfo);
      },
      eventClick: function(info) {
        vm.eventClicked(info, null);
      },
      eventDidMount: function(info) {
        var appointmentClass = info.event.extendedProps.class;
        var eventColor = info.event.extendedProps.background_color;
        $(info.el)[0].style.backgroundColor = eventColor;

        if (appointmentClass != 'BlockedAppointment') {
          var titleString = `<span class='app-name'>${info.event.extendedProps.patientName}</span> <br> ${info.event.extendedProps.appointment_type} \t`
          $(info.el).find('.fc-event-time').html(titleString);
        }

        $(info.el).addClass(info.event.extendedProps.status);

        if (appointmentClass == 'InternalAppointment') {
          var hash = [
            {
              name: 'Forms',
              icon: 'form',
              innerText: info.event.extendedProps.overdue_assigned_workflows,
              hash: 'form'
            },
            {
              name: 'Tasks',
              icon: 'task',
              innerText: info.event.extendedProps.overdue_tasks,
              hash: '#tasks-list'
            },
            {
              name: 'Flags',
              icon: 'flag',
              innerText: info.event.extendedProps.flags,
              hash: '#flags-list'
            },
            {
              name: 'Money',
              icon: 'money',
              innerText: info.event.extendedProps.transactions,
              hash: '#stats'
            },
            {
              name: 'Notes',
              icon: 'note',
              innerText: info.event.extendedProps.clinical_notes,
              hash: '#notes-list'
            },
          ]

          var iconContainer = document.createElement('span');
          iconContainer.classList.add('appointment-buttons-container');
          iconContainer.style.paddingLeft = '5px';

          if (vm.checkOverlapping(info.event)) {  // Assuming vm.checkOverlapping returns true if event overlaps
            // If event is overlapping, create a popover
            var popoverContentParent = document.createElement('span');
            var icon = document.createElement('i');
            icon.classList.add('fa')
            icon.classList.add('fa-caret-down')
            popoverContentParent.appendChild(icon);
            popoverContentParent.classList.add('three-dots')
            popoverContentParent.innerHTML = '  +'

            var popoverContent = document.createElement('div');
            hash.forEach(function(item) {
              var spanEl = document.createElement('a');
              spanEl.innerText = item.innerText;
              spanEl.classList.add('my-custom-button');
              spanEl.classList.add(item.icon);
              spanEl.dataset.hash = item.hash;
              spanEl.style.paddingLeft = '16px';
              spanEl.addEventListener('click', function(e) {
                e.stopPropagation(); // This will prevent the event from bubbling up to the eventClick handler
                vm.eventClicked(info, e);
              });
              popoverContent.appendChild(spanEl);
            });
            $(info.el).find('.app-name')[0].appendChild(popoverContentParent)

            tippy(popoverContentParent, {  // Create a tippy tooltip
              content: popoverContent,
              theme: 'light',
              allowHTML: true,
              interactive: true,  // Add this line
              trigger: 'click',  // Trigger the tooltip on click
            });

          } else {
            // If event is not overlapping, keep the existing functionality

            hash.forEach(function(item) {
              var spanEl = document.createElement('span');
              spanEl.innerText = item.innerText;
              spanEl.classList.add('my-custom-button');
              spanEl.classList.add(item.icon);
              spanEl.dataset.hash = item.hash;
              spanEl.style.marginRight = '2px';
              spanEl.style.paddingLeft = '16px';
              iconContainer.appendChild(spanEl);
            });

            $(info.el).find('.app-name')[0].appendChild(iconContainer);
          }
        }
      },
      datesSet: function(info) {
        $('body').addClass('loading')
        var startDate = info.startStr;
        var endDate = info.endStr;
        var url = `/calendars/search/`;

        if (vm.roleTarget.value == 'admin_user') {
          url = '/admin/' + url;
        }
        var priorityFormsBtn = $('a[data-target="calendar.priorityFormsBtn"]');
        var href = priorityFormsBtn.attr('href');
        var startDateStrp = new Date(info.startStr).toISOString().split('T')[0];
        var endDateStrp = new Date(info.endStr).toISOString().split('T')[0];
        // startDateStrp = endDateStrp

        var newHref = href.replace(/start_date=\d{4}-\d{2}-\d{2}/, 'start_date=' + startDateStrp)
                          .replace(/end_date=\d{4}-\d{2}-\d{2}/, 'end_date=' + endDateStrp);
        priorityFormsBtn.attr('href', newHref);

        var overdueFormsBtn = $('a[data-target="calendar.overdueFormsBtn"]');
        var href = overdueFormsBtn.attr('href');
        var startDateStrp = new Date(info.startStr).toISOString().split('T')[0];
        var endDateStrp = new Date(info.endStr).toISOString().split('T')[0];
        // startDateStrp = endDateStrp

        var newHref = href.replace(/start_date=\d{4}-\d{2}-\d{2}/, 'start_date=' + startDateStrp)
                          .replace(/end_date=\d{4}-\d{2}-\d{2}/, 'end_date=' + endDateStrp);
        overdueFormsBtn.attr('href', newHref);

        $.ajax({
          url: url,
          method: 'GET',
          data: {
            start_date: startDate,
            end_date: endDate
          },
          dataType: "json",
          success: (result) => {
            vm.calendar.removeAllEvents();
            $('body').removeClass('loading')


            JSON.parse(result.appointments).forEach(event => {
              vm.calendar.addEvent(event);
            });
            return;
          },
          error: (errors) => {
            $('body').removeClass('loading')
            toastr.error('Something went wrong', errors.responseJSON.errors.join('') );
            return;
          },
        });
      }
    });

    var vm = this;
    calendar.gotoDate(Date.parse(vm.goToDateValue))
    calendar.render();
    vm.calendar = calendar;
    $('.fc-license-message').hide();
  }

  eventClicked(info, event) {
    if (event) {
      var el = event.target;
    } else {
      var el = info.jsEvent.target;
    }
    if (el.classList.contains('three-dots')) {
      return;
    }
    var patientId = info.event.extendedProps.patientId;
    // Check if the click target has the button class
    if (el.classList.contains('my-custom-button')) {
      // Button click action
      if (this.roleTarget.value == 'admin_user') {
        var url = `admin/patients/${patientId}/profile#tasks`
      } else {
        var url = `patients/profile#tasks`
      }
      $.ajax({
        url: url,
        method: 'GET',
        data: {
          patient_id: patientId,
          id: patientId,
          hash: el.dataset['hash'],
        },
        dataType: "script",
        success: (result) => {
          // vm.createBlockage();
        },
        error: (error) => {
          toastr.error('Something went wrong');
          return;
        },
      });
    } else {
      // return;
      // Normal event click action
      var appointmentClass = info.event.extendedProps.class;
      if (appointmentClass == 'BlockedAppointment') {
        this.deleteBlockage(info);
      } else {
        this.editAppointment(info);
      }
    }
  }

  checkOverlapping(event) {
    var vm = this;
    var overlapping = false;
    var resourceId = event.extendedProps.calendarId;
    // Assuming "calendar" is the instance of your fullCalendar and you can get all events
    var allEvents = vm.calendar.getEvents();

    for (var i = 0; i < allEvents.length; i++) {
      var compareResourceId = allEvents[i].extendedProps.calendarId;
      if (allEvents[i].id !== event.id && compareResourceId == resourceId) { // Don't compare the event to itself
        if ((event.start >= allEvents[i].start && event.start < allEvents[i].end) ||
        (event.end > allEvents[i].start && event.end <= allEvents[i].end) ||
        (event.start <= allEvents[i].start && event.end >= allEvents[i].end))
          {

            overlapping = true;
            break;
          }
      }
    }

    return overlapping;
  }


  newBlockage (params = {}) {
    $("#labRequestModal").html('');
    $("#newAppointmentModal").html('');

    var vm = this;
    var url = `/appointments/new_blockage`;
    if (vm.roleTarget.value == 'admin_user') {
      url = '/admin/' + url;
    }

    $.ajax({
      url: url,
      method: 'GET',
      data: params,
      dataType: "script",
      success: (result) => {
        vm.createBlockage();
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }

  newAppointment (params = {}) {
    $("#labRequestModal").html('');
    $("#newAppointmentModal").html('');

    var vm = this;
    var url = `/appointments/new`;
    if (vm.roleTarget.value == 'admin_user') {
      url = '/admin/' + url;
    }

    $.ajax({
      url: url,
      method: 'GET',
      data: params,
      dataType: "script",
      success: (result) => {
        vm.createAppointment();
      },
      error: (error) => {
        toastr.error('Something went wrong');
        return;
      },
    });
  }

  editAppointment (data) {
    $("#labRequestModal").html('');
    $("#newAppointmentModal").html('');
    var vm = this;
    var id = data.event.id;

    var url = `/appointments/${id}/checkin`;
    if (vm.roleTarget.value == 'admin_user') {
      url = '/admin/' + url;
    }

    $.ajax({
      url: url,
      method: 'GET',
      data: {},
      dataType: "script",
      success: (result) => {
        vm.updateAppointment(data);
      },
      error: (error) => {
        toastr.error('Something went wrong');
        $('#appointmentModal').modal('hide');
        return;
      },
    });

  }

  updateAppointment (data) {
    var vm = this;
    var currentEvent = data.event
    var id = currentEvent.id;

    $(".update-appointment").off("click");
    $('.update-appointment').on('click', function(e) {
      e.preventDefault();
      var form = $('form')[$('form').length - 1]
      var isValid = form.reportValidity();
      if (!isValid) return;
      var params = vm.form_data(form);

      var url = `/appointments/${id}/onboard`;

      if (data.event.extendedProps.class == 'ExternalAppointment') {
        url = `/appointments/${id}/onboard_external`;
      }

      if (vm.roleTarget.value == 'admin_user') {
        url = '/admin/' + url;
      }

      $.ajax({
        url: url,
        method: 'POST',
        data: params,
        dataType: "json",
        success: (result) => {
          currentEvent.remove()
          vm.calendar.addEvent(result.appointment);
          toastr.success('Appointment updated.');

          if (result.appointment.class == 'InternalAppointment' && result.appointment.status == 'show') {
            var element = $('.refresh-profile')[0];
            $('.appointment-body').addClass('d-none');
            $('.refresh-message').removeClass('d-none');

            if (element) {
              element.click();
            }
            setTimeout(() => {
              $('.forms-tab').click();
            }, 1000);
          } else {
            if (result.appointment.class == 'ExternalAppointment') {
              $("#labRequestModal").modal('hide');
              $("#labRequestModal").html('');
              $('#lab_request_details_modal').modal('hide');
            }
          }
          return;
        },
        error: (error) => {
          toastr.error(error);
          $('#appointmentModal').modal('hide');
          return;
        },
      });
    });
  }

  createAppointment (data) {
    var vm = this;

    $(".create-appointment").off("click");
    $('.create-appointment').on('click', function(e) {
      e.preventDefault();
      var form = $('form')[$('form').length - 1]

      var isValid = form.reportValidity();
      if (!isValid) return;
      var params = vm.form_data(form);

      var url = `/appointments/`;
      if (vm.roleTarget.value == 'admin_user') {
        url = '/admin/' + url;
      }

      $.ajax({
        url: url,
        method: 'POST',
        data: params,
        dataType: "json",
        success: (result) => {
          vm.calendar.addEvent(result.appointment);
          toastr.success('Appointment updated.')
          $("#newAppointmentModal").modal('hide');
          $("#newAppointmentModal").html('');
          return;
        },
        error: (errors) => {
          toastr.error('Something went wrong', errors.responseJSON.errors.join('') );
          return;
        },
      });
    });
  }

  createBlockage (data) {
    var vm = this;

    $(".create-appointment").off("click");
    $('.create-appointment').on('click', function(e) {
      e.preventDefault();
      var form = $('form')[$('form').length - 1]

      var isValid = form.reportValidity();
      if (!isValid) return;
      var params = vm.form_data(form);

      var url = `/appointments/create_blockage`;
      if (vm.roleTarget.value == 'admin_user') {
        url = '/admin/' + url;
      }

      $.ajax({
        url: url,
        method: 'POST',
        data: params,
        dataType: "json",
        success: (result) => {
          vm.calendar.addEvent(result.appointment);
          toastr.success('Slot Block.')
          $("#newAppointmentModal").modal('hide');
          $("#newAppointmentModal").html('');
          return;
        },
        error: (errors) => {
          toastr.error('Something went wrong', errors.responseJSON.errors.join('') );
          return;
        },
      });
    });
  }


  deleteAppointment (info) {
    var vm = this;
    var id = info.event.id;
    var currentEvent = info.event
    var url = `/appointments/${id}/`;

    if (vm.roleTarget.value == 'admin_user') {
      url = '/admin/' + url;
    }

    $.ajax({
      url: url,
      method: 'DELETE',
      dataType: "script",
      success: (result) => {
        currentEvent.remove()
        // toastr.success('Calendar updated.')
        return;
      },
      error: (error) => {
        toastr.error('Something went wrong', error);
        return;
      },
    });
  }

  deleteBlockage (info) {
    var vm = this;
    swal("Are you sure you want to delete this blocked slot?", {
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
          className: "swal-cancel",
          closeModal: true,
        },
        catch: {
          text: "Yes",
          value: "yes",
          className: "swal-action",
        }
      },
    })
    .then((value) => {
      switch (value) {
        case "yes":
          vm.deleteAppointment(info);
          break;
        default:
      }
    });

  }

  openDragDropActions (selectionInfo) {
    var vm = this;
    var params = vm.extractParams(selectionInfo);

    swal("Please confirm your action.", {
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
          className: "swal-cancel",
          closeModal: true,
        },
        catch: {
          text: "New appointment!",
          value: "catch",
          className: 'swal-action'
        },
        block: {
          text: 'Block this slot',
          value: 'block',
          className: 'swal-action'
        }
      },
    })
    .then((value) => {
      switch (value) {
        case "block":
          delete Object.assign(params, {['blocked_appointment']: params['internal_appointment'] })['internal_appointment'];
          vm.newBlockage(params);
          break;
        case "catch":
          vm.newAppointment(params);
          break;
        default:
      }
    });
  }

  serializeForm(form) {
    return $(form).serializeArray().reduce(function(output, value) {
      output[value.name] = value.value
      return output
    }, {})
  }

  form_data(form) {
    return serializeFormData(form);
  }

  extractParams (selectionInfo) {
    var calendarId = selectionInfo.resource.id;
    var startTime = moment(selectionInfo.startStr).parseZone().tz("America/New_York", true).toISOString(true)
    var duration =  Math.floor((selectionInfo.end - selectionInfo.start) / 60000)

    return {
      internal_appointment: {
        calendar_id: calendarId,
        interval: duration,
        start_time: startTime
      }
    }
  }
}
